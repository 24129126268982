<template>
    <div v-if="enabled" class="columns is-desktop searchbar">
        <div class="column is-6">
            <FormulateInput error-behavior="submit" key="thesissearchtext" name="thesissearchtext" v-model="thesisauthor" :tooltip="$t('thesissearchtext_tooltip')" :label="`${$t('thesissearchtext')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
        </div>
        <div class="column is-2">
            <FormulateInput error-behavior="submit" key="thesissearchyear" type="number" min="0" max="2038" name="thesissearchyear" v-model="thesisyear" :tooltip="$t('thesissearchyear_tooltip')" :label="`${$t('thesissearchyear')}`" validation="optional|number|between:0,2039,value|invalidText" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year'), invalidText: $t('validation_invalid_text') }" />            
        </div>
        <div class="column is-3">
            <FormulateInput error-behavior="submit" :tooltip="$t('thesissearchtype_tooltip')" v-model="thesissearchtype" name="thesissearchtype" :options="{ 0: $t('apa5_phd', thesisLang), 1: $t('apa5_msc', thesisLang), 2: $t('apa5_bsc', thesisLang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')}`" />
        </div>
        <div class="column is-1" style="text-align: right;">
            <button class="button" @click.prevent="thesissearch" :style="{ fontSize: `${fontSize}em` }" :title="$t(`thesissearch`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em;" :disabled="!!working">
                <span id="search-button" :class="working ? 'searching' : 'idle'"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import { bus } from '@/main';

    export default {
        name: 'ThesisSearch',

        props: ['thesisLang'],

        data: () => ({
            thesisauthor: '',
            thesisyear: '',
            thesissearchtype: '',
            working: false,
            fontSize: '1',
            enabled: (process.env.VUE_APP_THESIS_SEARCH_ENABLED === 'true') ? true : false,
        }),

        methods: {
            thesissearch: function() {
                if (this.thesisauthor !== '') {
                    this.working = true;
                    axios.post(`${process.env.VUE_APP_API_BASE_URL}thesis-search/`, {
                        thesisauthor: this.thesisauthor,
                        thesissearchtype: this.thesissearchtype,
                        thesisyear: this.thesisyear,
                        thesislang: this.thesisLang,
                    })
                    .then((response) => {
                        // this.$emit('fillFormWithThesis', response.data);
                        this.$emit('openThesisSearchResults', this.transformData(response.data));
                    })
                    .catch(() => {
                        this.$notify({
                            type: 'error',
                            text: i18n.t('thesisretrieveerror', this.lang)
                        });
                    })
                    .then(() => {
                        this.working = false;
                    }); 
                }
                else {
                    this.$notify({
                        type: 'error',
                        text: i18n.t('thesissearchvalidationerror', this.lang)
                    });
                }
            },

            transformData: function(data) {
                const perPage = parseInt(process.env.VUE_APP_THESIS_RESULTS_PER_PAGE) || 50;
                let d = data['payload']['result'];
                let transformed = {};

                transformed.pagination = {
                    total: d['totalResults'],
                    per_page: perPage,
                    current_page: d['page'],
                    last_page: (parseInt(d['totalResults']) % perPage > 0) ? Math.floor(parseInt(d['totalResults']) / perPage) + 1 : parseInt(d['totalResults']) / perPage,
                    from: ((parseInt(d['page']) * perPage) - perPage) + 1,
                    to: parseInt(d['page']) * perPage,
                    next_page_url: '#',
                    prev_page_url: null,
                }

                transformed.data = [];

                for (let i = 0; i < d.results.length; i++) {
                    transformed.data.push({
                        id: d.results[i]['uri'],
                        author: this.filterByLanguage(d.results[i]['dc_creator'], this.thesisLang, true),
                        title: this.filterByLanguage(d.results[i]['dc_title'], this.thesisLang, false),
                    })
                }
                return transformed;
            },

            filterByLanguage(data, lang, removeSpecialChars) {
                const greekLetters = /[\u0370-\u03FF\u1F00-\u1FFF]+/g;
                const latinLetters = /[a-zA-Z]+/g;
                const specialChars = /[,']/g;
                const divider = ', ';
                let res = '';

                if (Array.isArray(data) && data.length > 0) {
                    if (data.length === 2 && ((greekLetters.test(data[0]) && latinLetters.test(data[1])) || (latinLetters.test(data[0]) && greekLetters.test(data[1])))) {
                        // if (lang && lang === 'el') {
                            if (greekLetters.test(data[0])) {
                                res = (removeSpecialChars) ? data[0].replace(specialChars, '').trim() : data[0].trim();
                            }
                            else {
                                res = (removeSpecialChars) ? data[1].replace(specialChars, '').trim() : data[1].trim();
                            }
                        // }
                        // else {
                        //     if (latinLetters.test(data[0])) {
                        //         res = (removeSpecialChars) ? data[0].replace(specialChars, '').trim() : data[0].trim();
                        //     }
                        //     else {
                        //         res = (removeSpecialChars) ? data[1].replace(specialChars, '').trim() : data[1].trim();
                        //     }
                        // }
                    }
                    else {
                        data.forEach((v) => {
                            v = (removeSpecialChars) ? v.replace(specialChars, '') : v;
                            res += ((res !== '') ? divider : '') + v.trim();
                        });
                    }
                }
                return res.trim();
            },
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data).toString();
          });
        },
    }
</script>
