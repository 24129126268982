<template>
    <div>
        <section>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        {{ $t('notfound_title') }}
                    </h1>
                    <h2 class="subtitle">
                        <br />
                        <p v-html="$t('notfound_text')"></p>
                    </h2>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
    }
</script>