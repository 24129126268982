<template>
    <div class="columns is-desktop">
        <div class="column">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <FormulateInput v-model=value v-if="req" :style="{ fontSize: `${fontSize}em` }" error-behavior="submit" :tooltip="!!tooltip ? tooltip : $t(`${name}_tooltip_mand`)" type="date" :name=name min="0001-01-01" max="2038-12-31" :label="!!label ? label : `${$t(name)} (*)`" validation="bail|required|date|after:0001-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" />
                    <FormulateInput v-model=value v-else :style="{ fontSize: `${fontSize}em` }" error-behavior="submit" :tooltip="!!tooltip ? tooltip : $t(`${name}_tooltip`)" type="date" :name=name min="0001-01-01" max="2038-12-31" :label="!!label ? label : `${$t(name)}`" validation="optional|^date|after:0001-01-01|before:2038-12-31" :validation-messages="{ date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" />
                </div>
                <div class="control" v-if="showClear">
                    <button class="input" @click.prevent="clearValue" :style="{ fontSize: `${fontSize}em` }" :title="$t(`clear_dateaccessed_button`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em">
                        <i class="fas fa-minus-circle"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { isChrome, isOpera } from 'mobile-device-detect';
    import { bus } from '@/main';

    export default {
        name: 'Calendar',

        props: {
            name: String,
            label: String,
            tooltip: String,
            req: {
                type: Boolean,
                default: false
            }
        },

        data: () => ({
            showClear: isChrome || isOpera,
            value: '',
            fontSize: '1',
        }),

        methods: {
            clearValue: function() {
                this.value = '';
            }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data).toString();
          });
        },
    }
</script>