<template>
    <div>
        <div class="source-page-title">{{ $t('notes') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div>
                    <institutes name="institutes" insts-depts-tip="notes_institutes_departments_tooltip" />
                </div>
                <!-- <FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /> -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('notes_city_tooltip_mand')" :label="`${$t('notes_city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="semester" :label="`${$t('semester')}`" :tooltip="$t('semester_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" condreq mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
                <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <!-- <FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /> -->
                    <div class="column is-4"><comp-calendar :lang="citation.lang" date-tip="notes_date_tooltip" month-tip="notes_month_tooltip" year-tip="notes2_year_tooltip" /></div>
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('notesdesc_tooltip_mand')" :label="`${$t('notesdesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <div>
                    <institutes name="institutes" insts-depts-tip="notes_institutes_departments_tooltip" />
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div>
                    <institutes name="institutes" instonly insts-tip="notes_institutes_tooltip" />
                </div>
                <FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('notes_city_tooltip_mand')" :label="`${$t('notes_city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div>
                    <institutes name="institutes" insts-depts-tip="notes_institutes_departments_tooltip" />
                </div>
                <FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('notes_city_tooltip_mand')" :label="`${$t('notes_city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div>
                    <institutes name="institutes" instonly insts-tip="notes_institutes_tooltip" />
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contribs name="authors" type="notes" single-name="author" show-corp creator-tooltip="notes_creator_artist_tooltip" corp-tooltip="notes_creator_creator_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="semester" type="select" :label="`${$t('ieee_semester')}`" :tooltip="$t('ieee_semester_tooltip')" :options="{ 0: $t('empty_selection', citation.lang), 1: $t('winter', citation.lang), 2: $t('vernal', citation.lang) }" /></div>
                </div>
                <div id="ieee-institutes">
                    <FormulateInput type="group" name="institutes" :repeatable="true" :label="`${$t('insts')} (*)`" :add-label="$t('add')" validation="ieeeInstitutesMinLength" :validation-rules="{ ieeeInstitutesMinLength: ieeeInstitutesMinLengthRule }" :validation-messages="{ ieeeInstitutesMinLength: $t('validation_institute_min_length') }">
                        <div class="columns is-desktop">
                            <div class="column"><FormulateInput error-behavior="submit" name="name" :label="`${$t('institute')} (*)`" :tooltip="$t('institute_tooltip_mand')" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('notes_city_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><states :lang="citation.lang" dc /></div>
                            <div class="column group-margin"><countries :lang="citation.lang" extreq /></div>
                        </div>
                    </FormulateInput>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="notes" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="notes" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="notes" creator-tooltip="notes_creator_creator_tooltip" artist-tooltip="notes_creator_artist_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('notestitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('coursetitle_tooltip')" name="course" :label="`${$t('course')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('notes_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                </div>
                <div id="ieee-institutes">
                    <FormulateInput type="group" name="institutes" :repeatable="true" :label="`${$t('deptartments_institutes')} (*)`" :tooltip="`${$t('notes_deptartments_institutes_tooltip')}`" :add-label="$t('add')" validation="cseInstitutesMinLength" :validation-rules="{ cseInstitutesMinLength: cseInstitutesMinLengthRule }" :validation-messages="{ cseInstitutesMinLength: $t('validation_institute_min_length') }">
                        <div class="columns is-desktop">
                            <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('notes_city_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><states :lang="citation.lang" /></div>
                            <div class="column"><countries :lang="citation.lang" /></div>
                            <div class="column group-margin"><FormulateInput error-behavior="submit" name="institute" :label="`${$t('institute')} (*)`" :tooltip="$t('institute_tooltip_mand')" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        </div>
                    </FormulateInput>
                </div>
                <Url @shortenurl="shortenurl($event)" condreq mediatype="notes" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="notes" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Controls from '@/components/forms/Controls';
    import Contribs from '@/components/forms/Contribs';
    import Institutes from '@/components/forms/Institutes';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import CompCalendar from '@/components/forms/CompCalendar';

    export default {
        name: 'Notes',

        components: {
            SysLang,
            Contributors,
            Contribs,
            Controls,
            Institutes,
            States,
            Countries,
            Url,
            Modal,
            CompCalendar,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'notes';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('notes');
                this.citation.system = data;
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.state = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.semester = '0',
                    this.citation.institutes = [
                    {
                        state: 'empty',
                        country: 'empty'
                    }
                    ],
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.yeartype = 'publicationyear',
                    this.citation.institutes = [
                    {
                        state: 'empty',
                        country: 'empty'
                    }
                    ],
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },

            ieeeInstitutesMinLengthRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.name && institute.city && institute.country;
                }
                return false;
            },

            cseInstitutesMinLengthRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.city && institute.institute;
                }
                return false;
            },
        },
    }
</script>

<style>
    #ieee-institutes a[role=button] {
        margin-top: 1.35em
    }
</style>