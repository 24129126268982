var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('sc_title'))+" ")]),_c('h2',{staticClass:"subtitle"},[_c('br'),_c('p',[_vm._v(_vm._s(_vm.$t('shortcite_description')))])])])])]),_c('section',{staticClass:"section",staticStyle:{"padding":"0 1.5rem"}},[_c('div',{staticClass:"container"},[_c('FormulateForm',{staticClass:"form-wrapper formulate-form",attrs:{"name":"shortcite","autocomplete":"off","novalidate":""},on:{"submit":_vm.submit,"submit-raw":_vm.validate},model:{value:(_vm.request),callback:function ($$v) {_vm.request=$$v},expression:"request"}},[_c('FormulateInput',{attrs:{"error-behavior":"submit","error":_vm.errMsg,"tooltip":_vm.$t('sc_uri_tooltip'),"label":`${_vm.$t('sc_uri')} (*)`,"name":"longUrl","validation":[
                        ['^required'],
                        // ['matches', /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/],
                        ['url']
                    ],"validation-messages":{
                        required: _vm.$t('validation_required_text'),
                        // matches: $t('validation_invalid_url'),
                        url: _vm.$t('validation_invalid_url')
                    }},on:{"input":function($event){_vm.errMsg = ''}}}),(_vm.showResult)?_c('div',{staticStyle:{"display":"flex"}},[_c('pre',{domProps:{"innerHTML":_vm._s(_vm.shortUrl)}}),_vm._v(" "),_c('button',{staticClass:"button",attrs:{"type":"button","title":_vm.$t('copy_tooltip')},on:{"click":_vm.doCopy}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-copy"})])])]):_vm._e(),_c('hr'),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_c('FormulateInput',{staticStyle:{"text-align":"right"},attrs:{"type":"submit","title":_vm.$t('buildshorturl_tooltip')}},[_vm._v(" "+_vm._s(_vm.$t('build_shorturl'))+" "),(_vm.loading)?_c('span',{staticClass:"loader",staticStyle:{"margin-left":"1em"}}):_vm._e()])],1),_c('div',{staticClass:"column"},[_c('FormulateInput',{staticStyle:{"text-align":"left"},attrs:{"type":"button","label":_vm.$t('reset_form'),"data-ghost":""},on:{"click":_vm.reset}})],1)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }