<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        {{ $t('sc_title') }}
                    </h1>
                    <h2 class="subtitle">
                        <br />
                        <p>{{ $t('shortcite_description') }}</p>
                    </h2>
                </div>
            </div>
        </section>
        <section class="section" style="padding: 0 1.5rem">
            <div class="container">
                <FormulateForm v-model="request" name="shortcite" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
                    <FormulateInput
                        error-behavior="submit"
                        :error="errMsg"
                        :tooltip="$t('sc_uri_tooltip')"
                        :label="`${$t('sc_uri')} (*)`"
                        name="longUrl"
                        :validation="[
                            ['^required'],
                            // ['matches', /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/],
                            ['url']
                        ]"
                        :validation-messages="{
                            required: $t('validation_required_text'),
                            // matches: $t('validation_invalid_url'),
                            url: $t('validation_invalid_url')
                        }"
                        @input="errMsg = ''"
                    />
                    <div v-if="showResult" style="display: flex">
                        <pre v-html="shortUrl" />
                        <button type="button" class="button" @click="doCopy" :title="$t('copy_tooltip')">
                            <span class="icon is-small">
                                <i class="fas fa-copy"></i>
                            </span>
                        </button>
                    </div>
                    <hr />
                    <div class="columns is-mobile">
                        <div class="column">
                            <FormulateInput type="submit" style="text-align: right" :title="$t('buildshorturl_tooltip')">
                                {{ $t('build_shorturl') }} <span v-if="loading" class="loader" style="margin-left: 1em" />
                            </FormulateInput>
                        </div>
                        <div class="column">
                            <FormulateInput type="button" style="text-align: left" :label="$t('reset_form')" data-ghost @click="reset" />
                        </div>
                    </div>
                </FormulateForm>
            </div>
        </section>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';

    export default {
        name: 'ShortCite',

        components: {
        },

        data: () => ({
            request: {},
            shortUrl: '',
            showResult: false,
            errorMessage: '',
            loading: false,
            errMsg: '',
        }),

        methods: {
            submit() {
                this.loading = true;
                if (this.showResult) {
                    this.showResult = false;
                    this.shortUrl = '';
                }
                // console.log(this.request);

                this.$gtag.event(`short-cite`, {
                    'event_category': `short-cite`,
                    'event_label': ``,
                    'value': 1
                });

                axios.post(`${process.env.VUE_APP_API_BASE_URL}shortcite`, this.request)
                .then((response) => {
                    // handle success
                    if (response.data.success) {
                        this.shortUrl = response.data.payload.shortUrl;
                        this.showResult = true;
                    }
                    else {
                        this.errMsg = i18n.t(response.data.payload.shortUrl, this.lang);
                    }
                })
                .catch(() => {
                    // handle error
                    // console.log(error.response.data.errors);
                })
                .then(() => {
                    // always executed
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            doCopy() {
                copyToClip(this.shortUrl);
            },

            reset() {
                this.$formulate.reset('shortcite');
                // this.$formulate.resetValidation('shortcite');
                this.shortUrl = '';
                this.showResult = false;
                this.errMsg = '';
            }
        },
    }

    function copyToClip(str) {
        return new Promise(function(resolve, reject) {
            var success = false;
            function listener(e) {
                e.clipboardData.setData("text/plain", str);
                e.preventDefault();
                success = true;
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            success ? resolve(): reject();
        });
    }
</script>

<style scoped>
    pre {
        background-color: rgb(235, 235, 235);
        border-radius: 5px;
        width: 96%;
        font-family: Roboto Condensed;
        font-weight: bold;
        margin-right: 1em;
        padding: .6rem 1rem .6rem 1rem
    }
</style>