<template>
    <div v-if="enabled" class="columns is-desktop searchbar">
        <div class="column is-12">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <FormulateInput error-behavior="submit" key="doisearchtext" name="doisearchtext" v-model="doi" :tooltip="$t('doisearchtext_tooltip')" :label="`${$t('doisearchtext')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div class="control">
                    <button class="input" @click.prevent="doisearch" :style="{ fontSize: `${fontSize}em` }" :title="$t(`doisearch`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em" :disabled="!!working">
                        <span id="search-button" :class="working ? 'searching' : 'idle'"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import { bus } from '@/main';

    export default {
        name: 'DoiSearch',

        data: () => ({
            doi: '',
            working: false,
            fontSize: '1',
            enabled: (process.env.VUE_APP_DOI_SEARCH_ENABLED === 'true') ? true : false,
        }),

        methods: {
            doisearch: function() {
                if (this.doi !== '') {
                    let cnf = {
                        headers: {
                            Accept: 'application/vnd.citationstyles.csl+json;q=1.0',
                        }
                    };
                    this.working = true;
                    this.doi = this.doi.replace('https://doi.org/', '').replace('http://dx.doi.org/', '').replace('https://dx.doi.org/', '');  // Remove prefix
                    axios.get(`${process.env.VUE_APP_DOI_RESOLVE_URL}${this.doi}`, cnf)
                    .then((response) => {
                        this.$emit('fillFormWithDoi', response.data);
                    })
                    .catch(() => {
                        this.$notify({
                            type: 'error',
                            text: i18n.t('doiretrieveerror', this.lang)
                        });
                    })
                    .then(() => {
                        this.working = false;
                    });
                }
                else {
                    this.$notify({
                        type: 'error',
                        text: i18n.t('doisearchvalidationerror', this.lang)
                    });
                }
            }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data).toString();
          });
        },
    }
</script>
