<template>
    <div>
        <hr />
        <div class="columns is-mobile">
            <div class="column">
                <FormulateInput type="submit" style="text-align: right" :title="$t('buildcitation_tooltip')">
                    {{ $t('build_citation') }} <span v-if="loading" class="loader" style="margin-left: 1em" />
                </FormulateInput>
            </div>
            <div class="column">
                <FormulateInput type="button" style="text-align: left" :label="$t('reset_form')" data-ghost @click="reset" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Controls',

        props: {
            loading: Boolean,
            formname: String,
            // sys: String,
            // lang: String,
        },

        data: () => ({
        }),

        methods: {
            reset() {
                this.$emit('formreset', this.formname);
                window.scrollTo({ top: 0, left: 0,  behavior: 'smooth' });
            }
        },
    }
</script>
