<template>
    <div class="columns is-desktop">
        <div class="column is-6">
            <FormulateInput :tooltip="$t('system_tooltip')" v-model="system" error-behavior="submit" name="system" :options="{ apa5: $t('apa5'), apa7: $t('apa7'), chicago_notes: $t('chicago_notes'), chicago_date: $t('chicago_date'), mla: $t('mla'), ieee: $t('ieee'), cse_date: $t('cse_date'), cse_cit: $t('cse_cit') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('citation_system')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="systemChange($event)" />
        </div>
        <div class="column is-6">
            <FormulateInput v-if="!!showlang" :tooltip="$t('lang_tooltip')" error-behavior="submit" name="lang" :options="{ el: $t('el'), en: $t('en') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('source_language')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="langChange($event)" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SysLang',

        props: {
            form: {
                type: String,
                required: false
            }
        },

        data: () => ({
            showlang: false,
            system: '',
        }),

        methods: {
            systemChange: function(event) {
                this.showlang = this.system;
                this.$emit('systemchanged', event.target.value);
            },

            langChange: function(event) {
                this.$emit('langchanged', event.target.value);
            }
        },

        watch: {
            system: function() {
                this.showlang = !!this.system;
            },
        },
    }
</script>
