<template>
    <div>
        <div class="source-page-title">{{ $t('website') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <div class="columns is-desktop">
                    <div class="column is-6"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('website_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <!-- <div class="column is-6"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('website_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div> -->
                    <div class="column is-6"><comp-calendar :lang="citation.lang" date-tip="website_date_tooltip" month-tip="website_month_tooltip" year-tip="website2_year_tooltip" /></div>
                </div>
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('website_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" type="number" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('website_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /> -->
                    <div class="column"><comp-calendar :lang="citation.lang" date-tip="website_date_tooltip" month-tip="website_month_tooltip" year-tip="website2_year_tooltip" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <!-- <div style="display: flex">
                    <FormulateInput style="width: 75%; margin-right: 1em" error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip')" :label="`${$t('website_title')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_text') }" />
                    <FormulateInput style="width: 25%" error-behavior="submit" type="number" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('website_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" />
                </div> -->
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('website_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns">
                    <div class="column is-10"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('website_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('website_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <!-- <div style="display: flex">
                    <FormulateInput style="width: 75%; margin-right: 1em" error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip')" :label="`${$t('website_title')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_text') }" />
                    <FormulateInput style="width: 25%" error-behavior="submit" type="number" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('website_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" />
                </div> -->
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip')" :label="`${$t('website_title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <div class="columns is-desktop">
                    <div class="column">
                        <contribs name="authors" type="website" single-name="author" show-corp corp-tooltip="website_creator_corp_tooltip" creator-tooltip="website_creator_creator_tooltip" />
                    </div>
                </div>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip')" :label="`${$t('website_title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="website" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="website" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <url-search @fillFormWithUrl="fillFormWithUrl($event)" />
                <contributors name="authors" type="website" creator-tooltip="website_creator_corp_tooltip" artist-tooltip="website_creator_creator_tooltip" />
                <!-- <FormulateInput :tooltip="$t('websitename_tooltip')" error-behavior="submit" name="websitename" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <!-- <div v-else class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('website_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                    <div class="column is-1"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('website_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication'), copyrightyear: $t('yeartypecopyright'), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('website_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" tip="cse_state_tooltip" dc /></div>
                    <div class="column"><countries :lang="citation.lang" tip="cse_country_tooltip" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('website_publisher_tooltip')" name="publisher" :label="`${$t('publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="website" req />
                <Controls :loading="loading" @formreset="formreset($event)" formname="website" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import Controls from '@/components/forms/Controls';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import CompCalendar from '@/components/forms/CompCalendar';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import UrlSearch from '@/components/forms/UrlSearch';


    export default {
        name: 'website',

        components: {
            SysLang,
            Contributors,
            Controls,
            Contribs,
            Url,
            Modal,
            CompCalendar,
            States,
            Countries,
            UrlSearch
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'website';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('website');
                this.citation.system = data;
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            fillFormWithUrl: function(data) {
                let d = data.payload.result;
                // console.log(d);
                if (this.citation.system === 'apa5') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'apa7') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'chicago_notes') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'chicago_date') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'mla') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'ieee') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.websitename = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
                else if (this.citation.system === 'cse_date' || this.citation.system === 'cse_cit') {
                    if (d['author']) {
                        this.citation.hascorpauthor = true;
                        this.citation.corpauthor = d['author'];
                    }
                    this.citation.title = d['title'];
                    this.citation.publisher = d['websitename'];
                    this.citation.uri = d['url'];
                    this.citation.dateaccessed = d['dateaccessed'];
                }
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.urlsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.yeartype = 'publicationyear',
                    this.citation.state = 'empty',
                    this.citation.country = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },
        },
    }
</script>
