<template>
  <div id="app" :style="{ fontSize: `${fontsize}rem` }">
    <cookie-law :buttonText="$t('agree_button_text')" position="bottom" transitionName="fade" theme="dark-lime" :buttonDecline=true :buttonDeclineText="$t('decline_button_text')" buttonDeclineClass="Cookie__button--abort">
      <div slot="message">
        {{ $t('consent_message') }} <router-link class="terms-footer-link" to="/terms">{{ $t('terms_title') }}.</router-link>
      </div>
    </cookie-law>
    <nav class="navbar is-fixed-top is-spaced" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <router-link :to="{ name: 'index' }" class="navbar-item" title="atCite">
            <img src="./assets/images/logo.png" class="logo" alt="atCite" />
          </router-link>
          <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMain" @click="burgerOpen = !burgerOpen" :class="{ 'is-active': burgerOpen }">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          <accessibility style="margin-top: .75rem; margin-right: .5rem" v-if="mobile" @themechanged="themechanged" @fontincreased="fontincreased" @fontdecreased="fontdecreased" />
        </div>
        <div id="navbarMain" class="navbar-menu" :class="{ 'is-active': burgerOpen }">
          <div class="navbar-end" :class="mobile ? 'mobile' : ''">
            <div class="navbar-item has-dropdown" @mouseenter="typeMenuOpen = true" @mouseleave="typeMenuOpen = false" :class="{ 'is-active': typeMenuOpen }" >
              <div @click="closeMenu"><a class="navbar-link">{{ $t('source_type') }}</a></div>
              <div class="navbar-dropdown" @click="typeMenuOpen = false">
                <div @click="closeMenu"><router-link :to="{ name: 'book' }" class="navbar-item">{{ $t('book') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'section' }" class="navbar-item">{{ $t('section') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'journal' }" class="navbar-item">{{ $t('journal') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'conference' }" class="navbar-item">{{ $t('conference') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'newspaper' }" class="navbar-item">{{ $t('newspaper') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'digitalmedia' }" class="navbar-item">{{ $t('digitalmedia') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'website' }" class="navbar-item">{{ $t('website') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'thesis' }" class="navbar-item">{{ $t('thesis') }}</router-link></div>
                <div @click="closeMenu"><router-link :to="{ name: 'notes' }" class="navbar-item">{{ $t('notes') }}</router-link></div>
              </div>
            </div>
            <div @click="closeMenu">
              <router-link to="/shortcite" class="navbar-item">
              <!-- <img src="./assets/images/shortcite_logo.png" alt="shortCite" style="width: 20px; padding-right: 5px" /> -->
              {{ $t('menu_shortcite') }}
            </router-link>
            </div>
            <div @click="closeMenu"><router-link to="/about" class="navbar-item">{{ $t('menu_about') }}</router-link></div>
            <div @click="closeMenu"><router-link to="/contact" class="navbar-item">{{ $t('menu_contact') }}</router-link></div>
            <locale-switcher @languageChanged="closeMenu" />
            <accessibility v-if="!mobile" style="border: 1px solid white; border-radius: 5px;" @themechanged="themechanged" @fontincreased="fontincreased" @fontdecreased="fontdecreased" />
          </div>
        </div>
      </div>
    </nav>
    <section class="section">
      <div class="container hero is-fullheight-with-navbar">
        <router-view />
      </div>
      <notifications position="bottom right" />
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
          <strong>atCite</strong> &copy; 2020 | <router-link to="/terms" class="terms-footer-link">{{ $t('terms_title') }}</router-link>
      </div>
    </footer>
  </div>
</template>

<script>
  import LocaleSwitcher from '@/components/LocaleSwitch';
  import { isMobile } from 'mobile-device-detect';
  import Accessibility from '@/components/Accessibility';
  import CookieLaw from 'vue-cookie-law';
  import { bus } from '@/main';

  export default {
    name: 'App',

    components: {
      LocaleSwitcher,
      Accessibility,
      CookieLaw,
    },

    data: () => ({
      burgerOpen: false,
      typeMenuOpen: false,
      darkMode: false,
      fontsize: 1,
      mobile: isMobile,
    }),

    methods: {
        fontdecreased() {
          this.fontsize < 1 ? this.fontsize = 0.75 : this.fontsize -= 0.25;
        },

        fontincreased() {
          this.fontsize >= 1.75 ? this.fontsize = 1.75 : this.fontsize += 0.25;
        },

        themechanged: function(data) {
          this.darkMode = data;
        },

        closeMenu() {
          this.burgerOpen = false;
        }
    },

    watch: {
      fontsize: function() {
        bus.$emit('fontsizechanged', this.fontsize);
      },
    },
  }
</script>

<style>
  #app > .Cookie--bottom > .Cookie__buttons > button.Cookie__button,
  #app > .Cookie--bottom > .Cookie__buttons > button.Cookie__button:hover {
    background: #697000;
    background-color: #697000;
  }
  a.navbar-item {
    color: #ffffff;
  }

</style>