<template>
    <div class="columns is-mobile is-vcentered is-multiline is-variable is-0-mobile">
        <div class="column is-3">
            <FormulateInput v-model="date" id="date" key="date" error-behavior="submit" type="number" name="date" min="1" max="31" validation="optional|number|between:0,32,value" :validation-messages="{ number: $t('validation_invalid_date'), between: $t('validation_invalid_date') }" :label="`${$t('compcalendar_date')}`" :tooltip="`${!!dateTip && dateTip !== '' ? $t(dateTip) : $t('compcalendar_date_tooltip')}`" />
        </div>
        <div class="column is-1 mt-5 has-text-centered">
            <span>/</span>
        </div>
        <div class="column is-4">
            <FormulateInput v-model="month" id="month" key="month" error-behavior="submit" type="select" name="month" :options=months[this.l] :label="`${$t('compcalendar_month')}`" :tooltip="`${!!monthTip && monthTip !== '' ? $t(monthTip) : $t('compcalendar_month_tooltip')}`" />
        </div>
        <div class="column is-1 mt-5 has-text-centered">
            <span>/</span>
        </div>
        <div class="column is-3">
            <FormulateInput v-model="year" error-behavior="submit" type="number" name="year" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" :label="`${$t('compcalendar_year')}`" :tooltip="`${!!yearTip && yearTip !== '' ? $t(yearTip) : $t('compcalendar_year_tooltip')}`" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CompCalendar',

        props: {
            lang: {
                type: String
            },
            dateTip: {
                type: String,
                required: false,
            },
            monthTip: {
                type: String,
                required: false,
            },
            yearTip: {
                type: String,
                required: false,
            },
        },

        data: () => ({
            l: 0,

            months: [
                { '0': '- Χωρίς επιλογή -', '1': 'Ιανουάριος', '2': 'Φεβρουάριος', '3': 'Μάρτιος', '4': 'Απρίλιος', '5': 'Μάιος', '6': 'Ιούνιος', '7': 'Ιούλιος', '8': 'Αύγουστος', '9': 'Σεπτέμβριος', '10': 'Οκτώβριος', '11': 'Νοέμβριος', '12': 'Δεκέμβριος' },
                { '0': '- Empty selection -', '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
            ],

            date: '',
            month: '0',
            year: ''
        }),

        methods: {
        },

        mounted() {
            this.$nextTick(function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            })
        },

        watch: {
            lang: function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            },
        },
    }
</script>