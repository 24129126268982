<template>
    <div>
        <div class="source-page-title">{{ $t('book') }}</div>
        <hr />
        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="original_year" :tooltip="$t('book_original_year_tooltip')" :label="`${$t('original_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" dc /></div>
                    <!-- <countries :lang="citation.lang" /> -->
                </div>
                <div class="columns is-desktop">
                    <div class="column is-4"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <!--
                <FormulateInput error-behavior="submit" name="seriestitle" :label="`${$t('seriestitle')}`" :tooltip="$t('book_seriestitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <contributors name="serieseditors" type="book" :showcorp=false />
                -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <Url :lang="citation.lang" mediatype="book" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="original_year" :tooltip="$t('book_original_year_tooltip')" :label="`${$t('original_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="single">
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('apa7_publisher')}`" :tooltip="$t('apa7_book_publisher_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1.5em" />
                    </FormulateInput>
                    <!-- <FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /> -->
                </div>
                <doi-uri :lang="citation.lang" mediatype="book" @shortenurl="shortenurl($event)" />
                <!-- <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('book_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                </div>
                <FormulateInput key="cn_volumeseries" v-model="volumeseries" name="volumeseries" :options="{ empty: $t('none_label'), volume: $t('volume_label'), series: $t('series_label') }" type="radio" :label="`${$t('volumeseries_label')}: `" style="display: flex" />
                <div v-if="(!!volumeseries && volumeseries !== 'empty' && volumeseries === 'volume')" class="double">
                    <FormulateInput key="cn_journalvolume" :style="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-' ? 'width: 25%' : 'width: 100%; margin-bottom: 1.5em'" error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('book_volume_tooltip')" />
                    <FormulateInput key="cn_volumetitle" v-if="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-'" style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div v-if="(!!volumeseries && volumeseries !== 'empty' && volumeseries === 'series')" class="double">
                    <FormulateInput key="cn_seriestitle" :style="!!citation.seriestitle && citation.seriestitle !== '' && citation.seriestitle !== '-' ? 'width: 75%' : 'width: 100%; margin-bottom: 1.5em'" error-behavior="submit" name="seriestitle" :label="`${$t('series')}`" :tooltip="$t('series_tooltip')" />
                    <FormulateInput key="cn_seriesno" v-if="!!citation.seriestitle && citation.seriestitle !== '' && citation.journalvolumetitle !== '-'" style="width: 25%" error-behavior="submit" name="seriesno" :label="`${$t('seriesno')}`" type="number" :tooltip="$t('seriesno_tooltip')" min="1" max="1000" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('book_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="book" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <!-- <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div> -->
                </div>
                <FormulateInput key="cn_volumeseries" v-model="volumeseries" name="volumeseries" :options="{ empty: $t('none_label'), volume: $t('volume_label'), series: $t('series_label') }" type="radio" :label="`${$t('volumeseries_label')}: `" style="display: flex" />
                <div v-if="(!!volumeseries && volumeseries !== 'empty' && volumeseries === 'volume')" class="double">
                    <FormulateInput key="cn_journalvolume" :style="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-' ? 'width: 25%' : 'width: 100%; margin-bottom: 1.5em'" error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('book_volume_tooltip')" />
                    <FormulateInput key="cn_volumetitle" v-if="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-'" style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div v-if="(!!volumeseries && volumeseries !== 'empty' && volumeseries === 'series')" class="double">
                    <FormulateInput key="cn_seriestitle" :style="!!citation.seriestitle && citation.seriestitle !== '' && citation.seriestitle !== '-' ? 'width: 75%' : 'width: 100%; margin-bottom: 1.5em'" error-behavior="submit" name="seriestitle" :label="`${$t('series')}`" :tooltip="$t('series_tooltip')" />
                    <FormulateInput key="cn_seriesno" v-if="!!citation.seriestitle && citation.seriestitle !== '' && citation.journalvolumetitle !== '-'" style="width: 25%" error-behavior="submit" name="seriesno" :label="`${$t('seriesno')}`" type="number" :tooltip="$t('seriesno_tooltip')" min="1" max="1000" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <!-- <div class="double"> -->
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" type="number" name="seriesno" :tooltip="$t('seriesno_tooltip')" :label="`${$t('seriesno')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_series'), between: $t('validation_invalid_series') }" /> -->
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <!-- Το αφήνω σαν failsafe σε περίπτωση που χρειαστώ και τον τίτλο τόμου. Προς το παρόν θα χρησιμοποιήσω τον τίτλο τόμου ως τίτλο σειράς
                    <FormulateInput style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                    <!-- <FormulateInput style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('series')}`" :tooltip="$t('series_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                <!-- </div> -->
                <!-- <series /> -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('book_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="book" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <FormulateInput error-behavior="submit" name="series" :tooltip="$t('serieswithno_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" /></div>
                </div>
                <!-- <div class="double">
                    <FormulateInput style="width: 25%" error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" />
                    <FormulateInput style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" />
                </div> -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="websitename" :tooltip="$t('book_websitename_tooltip')" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="book" />
                <Controls :loading="loading" formname="book" @formreset="formreset($event)" @shortenurl="shortenurl($event)" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contribs name="authors" type="book" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="book_hasauthors_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                </div>                    
                <contribs name="editors" type="book" single-name="editor" creator-tooltip="book_haseditors_tooltip" />
                <contribs name="translators" type="book" single-name="translator" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="series" :tooltip="$t('serieswithno_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" dc /></div>
                    <div class="column"><countries :lang="citation.lang" /></div>
                    <!-- <FormulateInput error-behavior="submit" name="country" :label="`${$t('country')}`" :tooltip="$t('country_tooltip')" /> -->
                    <!-- <FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('ieee_chapter_title')}`" :tooltip="$t('ieee_chapter_title_tooltip')" />
                    <FormulateInput error-behavior="submit" type="number" name="section" :tooltip="$t('book_section_tooltip')" :label="`${$t('book_section')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /> -->
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" type="number" name="chapter" :tooltip="$t('book_chapter_tooltip')" :label="`${$t('book_chapter')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /> -->
                    <!-- <FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_pages_tooltip')" /> -->
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="book" />
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('book_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="book" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="book" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <isbn-search @fillFormWithIsbn="fillFormWithIsbn($event)" />
                <contributors name="authors" type="book" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="book_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('book_title_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="book" :showcorp=false artist-tooltip="book_haseditors_tooltip" />
                <contributors name="translators" type="book" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('book_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" tip="cse_state_tooltip" dc /></div>
                    <div class="column"><countries :lang="citation.lang" tip="cse_country_tooltip" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="chapter" :label="`${$t('book_chapter')}`" min="0" max="1000" validation="optional|number|between:0,1000,value" :validation-messages="{ number: $t('validation_invalid_chapter') }" :tooltip="$t('book_chapter_tooltip')" /></div>
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="chaptertitle" :label="`${$t('ieee_chapter_title')}`" :tooltip="$t('ieee_chapter_title_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('book_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="seriestitle" :label="`${$t('seriestitle')}`" :tooltip="$t('book_seriestitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-4"><FormulateInput error-behavior="submit" name="seriesvolume" type="number" :label="`${$t('seriesvolume')}`" min="0" max="1000" validation="optional|number|between:0,1000,value" :validation-messages="{ number: $t('validation_invalid_chapter') }" :tooltip="$t('seriesvolume_tooltip')" /></div>
                </div>
                <contributors name="serieseditors" type="book" :showcorp=false artist-tooltip="book_hasserieseditors_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('book_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('book_spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('book_spages_tooltip')" /></div>
                </div>
                <url :lang="citation.lang" mediatype="book" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="book" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import Controls from '@/components/forms/Controls';
    // import Series from '@/components/forms/Series';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import DoiUri from '@/components/forms/DoiUrl';
    import IsbnSearch from '@/components/forms/IsbnSearch';

    export default {
        name: 'Book',

        components: {
            SysLang,
            Contributors,
            Contribs,
            Controls,
            // Series,
            States,
            Countries,
            Url,
            Modal,
            DoiUri,
            IsbnSearch,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
            volumeseries: 'empty',
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'book';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('book');
                this.citation.system = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.state = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.volumeseries = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.volumeseries = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.state = 'empty',
                    this.citation.country = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.isbnsearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.haseditors = false,
                    this.citation.hastranslators = false,
                    this.citation.yeartype = 'publicationyear',
                    this.citation.state = 'empty',
                    this.citation.country = 'empty',
                    this.citation.hasserieseditors = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            fillFormWithIsbn: function(data) {
                let d = data.payload.result;

                // console.log(d);

                if (this.citation.system === 'apa5') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    this.citation.series = (d['series'] && d['series'] !== '') ? d['series'] : '';
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else if (this.citation.system === 'apa7') {
                    this.citation.title = d['title'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    this.citation.series = (d['series'] && d['series'] !== '') ? d['series'] : '';
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                    this.citation.publishers = [{ publisher: d['publisher'] }];
                }
                else if (this.citation.system === 'chicago_notes') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    if ((d['series'] && d['series'] !== '') || (d['seriesno'] && d['seriesno'] !== '')) {
                        this.citation.volumeseries = 'series';
                        this.citation.seriestitle = (d['series'] && d['series'] !== '') ? d['series'].trim() : '';
                        this.citation.seriesno = (d['seriesno'] && d['seriesno'] !== '') ? d['seriesno'].trim() : '';
                    }
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else if (this.citation.system === 'chicago_date') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    if ((d['series'] && d['series'] !== '') || (d['seriesno'] && d['seriesno'] !== '')) {
                        this.citation.volumeseries = 'series';
                        this.citation.seriestitle = (d['series'] && d['series'] !== '') ? d['series'].trim() : '';
                        this.citation.seriesno = (d['seriesno'] && d['seriesno'] !== '') ? d['seriesno'].trim() : '';
                    }
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else if (this.citation.system === 'mla') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    this.citation.series = (d['series'] && d['series'] !== '') ? d['series'] : '';
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else if (this.citation.system === 'ieee') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    this.citation.series = (d['series'] && d['series'] !== '') ? d['series'] : '';
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else if (this.citation.system === 'cse_date' || this.citation.system === 'cse_cit') {
                    this.citation.title = d['title'];
                    this.citation.publisher = d['publisher'];
                    this.citation.year = d['year'];
                    this.citation.city = (d['city'] && d['city'] !== '') ? d['city'] : '';
                    if ((d['series'] && d['series'] !== '') || (d['seriesno'] && d['seriesno'] !== '')) {
                        this.citation.volumeseries = 'series';
                        this.citation.seriestitle = (d['series'] && d['series'] !== '') ? d['series'].trim() : '';
                        this.citation.seriesvolume = (d['seriesno'] && d['seriesno'] !== '') ? d['seriesno'].trim() : '';
                    }
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    if (d['editors'] && d['editors'].length > 0) {
                        this.citation.haseditors = true;
                        this.citation.editors = d['editors'];
                    }
                    if (d['translators'] && d['translators'].length > 0) {
                        this.citation.hastranslators = true;
                        this.citation.translators = d['translators'];
                    }
                }
                else {
                    // TODO: General handler
                }
            },
        },
    }
</script>
