<template>
    <div style="margin-bottom: 0">
        <div class="columns is-desktop">
            <div class="column is-narrow b-pad" v-if="!!optionsLabel">
                <label class="my-label" style="text-align: justify">{{ `${$t(optionsLabel)}: ` }}</label>
            </div>
            <div class="column is-narrow t-pad b-pad" v-if="showCorp">
                <FormulateInput v-model="hascorp" :tooltip="!!corpTooltip ? $t(corpTooltip) : $t('hasextracorpcreator_tooltip')" type="checkbox" :label="!!corpLabel ? $t(corpLabel) : $t('extracorporate_creator')" :name="`${name}_hascorp`" @change="creatorchange($event)" />
            </div>
            <div class="column is-narrow t-pad b-pad" v-if="showCreator">
                <FormulateInput v-model="hascreator" type="checkbox" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t(`hasextracreators_tooltip`)" :label="!!creatorLabel ? $t(creatorLabel) : $t('extracreators')" :name="`${name}_hascreator`" @change="creatorchange($event)" />
            </div>
            <div class="column is-narrow t-pad" v-if="showArtist">
                <FormulateInput v-model="hasartist" type="checkbox" :tooltip="!!artistTooltip ? $t(artistTooltip) : $t(`hasextraartists_tooltip`)" :label="!!artistLabel ? $t(artistLabel) : $t('extraartists')" :name="`${name}_hasartist`" @change="creatorchange($event)" />
            </div>
        </div>
        <div class="columns is-desktop" v-if="focusoncontrib && (hascorp || hascreator || hasartist)">
            <!-- <div class="column is-narrow b-pad">
                <label class="my-label" style="text-align: justify">{{ `${$t('focusOnContribsLabel')} ` }}</label>
            </div> -->
            <div class="column is-narrow t-pad b-pad">
                <FormulateInput key="mla_focusoncontribs" v-model="focusoncontribs" :tooltip="$t('focusoncontribs_tooltip')" type="checkbox" :label="$t('focusoncontribs')" name="focusoncontribs" />
            </div>
        </div>
        <FormulateInput v-model="corpauthor" error-behavior="submit" v-if="hascorp" type="group" :repeatable="false" :name="`${name}_authors`">
            <div class="columns is-desktop">
                <div class="column" :class="!!showContribution ? 'is-8' : ''"><FormulateInput error-behavior="submit" :key="`${name}_corp_lname`" name="lastname" :placeholder="`${$t('corpauthorname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin" v-if="showContribution" :class="!!showContribution ? 'is-4' : ''"><FormulateInput :key="`${name}_crt_contrib`" error-behavior="submit" name="contribution" :options="contributiontypes" type="select" :placeholder="$t('contribution_label')" validation="optional" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="creators" error-behavior="submit" v-if="hascreator" type="group" :name="`${name}_authors`" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" :key="`${name}_crt_lname`" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" :key="`${name}_crt_fname`" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column" :class="!showContribution ? 'group-margin' : ''"><FormulateInput error-behavior="submit" :key="`${name}_crt_mname`" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin" v-if="showContribution"><FormulateInput :key="`${name}_crt_contrib`" error-behavior="submit" name="contribution" :options="contributiontypes" type="select" :placeholder="$t('contribution_label')" validation="optional" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="artists" error-behavior="submit" v-if="hasartist" type="group" :name="`${name}_authors`" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em" >
            <div class="columns is-desktop">
                <div class="column" :class="[(!showContribution ? 'group-margin' : ''), (!!showContribution ? 'is-8' : '')]"><FormulateInput error-behavior="submit" :key="`${name}_art_lname`" name="lastname" :placeholder="`${$t('artistname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin" v-if="showContribution" :class="!!showContribution ? 'is-4' : ''"><FormulateInput :key="`${name}_crt_contrib`" error-behavior="submit" name="contribution" :options="contributiontypes" type="select" :placeholder="$t('contribution_label')" validation="optional" /></div>
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    import i18n from '@/i18n.js';

    export default {
        name: 'Creators2',

        props: {
            name: String,
            lang: String,
            mediaType: String,
            system: String,
            optionsLabel: String,
            showCorp: Boolean,
            showCreator: Boolean,
            showArtist: Boolean,
            showContribution: Boolean,
            contributions: Object,
            corpLabel: String,
            creatorLabel: String,
            artistLabel: String,
            corpTooltip: String,
            creatorTooltip: String,
            artistTooltip: String,
            focusoncontrib: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data: () => ({
            hascorp: false,
            hascreator: false,
            hasartist: false,
            corpauthor: {},
            creators: {},
            artists: {},
            contribution: '',
            focusoncontribs: false
        }),

        methods: {
            creatorchange: function($event) {
                this.corpauthor = {};
                this.creators = {};
                this.artists = {};
                this.contribution = '';
                switch ($event.target.name) {
                // switch ($event.target.name.split('_')[1]) {
                    case this.name + '_hascorp':
                        this.hascreator = this.hasartist = false;
                    break;
                    case this.name + '_hascreator':
                        this.hascorp = this.hasartist = false;
                    break;
                    case this.name + '_hasartist':
                        this.hascorp = this.hascreator = false;
                    break;
                    default:
                        this.hascorp = this.hascreator = this.hasartist = false;
                    break;
                }
            }
        },

        computed: {
            contributiontypes: function() {
                let res = {};

                if (!!this.system && (this.system === 'cse_date' || this.system === 'cse_cit')) {
                    switch (this.mediaType) {
                        case 'film':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                cse_editor: i18n.t('cse_editor', this.lang),
                                cse_director: i18n.t('cse_director', this.lang),
                                cse_producer: i18n.t('cse_producer', this.lang),
                            }
                        break;
                        case 'online_video':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                cse_uploader: i18n.t('cse_uploader', this.lang),
                            }
                        break;
                        case 'image':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                cse_photographer: i18n.t('cse_photographer', this.lang),
                            }
                        break;
                        default:
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                cse_editor: i18n.t('cse_editor', this.lang),
                                cse_director: i18n.t('cse_director', this.lang),
                                cse_producer: i18n.t('cse_producer', this.lang),
                                cse_uploader: i18n.t('cse_uploader', this.lang),
                                cse_photographer: i18n.t('cse_photographer', this.lang),
                            }
                        break;
                    }
                }
                else if (!!this.system && this.system === 'apa5') {
                    switch (this.mediaType) {
                        case 'song':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_composer: i18n.t('apa7_composer', this.lang),
                                apa7_singer: i18n.t('apa7_singer', this.lang),
                            }
                        break;
                        case 'album':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa5_lyricist: i18n.t('apa5_lyricist', this.lang),
                                apa7_composer: i18n.t('apa7_composer', this.lang),
                                apa7_singer: i18n.t('apa7_singer', this.lang),
                            }
                        break;
                        case 'film':
                        case 'tvseries':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                            }
                        break;
                        case 'episode':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                            }
                        break;
                        case 'broadcast':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                                apa7_guestexpert: i18n.t('apa7_guestexpert', this.lang),
                                apa7_host: i18n.t('apa7_host', this.lang),
                            }
                        break;
                        case 'producers':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                            }
                        break;
                        case 'online_video':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_uploader: i18n.t('apa7_uploader', this.lang),
                            }
                        break;
                        case 'photo':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_photographer: i18n.t('apa7_photographer', this.lang),
                            }
                        break;
                        default:
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                                apa7_composer: i18n.t('apa7_composer', this.lang),
                                apa7_singer: i18n.t('apa7_singer', this.lang),
                                apa7_uploader: i18n.t('apa7_uploader', this.lang),
                                apa7_photographer: i18n.t('apa7_photographer', this.lang),
                                apa7_guestexpert: i18n.t('apa7_guestexpert', this.lang),
                                apa7_host: i18n.t('apa7_host', this.lang),
                            }
                        break;
                    }
                }
                else if (!!this.system && this.system === 'mla') {
                    switch (this.mediaType) {
                        case 'film':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                mla_creator: i18n.t('mla_creator', this.lang),
                                mla_director: i18n.t('mla_director', this.lang),
                                mla_performer: i18n.t('mla_performer', this.lang),
                            }
                        break;
                        case 'episode':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                mla_creator: i18n.t('mla_creator', this.lang),
                                mla_director: i18n.t('mla_director', this.lang),
                                mla_performer: i18n.t('mla_performer', this.lang),
                            }
                        break;
                        default:
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                            }
                        break;
                    }
                }
                else {
                    switch (this.mediaType) {
                        case 'song':
                        case 'album':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_composer: i18n.t('apa7_composer', this.lang),
                                apa7_singer: i18n.t('apa7_singer', this.lang),
                            }
                        break;
                        case 'film':
                        case 'tvseries':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                            }
                        break;
                        case 'episode':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                                apa7_guestexpert: i18n.t('apa7_guestexpert', this.lang),
                                apa7_host: i18n.t('apa7_host', this.lang),
                            }
                        break;
                        case 'producers':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                            }
                        break;
                        case 'online_video':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_uploader: i18n.t('apa7_uploader', this.lang),
                            }
                        break;
                        case 'photo':
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_photographer: i18n.t('apa7_photographer', this.lang),
                            }
                        break;
                        default:
                            res = {
                                empty: i18n.t('empty_selection', this.lang),
                                apa7_director: i18n.t('apa7_director', this.lang),
                                apa7_producer: i18n.t('apa7_producer', this.lang),
                                apa7_writer: i18n.t('apa7_writer', this.lang),
                                apa7_composer: i18n.t('apa7_composer', this.lang),
                                apa7_singer: i18n.t('apa7_singer', this.lang),
                                apa7_uploader: i18n.t('apa7_uploader', this.lang),
                                apa7_photographer: i18n.t('apa7_photographer', this.lang),
                                apa7_guestexpert: i18n.t('apa7_guestexpert', this.lang),
                                apa7_host: i18n.t('apa7_host', this.lang),
                            }
                        break;
                    }
                }
                return res;
            },
        },
        
        watch: {
        },
    }
</script>
