<template>
    <div style="margin-bottom: 0">
        <div class="columns is-desktop">
            <div class="column is-narrow b-pad"><label class="my-label" style="text-align: justify">{{ `${$t('conductors_label')}: ` }}</label></div>
            <div class="column is-narrow b-pad t-pad"><FormulateInput v-model="hasconductor" error-behavior="submit" type="checkbox" :tooltip="$t(`hasconductor_tooltip`)" :label="$t('hasconductor')" name="hasconductor" @change="extracreatorchange($event)" /></div>
            <div class="column is-narrow t-pad"><FormulateInput v-model="hasconductorartist" error-behavior="submit" type="checkbox" :tooltip="$t(`hasconductorartists_tooltip`)" :label="$t('conductorartists')" name="hasconductorartist" @change="extracreatorchange($event)" /></div>
        </div>
        <!-- <FormulateInput v-model="corpconductor" error-behavior="submit" v-if="hascorpconductor" name="corpconductor" validation="required|invalidText" style="margin-bottom: 1.5em" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :style="hascorpconductor ? 'marginBottom: 1em' : 'marginBottom: 0'" :placeholder="`${$t('corpauthorname')} (*)`" /> -->
        <FormulateInput v-model="conductors" error-behavior="submit" v-if="hasconductor" type="group" name="conductors" :repeatable="true" :add-label="$t('add')" minimum="1"  style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="conductorartists" error-behavior="submit" v-if="hasconductorartist" type="group" name="conductors" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em" >
            <div :style="hasconductorartist ? 'marginBottom: 1.6em' : 'marginBottom: 0'">
                <FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('artistname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Conductors',

        data: () => ({
            // hascorpconductor: false,
            hasconductor: false,
            hasconductorartist: false,
            // corpconductor: '',
            conductors: {},
            conductorartists: {},
        }),

        methods: {
            extracreatorchange: function(e) {
                // this.corpconductor = '';
                this.conductors = {};
                this.conductorartists = {};
                switch (e.target.name) {
                    // case 'hascorpconductor':
                    //     this.hasconductor = false;
                    //     this.hasconductorartist = false;
                    //     break;
                    case 'hasconductor':
                        // this.hascorpconductor = false;
                        this.hasconductorartist = false;
                        break;
                    case 'hasconductorartist':
                        // this.hascorpconductor = false;
                        this.hasconductor = false;
                        break;
                    default:
                        // this.hascorpconductor = false;
                        this.hasconductor = false;
                        this.hasconductorartist = false;
                        break;
                }
            }
        },

        watch: {
            value: function() {
            },
        }
    }
</script>

<style>
    div[data-type=radio] > div > div {
        display: flex
    }
    .my-label {
        display: block;
        line-height: 1.5;
        font-size: .9em;
        font-weight: 600;
        margin-bottom: .1em;
        margin-right: 1em
    }
</style>