<template>
    <div>
        <div class="source-page-title">{{ $t('journal') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <!-- <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false /> -->
                <div class="columns is-desktop">
                    <div class="column is-6"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <!-- <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div> -->
                    <div class="column is-6"><comp-calendar :lang="citation.lang" month-tip="journal_month_tooltip" year-tip="journal_year_tooltip" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput :tooltip="$t('pagesarticlenotype_tooltip')" error-behavior="submit" name="pagesarticlenotype" :options="{ empty: $t('empty_selection', this.citation.lang), pages: $t('journal_option_pages', this.citation.lang), articleno: $t('journal_option_articleno', this.citation.lang) }" type="select" :label="`${$t('pagesarticlenotype')}`" /></div>
                    <div v-if="citation.pagesarticlenotype === 'pages'" class="column"><FormulateInput key="apa5_journal_pages" error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                    <div v-if="citation.pagesarticlenotype === 'articleno'" class="column"><FormulateInput key="apa5_journal_articleno" error-behavior="submit" name="articleno" :label="`${$t('articleno')} (*)`" :tooltip="$t('journal_articleno_tooltip_mand')" validation="bail|required" :validation-messages="{ required: $t('validation_required_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /></div> -->
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                </div>
                <Url :lang="citation.lang" mediatype="journal" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" :values="citation.authors" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.title" />
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.journalname" /></div>
                    <div class="column is-4"><comp-calendar :lang="citation.lang" month-tip="journal_month_tooltip" year-tip="journal_year_tooltip" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput :tooltip="$t('pagesarticlenotype_tooltip')" error-behavior="submit" name="pagesarticlenotype" :options="{ empty: $t('empty_selection'), pages: $t('journal_option_pages'), articleno: $t('journal_option_articleno') }" type="select" :label="`${$t('pagesarticlenotype')}`" :value="citation.pagesarticlenotype" /></div>
                    <div v-if="citation.pagesarticlenotype === 'pages'" class="column"><FormulateInput key="apa7_journal_pages" error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" :value="citation.pages" /></div>
                    <div v-if="citation.pagesarticlenotype === 'articleno'" class="column"><FormulateInput key="apa7_journal_articleno" error-behavior="submit" name="articleno" :label="`${$t('articleno')} (*)`" :tooltip="$t('journal_articleno_tooltip_mand')" validation="bail|required" :validation-messages="{ required: $t('validation_required_text') }" :value="citation.articleno" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" :value="citation.journalvolume" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" :value="citation.journalissue" /></div>
                </div>
                <doi-uri :lang="citation.lang" mediatype="journal" @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <contributors name="bookauthors" type="section" artist-label="editors" :showcorp=false artist-tooltip="journal_hasbookauthors_tooltip" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_spages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="100" validation="optional|^number|between:0,31,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="journal" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <contributors name="bookauthors" type="section" artist-label="editors" :showcorp=false artist-tooltip="journal_hasbookauthors_tooltip" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_spages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="100" validation="optional|^number|between:0,31,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="journal" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" key="mla_journal_authors" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" key="mla_journal_title" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" key="mla_journal_journalname" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-2"><FormulateInput error-behavior="submit" type="select" key="mla_journal_month" name="month" :tooltip="$t('journal_mla_month_tooltip')" :label="`${$t('journal_month_mla')}`" :options="[ { value: 0, label: $t('empty_selection', citation.lang) }, { value: 999, label: '----------------------', disabled: true }, { value: 1, label: $t('january', citation.lang) }, { value: 2, label: $t('february', citation.lang) }, { value: 3, label: $t('march', citation.lang) }, { value: 4, label: $t('april', citation.lang) }, { value: 5, label: $t('may', citation.lang) }, { value: 6, label: $t('june', citation.lang) }, { value: 7, label: $t('july', citation.lang) }, { value: 8, label: $t('august', citation.lang) }, { value: 9, label: $t('september', citation.lang) }, { value: 10, label: $t('october', citation.lang) }, { value: 11, label: $t('november', citation.lang) }, { value: 12, label: $t('december', citation.lang) }, { value: 998, label: '----------------------', disabled: true }, { value: 13, label: $t('season_winter', citation.lang) }, { value: 14, label: $t('season_spring', citation.lang) }, { value: 15, label: $t('season_summer', citation.lang) }, { value: 16, label: $t('season_autumn', citation.lang) } ]" /></div>
                    <div class="column is-2"><FormulateInput error-behavior="submit" type="number" key="mla_journal_year" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" key="mla_journal_pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" key="mla_journal_spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_spages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" key="mla_journal_journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" key="mla_journal_doi" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" name="websitename" :tooltip="$t('journal_websitename_tooltip')" key="mla_journal_websitename" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" key="mla_journal_uri" mediatype="journal" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contribs name="authors" type="journal" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="journal_hasauthors_tooltip" :values="citation.authors" />
                <FormulateInput error-behavior="submit" key="mla_journal_title" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" key="mla_journal_journalname" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-4"><comp-calendar :lang="citation.lang" month-tip="journal_month_tooltip" year-tip="journal_year_tooltip" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" key="mla_journal_journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                    <div class="column"><FormulateInput :tooltip="$t('pagesarticlenotype_tooltip')" error-behavior="submit" name="pagesarticlenotype" :options="{ empty: $t('empty_selection', citation.lang), pages: $t('pages'), articleno: $t('articleno') }" type="select" :label="`${$t('pages_articleno')}`" /></div>
                    <div v-if="citation.pagesarticlenotype === 'pages'" class="column"><FormulateInput key="ieee_journal_pages" error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                    <div v-if="citation.pagesarticlenotype === 'articleno'" class="column"><FormulateInput key="ieee_journal_articleno" error-behavior="submit" name="pages" :label="`${$t('articleno')} (*)`" :tooltip="$t('journal_articleno_tooltip_mand')" validation="bail|required" :validation-messages="{ required: $t('validation_required_text') }" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" key="mla_journal_doi" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" key="ieee_journal_uri" mediatype="journal" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="journal" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="journal" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <doi-search @fillFormWithDoi="fillFormWithDoi($event)" />
                <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                </div>
                <div class="columns">
                    <div class="column"><FormulateInput :tooltip="$t('csejournalpagestype_tooltip')" error-behavior="submit" name="pagestype" :options="{ pages: $t('startendpages'), total: $t('totalpages') }" type="select" :label="`${$t('csejournalpagestype')}`" /></div>
                    <div v-if="citation.pagestype === 'pages'" class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('csejournalpagespages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                    <div v-if="citation.pagestype === 'total'" class="column"><FormulateInput error-behavior="submit" type="number" name="pages" :label="`${$t('csejournalpagestotal')} (*)`" min="1" max="9999" validation="bail|required|number|between:0,10000,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" :tooltip="$t('journal_pagestotalno_tooltip_mand')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(^\d+([-–]\d+)?(\s)?(\w+)?$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="doi" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('journal_doi_tooltip')" /></div>
                </div>
                <url mediatype="journal" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="journal" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import Controls from '@/components/forms/Controls';
    import CompCalendar from '@/components/forms/CompCalendar';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import DoiUri from '@/components/forms/DoiUrl';
    import DoiSearch from '@/components/forms/DoiSearch';

    export default {
        name: 'Journal',

        components: {
            SysLang,
            Contributors,
            Contribs,
            Controls,
            CompCalendar,
            Url,
            Modal,
            DoiUri,
            DoiSearch,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),
        
        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'journal';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('journal');
                this.citation.system = data;
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.pagesarticlenotype = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.title = false,
                    this.citation.journalname = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.pagesarticlenotype = 'empty',
                    this.citation.journalvolume = false,
                    this.citation.journalissue = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.month = '0',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.pagesarticlenotype = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.doisearchtext = '',
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.yeartype = 'publicationyear',
                    this.citation.pagestype = 'pages',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },

            handleAuthors: function(input) {
                let authors = [];
                input.forEach(function(a) {
                    if (a['family'] && a['family'] !== '' && a['given'] && a['given'] !== '') {
                        if (a['given'].indexOf(' ') >= 0) {
                            authors.push({ lastname: a['family'], firstname: a['given'].split(' ')[0], middlename: a['given'].split(' ')[1] });
                        }
                        else {
                            authors.push({ lastname: a['family'], firstname: a['given']});
                        }
                    }
                    else {
                        let c = a['family'].replace(' - ', '-').split(' ').length - 1;
                        if (c === 2) {
                            authors.push({ lastname: a['family'].split(' ')[2], firstname: a['family'].split(' ')[0], middlename: a['family'].split(' ')[1] });
                        }
                        else if (c === 1) {
                            authors.push({ lastname: a['family'].split(' ')[1], firstname: a['family'].split(' ')[0] });
                        }
                        else {
                            authors.push({ lastname: a['family'] });
                        }
                    }
                });
                return authors;
            },

            fillFormWithDoi: function(data) {
                // let d = JSON.parse(data);
                // let d = this.sanitizeJSON(data);
                let d = data;
                if (this.citation.system === 'apa5') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.date = (d['published']) ? d['published']['date-parts'][0][2] : d['issued']['date-parts'][0][2];
                    this.citation.month = (d['published']) ? d['published']['date-parts'][0][1] : d['issued']['date-parts'][0][1];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pagesarticlenotype = 'pages';
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();
                }
                else if (this.citation.system === 'apa7') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.date = (d['published']) ? d['published']['date-parts'][0][2] : d['issued']['date-parts'][0][2];
                    this.citation.month = (d['published']) ? d['published']['date-parts'][0][1] : d['issued']['date-parts'][0][1];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pagesarticlenotype = 'pages';
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();
                }
                else if (this.citation.system === 'chicago_notes') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.doi = d['DOI'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();
                }
                else if (this.citation.system === 'chicago_date') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.doi = d['DOI'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();   
                }
                else if (this.citation.system === 'mla') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.month = (d['published']) ? d['published']['date-parts'][0][1] : d['issued']['date-parts'][0][1];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.websitename = d['publisher'];
                    this.citation.doi = d['DOI'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();                    
                }
                else if (this.citation.system === 'ieee') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.date = (d['published']) ? d['published']['date-parts'][0][2] : d['issued']['date-parts'][0][2];
                    this.citation.month = (d['published']) ? d['published']['date-parts'][0][1] : d['issued']['date-parts'][0][1];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pagesarticlenotype = 'pages';
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.doi = d['DOI'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();            
                }
                else if (this.citation.system === 'cse_date' || this.citation.system === 'cse_cit') {
                    if (d['author'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = this.handleAuthors(d['author']);
                    }
                    this.citation.title = d['title'];
                    this.citation.journalname = d['container-title'];
                    this.citation.month = (d['published']) ? d['published']['date-parts'][0][1] : d['issued']['date-parts'][0][1];
                    this.citation.year = (d['published']) ? d['published']['date-parts'][0][0] : d['issued']['date-parts'][0][0];
                    this.citation.pages = d['page'];
                    this.citation.journalvolume = d['volume'];
                    this.citation.journalissue = d['issue'];
                    this.citation.doi = d['DOI'];
                    this.citation.uri = d['URL'];
                    this.citation.dateaccessed = this.formatDate();   
                }
            },
        },
    }
</script>
