<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        {{ $t('index_title') }}
                    </h1>
                    <!-- <h2 class="subtitle">
                        <p>{{ $t('index_text') }}</p>
                    </h2> -->
                </div>
            </div>
        </section>
        <div class="container" style="padding: 0 1.5rem; line-height: 2rem">
            <div>
                <!-- <figure class="text-img">
                    <img src="../../assets/images/logo_black.png" alt="atCite">
                </figure> -->
            </div>
            <div class="has-text-justified has-background">
                <!-- <br /> -->
                <p>
                    {{ $t('index_line_1') }}
                </p>
                <!-- <br /> -->
                <p style="text-indent: 3em">
                    {{ $t('index_line_2') }}
                    <!-- <br /> -->
                </p>
                <!-- <p><button type="button" @click="notifyUser">Notify user</button></p> -->
            </div>
        </div>
        <intro-modal v-show="showIntroModal" @close="showIntroModal = false" />
    </div>
</template>

<script>
    import IntroModal from '@/components/IntroModal';

    export default {
        name: 'Index',

        components: {
            IntroModal,
        },

        data: () => ({
            showIntroModal: false,
        }),

        mounted() {
            this.$nextTick(function () {
                this.showIntroModal = (process.env.VUE_APP_SHOW_INTRO_MODAL === 'true') ? true : false;
            })
        },

        methods: {
        },
    }
</script>

<style scoped>
    .text-img {
        max-width: 512px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .has-background {
        background-image: url('../../assets/images/logo_trans_new.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 75%;
    }
</style>