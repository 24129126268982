// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
<template>
    <div class="control has-icons-left">
        <div class="select is-small" style="margin-left: 1em" :style="{ fontSize: `${fontSize}em` }">
            <select v-model="$i18n.locale" id="locale-switcher" @change="onChange()">
                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value" :selected="i === 0">{{ lang.text }}</option>
            </select>
        </div>
        <span class="icon is-small is-left" style="margin-left: 1em; color: grey" :style="{ fontSize: `${fontSize}em` }">
            <i class="fas fa-globe"></i>
        </span>
    </div>
</template>

<script>
    import { bus } from '../main';
    import i18n from '@/i18n.js';
    
    export default {
        name: 'locale-switcher',

        data() {
            return {
                langs: [
                    { value: 'el', text: i18n.t('el') }, 
                    { value: 'en', text: i18n.t('en') }
                ],
                fontSize: .75
            }
        },

        methods: {
            onChange() {
                this.$emit('languageChanged')
            }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data / 1.35).toString();
          });
        },
    }
</script>

<style scoped>
    /* #locale-switcher {
        line-height: 1
    } */
</style>