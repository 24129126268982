<template>
    <div v-if="enabled" class="columns is-desktop searchbar">
        <div class="column is-12">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <FormulateInput error-behavior="submit" key="isbnsearchtext" name="isbnsearchtext" v-model="isbn" :tooltip="$t('isbnsearchtext_tooltip')" :label="`${$t('isbnsearchtext')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div class="control">
                    <button class="input" @click.prevent="isbnsearch" :style="{ fontSize: `${fontSize}em` }" :title="$t(`isbnsearch`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em" :disabled="!!working">
                        <span id="search-button" :class="working ? 'searching' : 'idle'"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import { bus } from '@/main';

    export default {
        name: 'IsbnSearch',

        data: () => ({
            isbn: '',
            working: false,
            fontSize: '1',
            enabled: (process.env.VUE_APP_ISBN_SEARCH_ENABLED === 'true') ? true : false,
        }),

        methods: {
            isbnsearch: function() {
                if (this.isbn !== '') {
                    this.working = true;
                    axios.post(`${process.env.VUE_APP_API_BASE_URL}isbn-search/`, {
                        isbn: this.isbn
                    })
                    .then((response) => {
                        // console.log(response.data);
                        this.$emit('fillFormWithIsbn', response.data);
                    })
                    .catch(() => {
                        this.$notify({
                            type: 'error',
                            text: i18n.t('isbnretrieveerror', this.lang)
                        });
                    })
                    .then(() => {
                        this.working = false;
                    });
                }
                else {
                    this.$notify({
                        type: 'error',
                        text: i18n.t('isbnsearchvalidationerror', this.lang)
                    });
                }
            }
        },

        created() {
            bus.$on('fontsizechanged', (data) => {
                this.fontSize = (data).toString();
            });
        },
    }
</script>
