<template>
    <div class="group-margin">
        <div class="columns is-desktop">
            <div class="column is-narrow b-pad" v-if="!!optionsLabel">
                <label class="my-label" style="text-align: justify">{{ `${$t(optionsLabel)}: ` }}</label>
            </div>
            <div class="column is-narrow" v-if="showCorp" ><FormulateInput v-model="hascorpauthor" :tooltip="!!corpTooltip ? $t(corpTooltip) : $t('hascorpauthor_tooltip')" error-behavior="submit" type="checkbox" :label="$t(`corporate_${singleName}`) + `${hascorpauthor ? ` (*)` : ``}`" name="hascorpauthor" style="margin-bottom: 0" @change="hasauthor = false" /></div>
            <div class="column is-narrow"><FormulateInput v-model="hasauthor" error-behavior="submit" type="checkbox" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t(`${type}_has${name}_tooltip`)" :label="$t(`${name}`) + `${hasauthor ? ` (*)` : ``}`" :name="`has${name}`" style="margin-bottom: 0" @change="hascorpauthor = false" /></div>
        </div>
        <FormulateInput error-behavior="submit" v-if="hascorpauthor" :name="`corp${singleName}`" :key="`corp${singleName}`" validation="required|invalidText" style="margin-bottom: 1.5em" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :style="hascorpauthor ? 'marginBottom: 1em' : 'marginBottom: 0'" :placeholder="`${$t('corpauthorname')} (*)`" />
        <FormulateInput :value="values" error-behavior="submit" v-if="hasauthor" type="group" :name=name :key="`${name}`" :repeatable="true" :add-label="$t('add')" minimum="1"  style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Contribs',

        props: {
            name: String,
            singleName: String,
            type: String,
            showCorp: Boolean,
            corpTooltip: String,
            creatorTooltip: String,
            optionsLabel: String,
            values: {
                type: Array,
                required: false
            },            
        },

        data: () => ({
            hascorpauthor: false,
            hasauthor: false,
            // corpauthor: '',
            // authors: {}
        }),

        methods: {
            creatorchange: function(e) {
                this.corpauthor = '';
                this.authors = {};
                switch (e.target.name) {
                    case 'hascorpauthor':
                        this.hasauthor = false;
                        break;
                    case 'hasauthor':
                        this.hascorpauthor = false;
                        break;
                    default:
                        this.hascorpauthor = false;
                        this.hasauthor = false;
                        break;
                }
            }
        },

        watch: {
        }
    }
</script>

<style>
    div[data-type=radio] > div > div {
        display: flex
    }
</style>