<template>
    <div>
        <div class="source-page-title">{{ $t('conference') }}</div>
        <hr />
        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="apa5_conference" name="apa5_conferencetype" type="select" validation="required" :tooltip="$t('apa5_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('apa5_conferencetype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ apa5_published_symposium: $t('apa5_published_symposium'), apa5_published_proceedings: $t('apa5_published_proceedings'), apa5_unpublished_symposium: $t('apa5_unpublished_symposium'), apa5_unpublished_paper: $t('apa5_unpublished_paper'), apa5_poster_session: $t('apa5_poster_session'), apa5_electronic_symposium: $t('apa5_electronic_symposium'), apa5_electronic_paper: $t('apa5_electronic_paper') }" @change="apa5_conferencetypechanged" />
                <div v-if="citation.apa5_conferencetype === 'apa5_published_symposium'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip"  />
                    <FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip_mand')" name="conferencetitle" :label="`${$t('conferencetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <div class="column is-9"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('conference_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('spages_tooltip')" /></div> -->
                    </div>
                    <publisher-location :lang="citation.lang" :dc="true" city-tip="conference_publishcity_tooltip" country-tip="conference_country_tooltip" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_published_proceedings'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('apa5_conference_type')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings '" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('conference_volume_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('spages_tooltip')" /></div> -->
                    </div>
                    <publisher-location :lang="citation.lang" :dc="true" city-tip="conference_publishcity_tooltip" country-tip="conference_country_tooltip" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_unpublished_symposium'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column is-2"><FormulateInput id="month" key="month" error-behavior="submit" type="select" name="month" :options="{0: $t('empty_selection', citation.lang), 1: $t('january', citation.lang), 2: $t('february', citation.lang), 3: $t('march', citation.lang), 4: $t('april', citation.lang), 5: $t('may', citation.lang), 6: $t('june', citation.lang), 7: $t('july', citation.lang), 8: $t('august', citation.lang), 9: $t('september', citation.lang), 10: $t('october', citation.lang), 11: $t('november', citation.lang), 12: $t('december', citation.lang) }" :label="`${$t('compcalendar_month')}`" :tooltip="`${$t('compcalendar_month_tooltip')}`" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetitle" :label="`${$t('apa5_conference_proceedings_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" :tooltip="$t('conference_titleorg_tooltip')" name="meetingtitle" :label="`${$t('meetingtitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <publisher-location :lang="citation.lang" :dc="true" city-tip="conference_publishcity_tooltip" country-tip="conference_country_tooltip" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_unpublished_paper'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" :tooltip="$t('conference_titleorg_tooltip_mand')" name="conferencetitle" :label="`${$t('meetingtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2"><FormulateInput id="month" key="month" error-behavior="submit" type="select" name="month" :options="{0: $t('empty_selection', citation.lang), 1: $t('january', citation.lang), 2: $t('february', citation.lang), 3: $t('march', citation.lang), 4: $t('april', citation.lang), 5: $t('may', citation.lang), 6: $t('june', citation.lang), 7: $t('july', citation.lang), 8: $t('august', citation.lang), 9: $t('september', citation.lang), 10: $t('october', citation.lang), 11: $t('november', citation.lang), 12: $t('december', citation.lang) }" :label="`${$t('compcalendar_month')}`" :tooltip="`${$t('compcalendar_month_tooltip')}`" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <publisher-location :lang="citation.lang" :dc="true" city-tip="conference_publishcity_tooltip" country-tip="conference_country_tooltip" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_poster_session'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_poster_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_poster_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_poster_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" :tooltip="$t('conference_poster_titleorg_tooltip_mand')" name="conferencetitle" :label="`${$t('meetingtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2"><FormulateInput id="month" key="month" error-behavior="submit" type="select" name="month" :options="{0: $t('empty_selection', citation.lang), 1: $t('january', citation.lang), 2: $t('february', citation.lang), 3: $t('march', citation.lang), 4: $t('april', citation.lang), 5: $t('may', citation.lang), 6: $t('june', citation.lang), 7: $t('july', citation.lang), 8: $t('august', citation.lang), 9: $t('september', citation.lang), 10: $t('october', citation.lang), 11: $t('november', citation.lang), 12: $t('december', citation.lang) }" :label="`${$t('compcalendar_month')}`" :tooltip="`${$t('conference_poster_compcalendar_month_tooltip')}`" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_poster_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <publisher-location :lang="citation.lang" :dc="true" city="conference_poster_publishcity" city-tip="conference_poster_publishcity_tooltip" country-tip="conference_poster_country_tooltip" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference_poster" condreq />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_electronic_symposium'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div v-if="!citation.hascorpauthor && !citation.hasauthors" class="column is-10"><FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div v-else class="column is-10"><FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2">
                            <FormulateInput name="abstract" error-behavior="submit" type="checkbox" :label="`${$t('apa5_conference_abstract')}`" :tooltip="$t('apa5_conference_abstract_tooltip')" validation="optional" label-position="before" />
                        </div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_titleorg_tooltip_mand')" name="conferencetitle" :label="`${$t('meetingtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><calendar error-behavior="submit" name="year" key="apa5_digitalconference_date" :tooltip="$t('apa5_digitalconference_date_tooltip')" :label="`${$t('apa5_digitalconference_date')}`" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" req />
                </div>
                <div v-if="citation.apa5_conferencetype === 'apa5_electronic_paper'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_titleorg_tooltip_mand')" name="conferencetitle" :label="`${$t('meetingtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('apa5_digitalconference_year_tooltip')" :label="`${$t('apa5_digitalconference_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" req />
                </div>
                <!--
                <contributors name="authors" type="conference" creator-tooltip="apa5_hascorpauthor_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false />
                <FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings '" />
                <FormulateInput error-behavior="submit" :tooltip="$t('conference_org_tooltip')" name="conferenceorg" :label="`${$t('conferenceorg')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_year') }" /></div>
                </div>
                <div class="columns is-desktop">                
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><calendar name="conferencestartdate" /></div>
                    <div class="column"><calendar name="conferenceenddate" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_proceedings_city_tooltip')" name="city" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" condreq />
                -->
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <!-- <div class="instruction" v-html="$t('apa7_conference_instruction')"></div> -->
                <FormulateInput error-behavior="submit" key="apa7_conference" name="apa7_conferencetype" type="select" validation="required" :tooltip="$t('apa7_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('apa7_conferencetype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ apa7_published_proceedings: $t('apa7_published_proceedings'), apa7_conference_session: $t('apa7_conference_session'), apa7_published_paper: $t('apa7_published_paper'), apa7_poster_session: $t('apa7_poster_session'), apa7_symposium: $t('apa7_symposium') }" @change="apa7_conferencetypechanged" />
                <div v-if="citation.apa7_conferencetype === 'apa7_published_proceedings'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" artist-label="editors" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput error-behavior="submit" name="conferencetitle" :tooltip="$t('apa7_conference_proceedings_title_tooltip')" :label="`${$t('apa7_conference_proceedings_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="translators" type="conference" :showcorp=false artist-tooltip="conference_hastranslators_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('conference_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('conference_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_pages_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                    </div>
                    <doi-uri :lang="citation.lang" mediatype="conference" @shortenurl="shortenurl($event)" />
                </div>
                <div v-if="citation.apa7_conferencetype === 'apa7_conference_session'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('apa7_general_conference_title_tooltip_mand')" name="conferencename" :label="`${$t('apa7_general_conference_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="conferencestartdate" :tooltip="$t('conference_startdate_tooltip')" /></div>
                        <div class="column"><calendar name="conferenceenddate" :tooltip="$t('conference_enddate_tooltip')" /></div>
                    </div>
                    <!-- <contributors name="editors" type="conference" artist-label="editors" :showcorp=false artist-tooltip="conference_haseditors_tooltip" /> -->
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <doi-uri :lang="citation.lang" mediatype="conference" @shortenurl="shortenurl($event)" />
                </div>
                <div v-if="citation.apa7_conferencetype === 'apa7_published_paper'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('apa7_general_conference_title_tooltip_mand')" name="conferencename" :label="`${$t('apa7_general_conference_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="conferencestartdate" :tooltip="$t('conference_startdate_tooltip')" /></div>
                        <div class="column"><calendar name="conferenceenddate" :tooltip="$t('conference_enddate_tooltip')" /></div>
                    </div>
                    <!-- <contributors name="editors" type="conference" artist-label="editors" :showcorp=false artist-tooltip="conference_haseditors_tooltip" /> -->
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <doi-uri :lang="citation.lang" mediatype="conference" @shortenurl="shortenurl($event)" />
                </div>
                <div v-if="citation.apa7_conferencetype === 'apa7_poster_session'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_poster_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_poster_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_poster_titleorg_tooltip_mand')" name="conferencename" :label="`${$t('apa7_general_conference_title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="conferencestartdate" :tooltip="$t('apa7_poster_startdate_tooltip')" /></div>
                        <div class="column"><calendar name="conferenceenddate" :tooltip="$t('apa7_poster_enddate_tooltip')" /></div>
                    </div>
                    <!-- <contributors name="editors" type="conference" artist-label="editors" :showcorp=false artist-tooltip="conference_haseditors_tooltip" /> -->
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_poster_publishcity_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_poster_country_tooltip" /></div>
                    </div>
                    <Url :lang="citation.lang" mediatype="conference_poster" @shortenurl="shortenurl($event)" />
                </div>
                <div v-if="citation.apa7_conferencetype === 'apa7_symposium'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_name_tooltip_mand')" name="conferencename" :label="`${$t('conference_name')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="conferencestartdate" :tooltip="$t('conference_startdate_tooltip')" /></div>
                        <div class="column"><calendar name="conferenceenddate" :tooltip="$t('conference_enddate_tooltip')" /></div>
                    </div>
                    <contributors name="editors" type="conference" artist-label="editors" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <doi-uri :lang="citation.lang" mediatype="conference" @shortenurl="shortenurl($event)" />
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="cn_conferencetype" name="cn_conferencetype" type="select" validation="required" :tooltip="$t('cn_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('cn_conferencetype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ cn_conference_section: $t('cn_conference_section'), cn_conference_journal: $t('cn_conference_journal'), cn_conference_general: $t('cn_conference_general') }" @change="cn_conferencetypechanged" />
                <div v-if="citation.cn_conferencetype === 'cn_conference_section'">
                    <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('conference_type_tooltip_mand')" :label="`${$t('apa5_conference_type')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="bookauthors" type="section" :showcorp=false artist-label="editors" artist-tooltip="conference_haseditors_tooltip" />
                    <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('cn_conference_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    </div>
                    <div class="columns is-desktop" v-if="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-'">
                        <div class="column"><FormulateInput key="cn_volumetitle" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('chicago_section_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('conference_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_pages_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                </div>
                <div v-else-if="citation.cn_conferencetype === 'cn_conference_journal'">
                    <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                    <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <contributors name="bookauthors" type="section" artist-label="editors" :showcorp=false artist-tooltip="journal_hasbookauthors_tooltip" />
                    <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_spages_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                        <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="100" validation="optional|^number|between:0,31,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <Url @shortenurl="shortenurl($event)" mediatype="journal" />
                </div>
                <div v-else-if="citation.cn_conferencetype === 'cn_conference_general'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column is-9">
                            <FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                        </div>
                        <div class="column is-3">
                            <FormulateInput error-behavior="submit" key="cn_conferencepapertype" name="description" type="select" :tooltip="$t('cn_conferencepapertype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('cn_conferencepapertype')}`" :options="{ empty: $t('empty_selection'), cn_conference_paper_ppt: $t('cn_conference_paper_ppt'), cn_conference_paper_lecture: $t('cn_conference_paper_lecture'), cn_conference_paper_poster: $t('cn_conference_paper_poster'), cn_conference_paper_paper: $t('cn_conference_paper_paper') }" />
                        </div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" :tooltip="$t('cn_conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><comp-calendar key="cn_conference_general_date" :lang="citation.lang" date-tip="cn_conference_compcalendar_day_tooltip" month-tip="cn_conference_compcalendar_month_tooltip" year-tip="cn_conference_compcalendar_year_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_location_tooltip_mand')" name="city" :label="`${$t('conference_location')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states key="cn_conference_states" :lang="citation.lang "/></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                </div>
                <div v-else>
                    <!--
                    Old/Wrong approach
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings'" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><calendar name="conferencestartdate" /></div>
                        <div class="column"><calendar name="conferenceenddate" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_proceedings_city_tooltip')" name="city" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                    -->
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="cd_conferencetype" name="cd_conferencetype" type="select" validation="required" :tooltip="$t('cn_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('cn_conferencetype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ cd_conference_section: $t('cn_conference_section'), cd_conference_journal: $t('cn_conference_journal'), cd_conference_general: $t('cn_conference_general') }" @change="cd_conferencetypechanged" />
                <div v-if="citation.cd_conferencetype === 'cd_conference_section'">
                    <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('conference_sourcetitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('conference_type_tooltip_mand')" :label="`${$t('apa5_conference_type')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="bookauthors" type="section" :showcorp=false artist-label="editors" artist-tooltip="conference_haseditors_tooltip" />
                    <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('cn_conference_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    </div>
                    <!-- <div class="columns is-desktop" v-if="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-'">
                        <div class="column"><FormulateInput key="cd_volumetitle" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('chicago_section_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div> -->
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('conference_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_pages_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                </div>
                <div v-else-if="citation.cd_conferencetype === 'cd_conference_journal'">
                    <contributors name="authors" type="journal" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="journal_hasauthors_tooltip" />
                    <FormulateInput error-behavior="submit" name="title" :tooltip="$t('journalsourcetitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="journalname" :tooltip="$t('journalname_tooltip')" :label="`${$t('journalname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('journal_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <contributors name="bookauthors" type="section" artist-label="editors" :showcorp=false artist-tooltip="journal_hasbookauthors_tooltip" />
                    <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" validation="bail|required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_pages_tooltip_mand')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('journal_spages_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('journal_volume_tooltip')" /></div>
                        <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="100" validation="optional|^number|between:0,31,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('journal_issue_tooltip')" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('journal_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <Url @shortenurl="shortenurl($event)" mediatype="journal" />
                </div>
                <div v-else-if="citation.cd_conferencetype === 'cd_conference_general'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column is-9">
                            <FormulateInput error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                        </div>
                        <div class="column is-3">
                            <FormulateInput error-behavior="submit" key="cd_conferencepapertype" name="description" type="select" :tooltip="$t('cn_conferencepapertype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('cn_conferencepapertype')}`" :options="{ empty: $t('empty_selection'), cn_conference_paper_ppt: $t('cn_conference_paper_ppt'), cn_conference_paper_lecture: $t('cn_conference_paper_lecture'), cn_conference_paper_poster: $t('cn_conference_paper_poster'), cn_conference_paper_paper: $t('cn_conference_paper_paper') }" />
                        </div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" :tooltip="$t('cn_conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><comp-calendar key="cd_conference_general_date" :lang="citation.lang" date-tip="cn_conference_compcalendar_day_tooltip" month-tip="cn_conference_compcalendar_month_tooltip" year-tip="cn_conference_compcalendar_year_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_location_tooltip_mand')" name="city" :label="`${$t('conference_location')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states key="cd_conference_states" :lang="citation.lang "/></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                </div>
                <div v-else>
                    <!--
                    Old/Wrong approach
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings'" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-6"><conference-dates :lang="citation.lang" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" :tooltip="$t('conference_proceedings_city_tooltip')" name="city" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('doiurl_tooltip')" :label="`${$t('doi')}`" validation="optional|^url|invalidText" :validation-messages="{ url: $t('validation_invalid_url'), invalidText: $t('validation_invalid_text') }" />
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                    -->
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings'" />
                <div class="columns">
                    <div class="column is-9"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><calendar name="conferencestartdate" /></div>
                    <div class="column"><calendar name="conferenceenddate" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('conference_startdate_tooltip')" type="date" name="conferencestartdate" min="1900-01-01" max="2038-12-31" :label="`${$t('conferencestartdate')}`" validation="optional|^date|after:1900-01-01|before:2038-12-31" :validation-messages="{ date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('conference_enddate_tooltip')" type="date" name="conferenceenddate" min="1900-01-01" max="2038-12-31" :label="`${$t('conferenceenddate')}`" validation="optional|^date|after:1900-01-01|before:2038-12-31" :validation-messages="{ date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_proceedings_city_tooltip')" name="city" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('conference_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="websitename" :tooltip="$t('conference_websitename_tooltip')" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="conference" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="ieee_conferencetype" name="ieee_conferencetype" type="select" validation="required" :tooltip="$t('ieee_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('ieee_conference_type')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ ieee_conference_paper: $t('ieee_conference_paper'), ieee_conference_proceedings: $t('ieee_conference_proceedings'), ieee_conference_paper_online: $t('ieee_conference_paper_online'), ieee_conference_proceedings_online: $t('ieee_conference_proceedings_online') }" @change="ieee_conferencetypechanged" />
                <div v-if="citation.ieee_conferencetype === 'ieee_conference_paper'">
                    <contribs name="authors" type="conference" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthor" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput key="ieee_conference_paper_conftype" error-behavior="submit" :tooltip="$t('ieee_conference_title_tooltip_mand')" name="conferencetype" :label="`${$t('ieee_conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" dc /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_paperno_tooltip')" name="conferencepaperno" key="ieee_conferencepaperno" :label="`${$t('conferencepaperno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><Url @shortenurl="shortenurl($event)" mediatype="conference" hidedate /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </div>
                <div v-else-if="citation.ieee_conferencetype === 'ieee_conference_proceedings'">
                    <contribs name="authors" type="conference" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contribs name="editors" type="conference" single-name="editor" creator-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput key="ieee_conference_proceedings_conftype" error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('ieee_conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proc. '" />
                    <FormulateInput error-behavior="submit" name="series" :tooltip="$t('conference_series_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" dc /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('issue_tooltip')" key="ieee_journal_journalissue" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')} (*)`" :tooltip="$t('conference_pages_tooltip_mand')" validation="required|matches:/(\d+$)/" :validation-messages="{ required: $t('validation_required_text'), matches: $t('validation_invalid_pages') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><Url @shortenurl="shortenurl($event)" mediatype="conference" hidedate /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </div>
                <div v-else-if="citation.ieee_conferencetype === 'ieee_conference_paper_online'">
                    <contribs name="authors" type="conference" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthor" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput key="ieee_conference_paper_conftype" error-behavior="submit" :tooltip="$t('ieee_conference_title_tooltip_mand')" name="conferencetype" :label="`${$t('ieee_conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" dc /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><Url @shortenurl="shortenurl($event)" mediatype="conference" hidedate req /></div>
                    </div>
                </div>
                <div v-else-if="citation.ieee_conferencetype === 'ieee_conference_proceedings_online'">
                    <contribs name="authors" type="conference" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthor" error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <contribs name="editors" type="conference" single-name="editor" creator-tooltip="conference_haseditors_tooltip" />
                    <FormulateInput key="ieee_conference_proceedings_conftype" error-behavior="submit" :tooltip="$t('conference_type_tooltip_mand')" name="conferencetype" :label="`${$t('ieee_conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proc. '" />
                    <FormulateInput error-behavior="submit" name="series" :tooltip="$t('conference_series_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" dc /></div>
                        <div class="column"><countries :lang="citation.lang" tip="conference_country_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('book_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('issue_tooltip')" key="ieee_journal_journalissue" name="journalissue" :label="`${$t('journalissue')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_issue') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><Url @shortenurl="shortenurl($event)" mediatype="conference" hidedate req /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="doi" :tooltip="$t('conference_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </div>
                <div v-else>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="conference" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <!-- <sys-lang form="conference" @systemchanged="systemchanged" @langchanged="langchanged" /> -->
            <sys-lang form="conference" @langchanged="langchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="ssf_conferencetype" name="ssf_conferencetype" type="select" validation="required" :tooltip="$t('ssf_conferencetype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('ssf_conferencetype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ ssf_conference_proceedings: $t('ssf_conference_proceedings'), ssf_conference_paper: $t('ssf_conference_paper'), ssf_conference_poster: $t('ssf_conference_poster') }" @change="ssf_conferencetypechanged" />
                <div v-if="citation.ssf_conferencetype === 'ssf_conference_proceedings'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <div class="columns is-desktop">
                        <div v-if="!citation.hascorpauthor && !citation.hasauthors" class="column is-10"><FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_sourcetitle_tooltip')" name="title" :label="`${$t('ssf_conference_sourcetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div v-else class="column is-10"><FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('ssf_conference_sourcetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2">
                            <FormulateInput key="ssfabstract" name="abstract" error-behavior="submit" type="checkbox" :label="`${$t('ssf_conference_abstract')}`" :tooltip="$t('ssf_conference_abstract_tooltip')" validation="optional" label-position="before" />
                        </div>
                    </div>
                    <contributors name="editors" type="conference" creator-label="corpeditor" :showcorp=false artist-tooltip="conference_haseditors_tooltip" />
                    <contributors name="translators" type="conference" :showcorp=false artist-tooltip="conference_hastranslators_tooltip" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_proceedings_title_tooltip')" name="proceedingstitle" :label="`${$t('ssf_conference_proceedings_title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_title_tooltip_mand')" name="conferencetype" :label="`${$t('conferencetype')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :value="citation.lang === 'el' ? 'Πρακτικά ' : 'Proceedings '" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column">
                            {{ $t('conference_location') }}
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" type="conference" tip="cse_state_tooltip" dc /></div>
                                <div class="column"><countries :lang="citation.lang" type="conference" tip="conference_cse_country_tooltip" /></div>
                            </div>
                        </div>
                        <div class="column">
                            {{ $t('publication_location') }}
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('conference_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" tip="cse_state_tooltip" dc /></div>
                                <div class="column"><countries :lang="citation.lang" tip="conference_cse_country_tooltip" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                        <!-- <div class="column"><calendar name="conferencestartdate" /></div>
                        <div class="column"><calendar name="conferenceenddate" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_publisher_tooltip')" name="publisher" :label="`${$t('conference_publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('conference_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-else-if="citation.ssf_conferencetype === 'ssf_conference_paper'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('ssf_conference_sourcetitle_tooltip')" name="title" :label="`${$t('ssf_conference_sourcetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('ssf_conference_sourcetitle_tooltip_mand')" name="title" :label="`${$t('ssf_conference_sourcetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_paper_title_tooltip')" name="proceedingstitle" :label="`${$t('ssf_conference_paper_title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                    <FormulateInput error-behavior="submit" key="ssf_conferencetype_paper" :tooltip="$t('ssf_conference_paper_title_tooltip_mand')" name="conferencetype" :label="`${$t('ssf_conference_paper_title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('conference_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_city_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" type="conference" tip="cse_state_tooltip" dc /></div>
                        <div class="column"><countries :lang="citation.lang" type="conference" tip="conference_cse_country_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference" condreq />
                </div>
                <div v-else-if="citation.ssf_conferencetype === 'ssf_conference_poster'">
                    <contributors name="authors" type="conference" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="conference_poster_hasauthors_tooltip" />
                    <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" :tooltip="$t('conference_poster_sourcetitle_tooltip')" name="title" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput v-else error-behavior="submit" :tooltip="$t('conference_poster_sourcetitle_tooltip_mand')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput error-behavior="submit" key="ssf_conferencetype_poster" :tooltip="$t('conference_poster_titleorg_tooltip_mand')" name="conferencetype" :label="`${$t('meetingtitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('ssf_conference_poster_title_tooltip')" name="conferencetitle" :label="`${$t('conferencetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('ssf_conference_poster_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('conference_poster_publishcity_tooltip')" name="conferencecity" :label="`${$t('conferencecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states :lang="citation.lang" type="conference" tip="ssf_conference_poster_state_tooltip" dc /></div>
                        <div class="column"><countries :lang="citation.lang" type="conference" tip="conference_poster_country_tooltip" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><conference-dates :lang="citation.lang" :sdate-tip="$t('ssf_conference_poster_sdate_tooltip')" :smonth-tip="$t('ssf_conference_poster_smonth_tooltip')" :edate-tip="$t('ssf_conference_poster_edate_tooltip')" :emonth-tip="$t('ssf_conference_poster_emonth_tooltip')" :year-tip="$t('ssf_conference_poster_eyear_tooltip')" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="conference_poster" condreq />
                </div>
                <div v-else></div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="conference" />
            </div>
        </FormulateForm>

        <div v-else></div>
        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import Controls from '@/components/forms/Controls';
    import ConferenceDates from '@/components/forms/ConferenceDates';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Calendar from '@/components/forms/Calendar';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import DoiUri from '@/components/forms/DoiUrl';
    import PublisherLocation from '@/components/forms/PublisherLocation';
    import CompCalendar from '@/components/forms/CompCalendar';

    export default {
        name: 'Conference',

        components: {
            SysLang,
            Contributors,
            Contribs,
            Controls,
            ConferenceDates,
            CompCalendar,
            States,
            Countries,
            Calendar,
            Url,
            Modal,
            DoiUri,
            PublisherLocation,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'conference';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('conference');
                this.citation.system = data;
            },

            apa5_conferencetypechanged: function() {
                // this.citation.hascorpauthor = false;
                // this.citation.hasauthor = false;
                // this.citation.hasartist = false;
                // this.citation.title = '';
                // this.citation.producer = '';
                // this.citation.publisher = '';
                // this.citation.uploader = '';
                // this.citation.year = null;
                // this.citation.timestamp = '';
                // this.citation.city = '';
                // this.citation.digitalmediaalbum = '';
                // this.citation.season = '';
                // this.citation.episode = '';
                // this.citation.uri = '';
                // this.citation.dateaccessed = null;
                this.$formulate.resetValidation('conference');
            },

            apa7_conferencetypechanged: function() {
                // this.citation.hascorpauthor = false;
                // this.citation.hasauthor = false;
                // this.citation.hasartist = false;
                // this.citation.title = '';
                // this.citation.producer = '';
                // this.citation.publisher = '';
                // this.citation.uploader = '';
                // this.citation.year = null;
                // this.citation.timestamp = '';
                // this.citation.city = '';
                // this.citation.digitalmediaalbum = '';
                // this.citation.season = '';
                // this.citation.episode = '';
                // this.citation.uri = '';
                // this.citation.dateaccessed = null;
                this.$formulate.resetValidation('conference');
            },

            cn_conferencetypechanged: function() {
                this.$formulate.resetValidation('conference');
            },

            cd_conferencetypechanged: function() {
                this.$formulate.resetValidation('conference');
            },

            ieee_conferencetypechanged: function() {
                if (this.citation.ieee_conferencetype === 'ieee_conference_proceedings' || this.citation.ieee_conferencetype === 'ieee_conference_proceedings_online') {
                    if (this.citation.conferencetype === '') {
                        this.citation.conferencetype = (this.citation.lang === 'el') ? 'Πρακτικά ' : 'Proc. ';
                    }
                }
                this.$formulate.resetValidation('conference');
            },

            ssf_conferencetypechanged: function() {
                this.$formulate.resetValidation('conference');
            },

            langchanged: function(data) {
                if (this.citation.system !== 'ieee') {
                    if (this.citation.conferencetype === '' || this.citation.conferencetype === 'Πρακτικά ' || this.citation.conferencetype === 'Proceedings ') { 
                        this.citation.conferencetype = (data === 'el') ? 'Πρακτικά ' : 'Proceedings ';
                    }
                }
                else {
                    if (this.citation.system === 'ieee' && (this.citation.ieee_conferencetype === 'ieee_conference_proceedings' || this.citation.ieee_conferencetype === 'ieee_conference_proceedings_online')) {
                        if (this.citation.conferencetype === '' || this.citation.conferencetype === 'Πρακτικά ' || this.citation.conferencetype === 'Proc. ') {
                            this.citation.conferencetype = (data === 'el') ? 'Πρακτικά ' : 'Proc. ';
                        }
                    }
                }
                // if (this.citation.system === 'apa7') {
                //     this.citation.conferencetitle = (data === 'el') ? 'Πρακτικά ' : 'Proceedings ';
                // }
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                let conferencetype = '';
                switch (sys) {
                    case 'apa5': conferencetype = this.citation.apa5_conferencetype;
                    break;
                    case 'apa7': conferencetype = this.citation.apa7_conferencetype;
                    break;
                    case 'chicago_notes': conferencetype = this.citation.cn_conferencetype;
                    break;
                    case 'chicago_date': conferencetype = this.citation.cd_conferencetype;
                    break;
                    case 'ieee': conferencetype = this.citation.ieee_conferencetype;
                    break;
                    case 'cse_date': conferencetype = this.citation.ssf_conferencetype;
                    break;
                    case 'cse_cit': conferencetype = this.citation.ssf_conferencetype;
                    break;
                    default: conferencetype = '';
                    break;
                }
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                switch (sys) {
                    case 'apa5': this.citation.apa5_conferencetype = conferencetype;
                    break;
                    case 'apa7': this.citation.apa7_conferencetype = conferencetype;
                    break;
                    case 'chicago_notes': this.citation.cn_conferencetype = conferencetype;
                    break;
                    case 'chicago_date': this.citation.cd_conferencetype = conferencetype;
                    break;
                    case 'ieee': this.citation.ieee_conferencetype = conferencetype;
                    break;
                    case 'cse_date': this.citation.ssf_conferencetype = conferencetype;
                    break;
                    case 'cse_cit': this.citation.ssf_conferencetype = conferencetype;
                    break;
                    default: conferencetype = '';
                    break;
                }
                if (sys === 'apa5') {
                    if (this.citation.apa5_conferencetype === 'apa5_published_symposium') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.haseditors = false;
                        this.citation.location= [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_published_proceedings') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.haseditors = false;
                        this.citation.location = [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_unpublished_symposium') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.haseditors = false;
                        this.citation.location = [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.month = '0';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_unpublished_paper') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.location = [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.month = '0';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_poster_session') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.location = [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.month = '0';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_electronic_symposium') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.year = '';
                    }
                    else if (this.citation.apa5_conferencetype === 'apa5_electronic_paper') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else {
                        // TODO: General handler
                    }
                }
                if (sys === 'apa7') {
                    if (this.citation.apa7_conferencetype === 'apa7_published_proceedings') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.haseditors = false;
                        this.citation.hastranslators = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.apa7_conferencetype === 'apa7_conference_session') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencestartdate = '';
                        this.citation.conferenceenddate = '';
                    }
                    else if (this.citation.apa7_conferencetype === 'apa7_published_paper') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencestartdate = '';
                        this.citation.conferenceenddate = '';
                    }
                    else if (this.citation.apa7_conferencetype === 'apa7_poster_session') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencestartdate = '';
                        this.citation.conferenceenddate = '';
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.apa7_conferencetype === 'apa7_symposium') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencestartdate = '';
                        this.citation.conferenceenddate = '';
                        this.citation.haseditors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else {
                        // TODO: General handler
                    }
                }
                if (sys === 'chicago_notes') {
                    if (this.citation.cn_conferencetype === 'cn_conference_section') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.hasbookauthors = false;
                        this.citation.hastranslators = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.cn_conferencetype === 'cn_conference_journal') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.hasbookauthors = false;
                        this.citation.hastranslators = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.cn_conferencetype === 'cn_conference_general') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.state = 'empty';
                    }
                    else {
                        // TODO: General hanlder
                    }
                }
                if (sys === 'chicago_date') {
                    if (this.citation.cd_conferencetype === 'cd_conference_section') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.hasbookauthors = false;
                        this.citation.hastranslators = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.cd_conferencetype === 'cd_conference_journal') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.hasbookauthors = false;
                        this.citation.hastranslators = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.cd_conferencetype === 'cd_conference_general') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.state = 'empty';
                    }
                    else {
                        // TODO: General hanlder
                    }
                }
                if (sys === 'mla') {
                    this.citation.hascorpauthor = false;
                    this.citation.hasauthors = false;
                    this.citation.haseditors = false;
                    this.citation.conferencetype = '';
                    this.citation.conferencestartdate = '';
                    this.citation.conferenceenddate = '';
                    this.citation.uri = '';
                    this.citation.dateaccessed = '';
                }
                if (sys === 'ieee') {
                    if (this.citation.ieee_conferencetype === 'ieee_conference_paper') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.uri = '';
                    }
                    else if (this.citation.ieee_conferencetype === 'ieee_conference_proceedings') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.haseditors = false;
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencetype = '';
                    }
                    else if (this.citation.ieee_conferencetype === 'ieee_conference_paper_online') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                    }
                    else if (this.citation.ieee_conferencetype === 'ieee_conference_proceedings_online') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.haseditors = false;
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.conferencetype = '';
                    }
                    else {
                        // TODO: General handler
                    }
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    if (this.citation.ssf_conferencetype === 'ssf_conference_proceedings') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.haseditors = false;
                        this.citation.hastranslators = false;
                        this.citation.conferencetype = '';
                        this.citation.yeartype = 'publicationyear';
                        this.citation.conferencestate = 'empty';
                        this.citation.conferencecountry = 'empty';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.ssf_conferencetype === 'ssf_conference_paper') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.conferencestate = 'empty';
                        this.citation.conferencecountry = 'empty';
                        this.citation.yeartype = 'publicationyear';
                    }
                    else if (this.citation.ssf_conferencetype === 'ssf_conference_poster') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthors = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.startmonth = '0';
                        this.citation.endmonth = '0';
                        this.citation.conferencestate = 'empty';
                        this.citation.conferencecountry = 'empty';
                        this.citation.yeartype = 'publicationyear';
                    }
                    else {
                        // TODO: General handler
                    }
                }
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            }
        },
    }
</script>

<style>
    .instruction {
        color: #ff0000;
        font-style: italic;
        font-size: 1em;
        padding-bottom: 1.5em;
        text-align: justify;
        text-justify: inter-word;
        word-break: break-word
    }
    .instruction a {
        color: #ff0000;
        text-decoration: underline;
    }
    .instruction a:hover {
        color: #ff0000;        
    }
    #app > section > div > div > form > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div.column.is-2 > div > div,
    #app > section > div > div > form > div:nth-child(2) > div:nth-child(2) > div.column.is-2 > div > div {
        display: block;
    }
</style>