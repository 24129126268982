<template>
    <div style="margin-bottom: 0">
        <div class="columns is-desktop">
            <div class="column is-narrow b-pad"><label class="my-label" style="text-align: justify">{{ `${$t('performers_label')}: ` }}</label></div>
            <div class="column is-narrow b-pad t-pad"><FormulateInput v-model="hasperformer" error-behavior="submit" type="checkbox" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t(`hasperformer_tooltip`)" :label="$t('hasperformer')" name="hasperformer" @change="extracreatorchange($event)" /></div>
            <div class="column is-narrow t-pad"><FormulateInput v-model="hasperformerartist" error-behavior="submit" type="checkbox" :tooltip="!!artistTooltip ? $t(artistTooltip) : $t(`hasperformerartists_tooltip`)" :label="$t('performerartists')" name="hasperformerartist" @change="extracreatorchange($event)" /></div>
        </div>
        <FormulateInput v-model="performers" error-behavior="submit" v-if="hasperformer" type="group" name="performers" :repeatable="true" :add-label="$t('add')" minimum="1"  style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="performerartists" error-behavior="submit" v-if="hasperformerartist" type="group" name="performers" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em" >
            <div :style="hasperformerartist ? 'marginBottom: 1.6em' : 'marginBottom: 0'">
                <FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('artistname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Performers',

        props: {
            creatorTooltip: String,
            artistTooltip: String,
        },

        data: () => ({
            // hascorpperformer: false,
            hasperformer: false,
            hasperformerartist: false,
            // corpperformer: '',
            performers: {},
            performerartists: {},
        }),

        methods: {
            extracreatorchange: function(e) {
                // this.corpperformer = '';
                this.performers = {};
                this.performerartists = {};
                switch (e.target.name) {
                    // case 'hascorpperformer':
                    //     this.hasperformer = false;
                    //     this.hasperformerartist = false;
                    //     break;
                    case 'hasperformer':
                        this.hascorpperformer = false;
                        this.hasperformerartist = false;
                        break;
                    case 'hasperformerartist':
                        // this.hascorpperformer = false;
                        this.hasperformer = false;
                        break;
                    default:
                        // this.hascorpperformer = false;
                        this.hasperformer = false;
                        this.hasperformerartist = false;
                        break;
                }
            }
        },

        watch: {
            value: function() {
            },
        }
    }
</script>

<style>
    div[data-type=radio] > div > div {
        display: flex
    }
    .my-label {
        display: block;
        line-height: 1.5;
        font-size: .9em;
        font-weight: 600;
        margin-bottom: .1em;
        margin-right: 1em
    }
</style>