<template>
    <div class="columns is-desktop is-vcentered">
        <div class="column is-4">
            <div class="columns is-mobile">
                <div class="column"><FormulateInput id="startdate" key="startdate" error-behavior="submit" type="number" name="startdate" min="1" max="31" validation="optional|number|between:0,32,value" :validation-messages="{ number: $t('validation_invalid_date'), between: $t('validation_invalid_date') }" :label="`${$t('startdate')}`" :tooltip="`${!!sdateTip ? $t(sdateTip) : $t('startdate_tooltip')}`" /></div>
                <!-- <div class="column is-1"><span>/</span></div> -->
                <div class="column"><FormulateInput id="startmonth" key="startmonth" error-behavior="submit" type="select" name="startmonth" :options=months[this.l] :label="`${$t('startmonth')}`" :tooltip="`${!!smonthTip ? $t(smonthTip) : $t('startmonth_tooltip')}`" /></div>
            </div>
        </div>
        <div class="column is-1 conference-dates-divider has-text-centered"><span>—</span></div>
        <div class="column is-4">
            <div class="columns is-mobile">
                <div class="column"><FormulateInput id="enddate" key="enddate" error-behavior="submit" type="number" name="enddate" min="1" max="31" validation="optional|number|between:0,32,value" :validation-messages="{ number: $t('validation_invalid_date'), between: $t('validation_invalid_date') }" :label="`${$t('enddate')}`" :tooltip="`${!!edateTip ? $t(edateTip) : $t('enddate_tooltip')}`" /></div>
                <!-- <div class="column is-1"><span>/</span></div> -->
                <div class="column"><FormulateInput id="endmonth" key="endmonth" error-behavior="submit" type="select" name="endmonth" :options=months[this.l] :label="`${$t('endmonth')}`" :tooltip="`${!!emonthTip ? $t(emonthTip) : $t('endmonth_tooltip')}`" /></div>
                <!-- <div class="column is-1"><span>/</span></div> -->
            </div>
        </div>
        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="conf_year" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" :label="`${$t('conferenceyear')}`" :tooltip="`${!!yearTip ? $t(yearTip) : $t('conferenceyear_tooltip')}`" /></div>
    </div>
</template>

<script>
    export default {
        name: 'ConferenceDates',

        props: {
            lang: {
                type: String
            },
            daterequired: {
                type: Boolean
            },
            monthrequired: {
                type: Boolean
            },
            yearrequired: {
                type: Boolean
            },
            enddate: {
                type: Boolean
            },
            sdateTip: {
                type: String,
                required: false
            },
            smonthTip: {
                type: String,
                required: false
            },
            edateTip: {
                type: String,
                required: false
            },
            emonthTip: {
                type: String,
                required: false
            },
            yearTip: {
                type: String,
                required: false
            }
        },

        data: () => ({
            l: 0,

            months: [
                { '0': '- Χωρίς επιλογή -', '1': 'Ιανουάριος', '2': 'Φεβρουάριος', '3': 'Μάρτιος', '4': 'Απρίλιος', '5': 'Μάιος', '6': 'Ιούνιος', '7': 'Ιούλιος', '8': 'Αύγουστος', '9': 'Σεπτέμβριος', '10': 'Οκτώβριος', '11': 'Νοέμβριος', '12': 'Δεκέμβριος' },
                { '0': '- Empty selection -', '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
            ]
        }),

        methods: {
        },

        mounted() {
            this.$nextTick(function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            })
        },

        watch: {
            lang: function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            },
        },
    }
</script>