<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div id="intro-modal" class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" style="flex-shrink: 1; text-align: left; ">{{ $t('intromodalheader') }}</p>
                <button class="delete" aria-label="close" @click.prevent="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                <div class="intro-modal-body" v-html="$t('intromodalbody')" />
            </section>
            <footer class="modal-card-foot">
                <p>{{ $t('intromodalfooter') }}</p>
            </footer>
        </div>
    </div>
</template>

<script>
    // import i18n from '@/i18n.js';
    import { isMobile } from 'mobile-device-detect';

    export default {
        name: 'IntroModal',

        components: {

        },

        props: {

        },

        data: () => ({
            mobile: isMobile,
        }),

        methods: {

        },

        created() {
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.$emit('close');
                }
            });
        },
    }
</script>

<style scoped>
    #intro-modal header.modal-card-head {
        background-color: #790000; 
    }

    #intro-modal header .modal-card-title {
        color: #ffffff;
    }

    #intro-modal footer.modal-card-foot {
        background-color: #790000;
        color: #ffffff !important;
    }

    footer p {
        font-size: .8em;
    }
</style>