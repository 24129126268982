import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/components/pages/Index';
import ShortCite from '@/components/pages/ShortCite';
import Contact from '@/components/pages/Contact';
import About from '@/components/pages/About';
import Terms from '@/components/pages/Terms';
import NotFound from '@/components/pages/NotFound';
import Book from '@/components/forms/Book';
import Section from '@/components/forms/BookSection';
import Journal from '@/components/forms/Journal';
import Conference from '@/components/forms/Conference';
import Newspaper from '@/components/forms/Newspaper';
import DigitalMedia from '@/components/forms/DigitalMedia';
import Website from '@/components/forms/Website';
import Thesis from '@/components/forms/Thesis';
import Notes from '@/components/forms/Notes';

Vue.use(Router);

export default new Router({
    routes: [
        { path: '/', name: 'index', component: Index },
        { path: '/shortcite', name: 'shortcite', component: ShortCite },
        { path: '/contact', name: 'contact', component: Contact },
        { path: '/about', name: 'about', component: About },
        { path: '/terms', name: 'terms', component: Terms },
        { path: '/book', name: 'book', component: Book },
        { path: '/section', name: 'section', component: Section },
        { path: '/journal', name: 'journal', component: Journal },
        { path: '/conference', name: 'conference', component: Conference },
        { path: '/newspaper', name: 'newspaper', component: Newspaper },
        { path: '/digitalmedia', name: 'digitalmedia', component: DigitalMedia },
        { path: '/website', name: 'website', component: Website },
        { path: '/thesis', name: 'thesis', component: Thesis },
        { path: '/notes', name: 'notes', component: Notes },
        { path: '*', component: NotFound }
    ],
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        else if (to.hash) {
        return { selector: to.hash };
        }
        else { return { x: 0, y: 0 }; }
    }
});