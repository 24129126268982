<template>
    <span style="margin-left: 1em; padding: .1em">
        <input type="checkbox" id="theme-switch" class="theme-switch" v-model="darkMode" @click="themechange" />
        <label for='theme-switch'>
            <span style="color: #ffffff; padding-left: .5em">
                <i class="fas fa-adjust" :title="`${$t('dark_mode')}`"></i>
            </span>
        </label>
        <a :title="`${$t('decrease_font')}`" style="color: #ffffff; padding-left: 1em" @click.prevent="decreasefont"><i class="fas fa-search-minus"></i></a>
        <a :title="`${$t('increase_font')}`" style="color: #ffffff; padding-left: 1em; padding-right: .5em" @click.prevent="increasefont"><i class="fas fa-search-plus"></i></a>
    </span>
</template>

<script>
    export default {
        name: 'Accessibility',

        data: () => ({
            darkMode: false,
        }),

        methods: {
            themechange: function() {
                if (this.darkMode === false) {
                    this.$emit('themechanged', true);
                }
                else {
                    this.$emit('themechanged', false);
                }
            },

            increasefont: function() {
                this.$emit('fontincreased');
            },

            decreasefont: function() {
                this.$emit('fontdecreased');
            }
        },

        mounted() {
            // set 'app-background' class to body tag
            let bodyElement = document.body;
            bodyElement.classList.add('app-background');

            // check for active theme
            let htmlElement = document.documentElement;
            let theme = localStorage.getItem('theme');

            if (theme === 'dark') {
                htmlElement.setAttribute('theme', 'dark');
                this.darkMode = true;
            }
            else {
                htmlElement.setAttribute('theme', 'light');
                this.darkMode = false;
            }
        },

        watch: {
            darkMode: function () {
                // add/remove class to/from html tag
                let htmlElement = document.documentElement;

                if (this.darkMode) {
                localStorage.setItem('theme', 'dark');
                htmlElement.setAttribute('theme', 'dark');
                }
                else {
                localStorage.setItem('theme', 'light');
                htmlElement.setAttribute('theme', 'light');
                }
            }
        },
    }
</script>
