<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" style="flex-shrink: 1; text-align: left; ">atCite [{{ $t(system) }}]</p>
                <button class="delete" aria-label="close" @click.prevent="$emit('close')"></button>
            </header>
            <section class="modal-card-body result">
                <!-- <div v-if="errorMessage && errorMessage !== ''">
                    <p v-html="errorMessage"></p>
                </div> -->
                <div v-if="system === 'apa5'">
                    <div v-if="footref">
                        <p class="modal-results-header">{{ $t('intext_reference_first') }}</p>
                        <div ref="footref" id="footref" class="footref">
                            <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; line-height: 150%" v-html="footref"></p>
                        </div>
                        <br />
                    </div>
                    <div v-if="inref">
                        <p class="modal-results-header">{{ (footref !== '') ? $t('intext_reference_consequent') : $t('intext_reference') }}</p>
                        <div ref="inref" id="inref" class="inref">
                            <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; line-height: 150%" v-html="inref"></p>
                        </div>
                        <br />
                    </div>
                    <div v-if="backref">
                        <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                        <div ref="backref" id="backref" class="backref">
                            <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em; line-height: 150%" v-html="backref"></p>
                        </div>
                    </div>
                </div>
                <div v-else-if="system === 'apa7'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'chicago_notes'">
                    <p class="modal-results-header">{{ $t('fullfootnote') }}</p>
                    <div ref="footref" id="footref" class="footref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; text-indent: 2em; line-height: 150%" v-html="footref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('shortfootnote') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-all" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'chicago_date'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: auto; word-break: break-all" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: auto; word-break: break-word; padding-left: 2em; text-indent: -2em; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'mla'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'ieee'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'cse_date'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else-if="system === 'cse_cit'">
                    <p class="modal-results-header">{{ $t('intext_reference') }}</p>
                    <div ref="inref" id="inref" class="inref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word" v-html="inref"></p>
                    </div>
                    <br />
                    <p class="modal-results-header">{{ $t('citation_reference') }}</p>
                    <div ref="backref" id="backref" class="backref">
                        <p style="font-family: Calibri; text-align: justify; text-justify: inter-word; word-break: break-word; line-height: 150%" v-html="backref"></p>
                    </div>
                </div>
                <div v-else>
                </div>
                <hr style="margin: .5rem 0" />
                <div class="citation-source">
                    {{ $t('source_label') }}:
                    <p v-if="system === 'apa5'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em" />
                    <p v-else-if="system === 'apa7'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em" />
                    <p v-else-if="system === 'chicago_notes'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em" />
                    <p v-else-if="system === 'chicago_date'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-all; padding-left: 2em; text-indent: -2em" />
                    <p v-else-if="system === 'mla'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word; padding-left: 2em; text-indent: -2em" />
                    <p v-else-if="system === 'ieee'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word" />
                    <p v-else-if="system === 'cse_date'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word" />
                    <p v-else-if="system === 'cse_cit'" v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word" />
                    <p v-else v-html="source" style="text-align: justify; text-justify: inter-word; word-break: break-word" />
                </div>
            </section>
            <footer class="modal-card-foot">
                <!-- <div v-if="!errorMessage || errorMessage === ''"> -->
                    <div class="buttons is-centered">
                        <button style="" v-if="system === 'apa5' && footref !==''" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyFootref">{{ $t('copy_footref_apa5') }}</button>
                        <button style="" v-if="system === 'chicago_notes'" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyFootref">{{ $t('copy_footref') }}</button>
                        <button style="" v-if="system !== 'ieee' && system !== 'apa5' && system !== 'chicago_notes'" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyInref">{{ $t('copy_intext') }}</button>
                        <button style="" v-if="system === 'chicago_notes'" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyInref">{{ $t('copy_intext_chicago') }}</button>
                        <button style="" v-if="system === 'apa5'" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyInref">{{ (footref !== '') ? $t('copy_intext_apa5') : $t('copy_intext') }}</button>
                        <button style="" type="button" class="button" :title="$t('copy_tooltip')" @click.prevent="copyBackref">{{ $t('copy_citation') }}</button>
                    </div>
                    <!-- <button class="button">Cancel</button> -->
                <!-- </div> -->
            </footer>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import i18n from '@/i18n.js';
    import { isMobile } from 'mobile-device-detect';

    export default {
        name: 'Modal',

        props: {
            system: String,
            inref: String,
            backref: String,
            footref: String,
            source: String,
            errorMessage: String,
        },

        data: () => ({
            mobile: isMobile
        }),

        methods: {
            copyInref() {
                copyToClip(document.getElementById('inref').innerHTML, this.mobile);
            },

            copyBackref() {
                copyToClip(document.getElementById('backref').innerHTML, this.mobile);
            },

            copyFootref() {
                copyToClip(document.getElementById('footref').innerHTML, this.mobile);
            },
        },

        created() {
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.$emit('close');
                }
            });
        },
    }

    // Καλύτερη λύση: https://www.npmjs.com/package/clipboard-polyfill
    function copyToClip(str, mob) {
        return new Promise(function(resolve, reject) {
            var success = false;
            function listener(e) {
                if (mob) {
                    e.clipboardData.setData("text/plain", str.replace(/<[^>]*>?/gm, '').replace('&amp;', '&'));
                }
                else {
                    e.clipboardData.setData("text/html", str);
                }
                e.preventDefault();
                success = true;
                Vue.notify({
                    type: 'info',
                    text: i18n.t('copiedtoclipboard', this.lang)
                });
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            success ? resolve(): reject();
        });
    }

</script>

<style scoped>
    .citation-source {
        font-family: 'Roboto Condensed';
        font-size: .8em;
        padding: 5px;
    }
    .modal-card-foot {
        /* padding-left: 0;
        padding-right: 0; */
        justify-content: center
    }
</style>