<template>
    <FormulateInput
        :name="`${!!type ? `${type}` : ``}state`"
        :options="!!dc ? states_dc[this.l] : states[this.l]"
        type="select"
        :label="`${$t('state')}`"
        :tooltip="!!tip ? $t(tip) : $t('state_tooltip')"
        
    />
</template>

<script>
    export default {
        name: 'States',

        props: {
            lang: String,
            type: String,
            tip: String,
            dc: Boolean
        },

        data: () => ({
            l: 0,
            states: [
                { 'empty': '- Χωρίς επιλογή -', 'ID': 'Άινταχο (ID)', 'IA': 'Αϊόβα (IA)', 'AL': 'Αλαμπάμα (AL)', 'AK': 'Αλάσκα (AK)', 'AZ': 'Αριζόνα (AZ)', 'AR': 'Αρκάνσας (AR)', 'VT': 'Βερμόντ (VT)', 'VA': 'Βιρτζίνια (VA)', 'NC': 'Βόρεια Καρολίνα (NC)', 'ND': 'Βόρεια Ντακότα (ND)', 'UT': 'Γιούτα (UT)', 'WV': 'Δυτική Βιρτζίνια (WV)', 'IL': 'Ιλινόις (IL)', 'IN': 'Ιντιάνα (IN)', 'CA': 'Καλιφόρνια (CA)', 'KS': 'Κάνσας (KS)', 'KY': 'Κεντάκι (KY)', 'CO': 'Κολοράντο (CO)', 'CT': 'Κονέκτικατ (CT)', 'LA': 'Λουισιάνα (LA)', 'ME': 'Μέιν (ME)', 'MD': 'Μέριλαντ (MD)', 'MA': 'Μασαχουσέτη (MA)', 'MI': 'Μίσιγκαν (MI)', 'MN': 'Μινεσότα (MN)', 'MS': 'Μισισίπι (MS)', 'MO': 'Μιζούρι (MO)', 'MT': 'Μοντάνα (MT)', 'NY': 'Νέα Υόρκη (NY)', 'NV': 'Νεβάδα (NV)', 'NE': 'Νεμπράσκα (NE)', 'NM': 'Νέο Μεξικό (NM)', 'NH': 'Νιου Χάμσαϊρ (NH)', 'NJ': 'Νιου Τζέρσεϊ (NJ)', 'SC': 'Νότια Καρολίνα (SC)', 'SD': 'Νότια Ντακότα (SD)', 'DE': 'Ντελαγουέρ (DE)', 'OK': 'Οκλαχόμα (OK)', 'OR': 'Όρεγκον (OR)', 'WY': 'Ουαϊόμινγκ (WY)', 'WA': 'Ουάσινγκτον (WA)', 'WI': 'Ουισκόνσιν (WI)', 'OH': 'Οχάιο (OH)', 'PA': 'Πενσυλβάνια (PA)', 'RI': 'Ρόουντ Άιλαντ (RI)', 'TN': 'Τενεσί (TN)', 'TX': 'Τέξας (TX)', 'GA': 'Τζόρτζια (GA)', 'FL': 'Φλόριντα (FL)', 'HI': 'Χαβάη (HI)' },
                { 'empty': '- Empty selection -', 'AL': 'Alabama (AL)', 'AK': 'Alaska (AK)', 'AZ': 'Arizona (AZ)', 'AR': 'Arkansas (AR)', 'CA': 'California (CA)', 'CO': 'Colorado (CO)', 'CT': 'Connecticut (CT)', 'DE': 'Delaware (DE)', 'FL': 'Florida (FL)', 'GA': 'Georgia (GA)', 'HI': 'Hawaii (HI)', 'ID': 'Idaho (ID)', 'IL': 'Illinois (IL)', 'IN': 'Indiana (IN)', 'IA': 'Iowa (IA)', 'KS': 'Kansas (KS)', 'KY': 'Kentucky (KY)', 'LA': 'Louisiana (LA)', 'ME': 'Maine (ME)', 'MD': 'Maryland (MD)', 'MA': 'Massachusetts (MA)', 'MI': 'Michigan (MI)', 'MN': 'Minnesota (MN)', 'MS': 'Mississippi (MS)', 'MO': 'Missouri (MO)', 'MT': 'Montana (MT)', 'NE': 'Nebraska (NE)', 'NV': 'Nevada (NV)', 'NH': 'New Hampshire (NH)', 'NJ': 'New Jersey (NJ)', 'NM': 'New Mexico (NM)', 'NY': 'New York (NY)', 'NC': 'North Carolina (NC)', 'ND': 'North Dakota (ND)', 'OH': 'Ohio (OH)', 'OK': 'Oklahoma (OK)', 'OR': 'Oregon (OR)', 'PA': 'Pennsylvania (PA)', 'RI': 'Rhode Island (RI)', 'SC': 'South Carolina (SC)', 'SD': 'South Dakota (SD)', 'TN': 'Tennessee (TN)', 'TX': 'Texas (TX)', 'UT': 'Utah (UT)', 'VT': 'Vermont (VT)', 'VA': 'Virginia (VA)', 'WA': 'Washington (WA)', 'WV': 'West Virginia (WV)', 'WI': 'Wisconsin (WI)', 'WY': 'Wyoming (WY)' }
            ],
            states_dc: [
                { 'empty': '- Χωρίς επιλογή -', 'ID': 'Άινταχο (ID)', 'IA': 'Αϊόβα (IA)', 'AL': 'Αλαμπάμα (AL)', 'AK': 'Αλάσκα (AK)', 'AZ': 'Αριζόνα (AZ)', 'AR': 'Αρκάνσας (AR)', 'VT': 'Βερμόντ (VT)', 'VA': 'Βιρτζίνια (VA)', 'NC': 'Βόρεια Καρολίνα (NC)', 'ND': 'Βόρεια Ντακότα (ND)', 'UT': 'Γιούτα (UT)', 'WV': 'Δυτική Βιρτζίνια (WV)', 'IL': 'Ιλινόις (IL)', 'IN': 'Ιντιάνα (IN)', 'CA': 'Καλιφόρνια (CA)', 'KS': 'Κάνσας (KS)', 'KY': 'Κεντάκι (KY)', 'CO': 'Κολοράντο (CO)', 'CT': 'Κονέκτικατ (CT)', 'LA': 'Λουισιάνα (LA)', 'ME': 'Μέιν (ME)', 'MD': 'Μέριλαντ (MD)', 'MA': 'Μασαχουσέτη (MA)', 'MI': 'Μίσιγκαν (MI)', 'MN': 'Μινεσότα (MN)', 'MS': 'Μισισίπι (MS)', 'MO': 'Μιζούρι (MO)', 'MT': 'Μοντάνα (MT)', 'NY': 'Νέα Υόρκη (NY)', 'NV': 'Νεβάδα (NV)', 'NE': 'Νεμπράσκα (NE)', 'NM': 'Νέο Μεξικό (NM)', 'NH': 'Νιου Χάμσαϊρ (NH)', 'NJ': 'Νιου Τζέρσεϊ (NJ)', 'SC': 'Νότια Καρολίνα (SC)', 'SD': 'Νότια Ντακότα (SD)', 'DE': 'Ντελαγουέρ (DE)', 'OK': 'Οκλαχόμα (OK)', 'OR': 'Όρεγκον (OR)', 'WY': 'Ουαϊόμινγκ (WY)', 'DC': 'Ουάσινγκτον (DC)', 'WI': 'Ουισκόνσιν (WI)', 'OH': 'Οχάιο (OH)', 'PA': 'Πενσυλβάνια (PA)', 'RI': 'Ρόουντ Άιλαντ (RI)', 'TN': 'Τενεσί (TN)', 'TX': 'Τέξας (TX)', 'GA': 'Τζόρτζια (GA)', 'FL': 'Φλόριντα (FL)', 'HI': 'Χαβάη (HI)' },
                { 'empty': '- Empty selection -', 'AL': 'Alabama (AL)', 'AK': 'Alaska (AK)', 'AZ': 'Arizona (AZ)', 'AR': 'Arkansas (AR)', 'CA': 'California (CA)', 'CO': 'Colorado (CO)', 'CT': 'Connecticut (CT)', 'DE': 'Delaware (DE)', 'FL': 'Florida (FL)', 'GA': 'Georgia (GA)', 'HI': 'Hawaii (HI)', 'ID': 'Idaho (ID)', 'IL': 'Illinois (IL)', 'IN': 'Indiana (IN)', 'IA': 'Iowa (IA)', 'KS': 'Kansas (KS)', 'KY': 'Kentucky (KY)', 'LA': 'Louisiana (LA)', 'ME': 'Maine (ME)', 'MD': 'Maryland (MD)', 'MA': 'Massachusetts (MA)', 'MI': 'Michigan (MI)', 'MN': 'Minnesota (MN)', 'MS': 'Mississippi (MS)', 'MO': 'Missouri (MO)', 'MT': 'Montana (MT)', 'NE': 'Nebraska (NE)', 'NV': 'Nevada (NV)', 'NH': 'New Hampshire (NH)', 'NJ': 'New Jersey (NJ)', 'NM': 'New Mexico (NM)', 'NY': 'New York (NY)', 'NC': 'North Carolina (NC)', 'ND': 'North Dakota (ND)', 'OH': 'Ohio (OH)', 'OK': 'Oklahoma (OK)', 'OR': 'Oregon (OR)', 'PA': 'Pennsylvania (PA)', 'RI': 'Rhode Island (RI)', 'SC': 'South Carolina (SC)', 'SD': 'South Dakota (SD)', 'TN': 'Tennessee (TN)', 'TX': 'Texas (TX)', 'UT': 'Utah (UT)', 'VT': 'Vermont (VT)', 'VA': 'Virginia (VA)', 'DC': 'Washington (DC)', 'WV': 'West Virginia (WV)', 'WI': 'Wisconsin (WI)', 'WY': 'Wyoming (WY)' }
            ]
        }),

        mounted() {
            this.$nextTick(function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            })
        },

        watch: {
            lang: function() {
                this.l = (this.lang === 'el') ? 0 : 1;
            },
        },
    }
</script>