<template>
    <div class="columns is-desktop">
        <div class="column" :class="!hidedate ? 'is-9' : ''">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <FormulateInput v-if="req" :style="{ fontSize: `${fontSize}em` }" v-model="uri" :id="`${mediatype}_uri`" error-behavior="submit" :tooltip="$t(`${mediatype}_uri_tooltip_mand`)" name="uri" :label="`${$t('uri')} (*)`" validation="bail|required|url"  :validation-messages="{ required: $t('validation_required_text'), url: $t('validation_invalid_url') }" @input="maliciousUrl = ''" :error="maliciousUrl" />
                    <FormulateInput v-else :style="{ fontSize: `${fontSize}em` }" v-model="uri" :id="`${mediatype}_uri`" error-behavior="submit"  :tooltip="$t(`${mediatype}_uri_tooltip`)"  name="uri"  :label="`${$t('uri')}`" validation="optional|url"  :validation-messages="{ url: $t('validation_invalid_url') }" @input="maliciousUrl = ''" :error="maliciousUrl" />
                </div>
                <div class="control">
                    <button class="input" @click.prevent="shorten_url" :style="{ fontSize: `${fontSize}em` }" :title="$t(`shortenuri`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em" :disabled="!!working">
                        <i class="fas fa-link"></i>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!hidedate" class="column is-3">
            <calendar name="dateaccessed" :req="req || (condreq && uri !== '')" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import Calendar from './Calendar.vue';
    import { bus } from '@/main';

    // import { isChrome, isOpera } from 'mobile-device-detect';

    export default {
        name: 'Url',

        components: {
            Calendar
        },
  
        props: {
            req: {
                type: Boolean
            },
            hidedate: {
                type: Boolean
            },
            mediatype: {
                type: String,
                default: 'book'
            },
            condreq: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            uri: '',
            working: false,
            fontSize: '1',
            // dateaccessed: '',
            // showClear: isChrome || isOpera,
            maliciousUrl: '',
        }),

        methods: {
            // clearDateAccessed: function() {
            //     this.dateaccessed = '';
            // },

            shorten_url: function() {
                const regex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;

                if (this.uri && this.uri !== '' && regex.test(this.uri)) {
                    this.working = true;

                    this.$gtag.event(`short-cite-inline`, { 'event_category': `short-cite`, 'event_label': ``, 'value': 1 });

                    axios.post(`${process.env.VUE_APP_API_BASE_URL}shortcite`, { longUrl: this.uri })
                    .then((response) => {
                        // console.log(response.data.payload);
                        if (response.data.success) {
                            this.$emit('shortenurl', response.data.payload.shortUrl);
                        }
                        else {
                            this.maliciousUrl = i18n.t(response.data.payload.shortUrl, this.lang);
                        }
                    })
                    .catch(() => {
                        alert(i18n.t('urlshorten_general_error', this.lang));
                    })
                    .then(() => {
                        this.working = false;
                    });
                }
            }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data).toString();
          });
        },
    }
</script>
