<template>
    <div>
        <div class="source-page-title">{{ $t('section') }}</div>
        <hr />
        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="bookauthors" type="section" :showcorp=false />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="original_year" :tooltip="$t('section_original_year_tooltip')" :label="`${$t('original_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('section_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" dc /></div>
                    <!-- <countries :lang="citation.lang" /> -->
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <!-- <FormulateInput style="width: 25%" error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('section_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <!--
                <FormulateInput error-behavior="submit" name="seriestitle" :label="`${$t('seriestitle')}`" :tooltip="$t('book_seriestitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <contributors name="serieseditors" type="book" :showcorp=false />
                -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_spages_tooltip')" /></div>
                </div>
                <Url :lang="citation.lang" mediatype="section" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="bookauthors" type="section" :showcorp=false artist-tooltip="section_hasbookauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                </div>
                <!-- <div class="double">
                    <FormulateInput style="width: 25%" error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" />
                    <FormulateInput style="width: 75%" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('section_volumetitle_tooltip')" />
                </div> -->
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_spages_tooltip')" /></div>
                </div>
                <!-- <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('section_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /> -->
                <doi-uri :lang="citation.lang" mediatype="section" @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="bookauthors" type="section" :showcorp=false artist-tooltip="section_hasbookauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('section_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                </div>
                <div class="columns is-desktop" v-if="!!citation.journalvolume && citation.journalvolume !== '' && citation.journalvolume !== '-'">
                    <div class="column"><FormulateInput key="cn_volumetitle" error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('chicago_section_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('section_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="section" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="bookauthors" type="section" :showcorp=false artist-tooltip="section_hasbookauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('section_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('section_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="section" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="bookauthors" type="section" :showcorp=false artist-tooltip="section_hasbookauthors_tooltip" />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" /></div>
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('volume_tooltip')" type="number" name="journalvolume" :label="`${$t('journalvolume')}`" min="1" max="1000" validation="optional|^number|between:0,1001,value" :validation-messages="{ number: $t('validation_invalid_volume'), between: $t('validation_invalid_volume') }" /> -->
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_spages_tooltip')" /></div>
                </div>
                <FormulateInput error-behavior="submit" name="series" :tooltip="$t('section_seriestitle_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" name="websitename" :tooltip="$t('section_websitename_tooltip')" :label="`${$t('websitename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Url @shortenurl="shortenurl($event)" mediatype="section" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contribs name="authors" type="section" single-name="author" show-corp corp-tooltip="hascorpauthor_tooltip" creator-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthor" error-behavior="submit" name="volumetitle" :label="`${$t('title')}`" :tooltip="$t('section_title_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="volumetitle" :label="`${$t('title')} (*)`" :tooltip="$t('section_title_tooltip_mand')" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contribs name="editors" type="section" single-name="editor" creator-tooltip="section_hasbookauthors_tooltip" />
                <contribs name="translators" type="section" single-name="translator" creator-tooltip="section_hastranslators_tooltip" />
                <FormulateInput error-behavior="submit" name="series" :tooltip="$t('section_seriestitle_tooltip')" :label="`${$t('series')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('book_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" dc /></div>
                    <div class="column"><countries :lang="citation.lang" /></div>
                    <!-- <FormulateInput error-behavior="submit" name="country" :label="`${$t('country')}`" :tooltip="$t('country_tooltip')" /> -->
                    <!-- <FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('ieee_chapter_title')}`" :tooltip="$t('ieee_chapter_title_tooltip')" />
                    <FormulateInput error-behavior="submit" type="number" name="section" :tooltip="$t('book_section_tooltip')" :label="`${$t('book_section')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /> -->
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('mla_journalvolume')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_volume') }" :tooltip="$t('mla_volume_tooltip')" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="chapter" :tooltip="$t('book_chapter_tooltip')" :label="`${$t('book_chapter')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="section" />
                <FormulateInput error-behavior="submit" name="doi" :tooltip="$t('section_doi_tooltip')" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="section" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="section" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="section" creator-tooltip="cse_hascorpauthor_tooltip" artist-tooltip="section_hasauthors_tooltip" />
                <FormulateInput v-if="!citation.hascorpauthor && !citation.hasauthors" error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                <FormulateInput v-else error-behavior="submit" name="title" :tooltip="$t('section_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="editors" type="section" artist-label="bookauthors" artist-tooltip="section_hasbookauthors_tooltip" :showcorp=false />
                <FormulateInput error-behavior="submit" name="booktitle" :tooltip="$t('section_booktitle_tooltip')" :label="`${$t('booktitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <contributors name="translators" type="section" :showcorp=false artist-tooltip="section_hastranslators_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="year" :tooltip="$t('section_year_tooltip')" :label="`${$t('year')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="edition" :tooltip="$t('section_edition_tooltip')" :label="`${$t('edition')}`" min="1" max="100" validation="optional|number|between:0,100,value" :validation-messages="{ number: $t('validation_invalid_edition'), between: $t('validation_invalid_edition') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('section_city_tooltip')" :label="`${$t('city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><states :lang="citation.lang" tip="cse_state_tooltip" dc /></div>
                    <div class="column"><countries :lang="citation.lang" tip="cse_country_tooltip" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="chapter" :label="`${$t('book_chapter')}`" min="0" max="1000" validation="optional|number|between:0,1000,value" :validation-messages="{ number: $t('validation_invalid_chapter') }" :tooltip="$t('section_chapter_tooltip')" /></div>
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="chaptertitle" :label="`${$t('ieee_chapter_title')}`" :tooltip="$t('section_chaptertitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-3"><FormulateInput error-behavior="submit" name="journalvolume" :label="`${$t('journalvolume')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('general_volume_tooltip')" /></div>
                    <div class="column is-9"><FormulateInput error-behavior="submit" name="volumetitle" :label="`${$t('volumetitle')}`" :tooltip="$t('section_volumetitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" name="seriestitle" :label="`${$t('seriestitle')}`" :tooltip="$t('section_seriestitle_tooltip')" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-4"><FormulateInput error-behavior="submit" name="seriesvolume" type="number" :label="`${$t('seriesvolume')}`" min="0" max="1000" validation="optional|number|between:0,1000,value" :validation-messages="{ number: $t('validation_invalid_chapter') }" :tooltip="$t('seriesvolume_tooltip')" /></div>
                </div>
                <contributors name="serieseditors" type="section" :showcorp=false artist-tooltip="section_hasserieseditors_tooltip" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('section_publisher_tooltip_mand')" :label="`${$t('publisher')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('section_pages_tooltip')" /></div>
                </div>
                <Url :lang="citation.lang" mediatype="section" condreq @shortenurl="shortenurl($event)" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="section" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Controls from '@/components/forms/Controls';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import DoiUri from '@/components/forms/DoiUrl';

    export default {
        name: 'BookSection',

        components: {
            SysLang,
            Contributors,
            Contribs,
            States,
            Countries,
            Controls,
            Url,
            Modal,
            DoiUri,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'section';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.$formulate.reset('section');
                this.citation = {};
                this.citation.system = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.state = 'empty',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.hasbookauthors = false,
                    this.citation.hastranslators = false,
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                        this.citation.hascorpauthor = false,
                        this.citation.hasauthors = false,
                        this.citation.hasbookauthors = false,
                        this.citation.hastranslators = false,
                        this.citation.uri = '',
                        this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                        this.citation.hascorpauthor = false,
                        this.citation.hasauthors = false,
                        this.citation.haseditors = false,
                        this.citation.hastranslators = false,
                        this.citation.state = 'empty',
                        this.citation.country = 'empty',
                        this.citation.uri = '',
                        this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                        this.citation.hascorpauthor = false,
                        this.citation.hasauthors = false,
                        this.citation.haseditors = false,
                        this.citation.hastranslators = false,
                        this.citation.yeartype = 'publicationyear',
                        this.citation.state = 'empty',
                        this.citation.country = 'empty',
                        this.citation.hasserieseditors = false,
                        this.citation.uri = '',
                        this.citation.dateaccessed = ''
                }
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            }
        },
    }
</script>
