<template>
    <div class="group-margin">
        <div class="columns is-desktop">
            <div class="column is-narrow" v-if="!!optionsLabel"><label class="my-label" style="text-align: justify">{{ `${!!optionsLabel && optionsLabel !== '' ? $t(optionsLabel) : $t('contributors')}: ` }}</label></div>
            <div class="column is-narrow" v-if="!!showcorp"><FormulateInput v-model="hascorpauthor" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t('hascorpauthor_tooltip')" error-behavior="submit" type="checkbox" :label="`${!!creatorLabel ? $t(creatorLabel) : $t('corporate_author')}${hascorpauthor ? ` (*)` : ``}`" name="hascorpauthor" style="margin-bottom: 0" @change="isenabled = false" /></div>
            <div class="column is-narrow"><FormulateInput v-model="isenabled" error-behavior="submit" type="checkbox" :tooltip="!!artistTooltip ? $t(artistTooltip) : $t(`${type}_has${name}_tooltip`)" :label="`${!!artistLabel ? $t(artistLabel) : $t(name)}${isenabled ? ` (*)` : ``}`" :name="`has${name}`" style="margin-bottom: 0" @change="hascorpauthor = false" /></div>
        </div>
        <FormulateInput error-behavior="submit" key="corpauthor" v-if="hascorpauthor" name="corpauthor" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :placeholder="`${$t('corpauthorname')}`" />
        <FormulateInput :value="values" error-behavior="submit" v-if="isenabled" type="group" :name=name :repeatable="true" :add-label="$t('add')" minimum="1">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Contributors',

        props: {
            name: {
                type: String,
                required: true,
                default: 'authors'
            },
            type: {
                type: String,
                required: false
            },
            creatorLabel: String,
            artistLabel: String,
            creatorTooltip: String,
            artistTooltip: String,
            optionsLabel: String,
            showcorp: {
                type: Boolean,
                default: true
            },
            values: {
                type: Array,
                required: false
            },
        },

        data: () => ({
            isenabled: false,
            hascorpauthor: false,
        }),

        methods: {
        },
    }
</script>