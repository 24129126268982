import '@/assets/css/styles.scss';
import '@/assets/styles/bulma.css';
import '@/assets/styles/custom.css';
import CustomLabel from '@/components/CustomLabel';
import VueFormulate from '@braid/vue-formulate';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import axios from 'axios';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueGtag from 'vue-gtag';
import Notifications from 'vue-notification';
import App from './App.vue';
import i18n from './i18n';
import router from './router';

Vue.config.productionTip = false;

export const bus = new Vue();

Vue.use(VueGtag, {
  enabled: (process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === 'true') ? true : false,
  bootstrap: true,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    params: {
      send_page_view: false,
    },
  }
}, router);

Vue.use(VTooltip, {
});

Vue.use(VueFormulate, {
  slotComponents: {
    label: 'CustomLabel'
  },
  slotProps: {
    label: ['tooltip']
  },
  rules: {
    invalidText: ({ value }) => value !== '-' && value !== '‒' && value !== '–' && value !== '—' && value !== '―' && value !== '⁓' && value !== '.' && value !== ',' && value !== '!' && value !== ' ' && value !== '  ' && value !== '   ',
    emptySelection: ({value}) => value !== '' && value !== 'empty',
    invalidDuration: ({ value }) => /^(([0-9]?[0-9]):)?([0-5]?[0-9]):([0-5]?[0-9])$/g.test(value),
  },
});

Vue.use(VueAxios, axios);

Vue.use(Notifications);

Vue.component('CustomLabel', CustomLabel);

Vue.mixin({
  methods: {
      padTo2Digits(num) {
          return num.toString().padStart(2, '0');
      },

      formatDate(date = new Date()) {
          return [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join('-');
      },

      sanitizeJSON(input) {
        // console.log(input);
        return (!!input && input !== '') ? input.replace(/^(\\n)|(&#[A-Za-z0-9]{3};)$/g, '') : '';
      },
  }
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
