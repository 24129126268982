<template>
    <div>
        <div class="source-page-title">{{ $t('thesis') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('apa5_phd', citation.lang), 1: $t('apa5_msc', citation.lang), 2: $t('apa5_bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('pages_tooltip')" /></div>
                </div>
                <!-- <div class="double">
                    <FormulateInput error-behavior="submit" :tooltip="$t('thesis_department_tooltip')" name="department" :label="`${$t('department')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_text') }" />
                    <FormulateInput error-behavior="submit" :tooltip="$t('thesis_institute_tooltip')" name="institute" :label="`${$t('institute')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_text') }" />
                </div> -->
                <div>
                    <institutes name="institutes" :instonly=true insts-tip="thesis_institute_tooltip_mand" />
                </div>
                <publisher-location :lang="citation.lang" :dc="true" city-tip="thesis_city_tooltip" />
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" condreq />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column group-margin"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('apa7_phd', citation.lang), 1: $t('apa7_msc', citation.lang), 2: $t('apa7_bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column group-margin"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column group-margin"><FormulateInput error-behavior="submit" :tooltip="$t('thesis_pubno_tooltip')" name="pubno" :label="`${$t('pubno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div>
                    <institutes name="institutes" insts-tip="thesis_institute_tooltip_mand" instonly />
                </div>
                <div class="single">
                    <FormulateInput error-behavior="submit" :tooltip="$t('thesis_library_tooltip')" name="library" :label="`${$t('library')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('phd', citation.lang), 1: $t('msc', citation.lang), 2: $t('bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesis_pubno_tooltip')" name="pubno" :label="`${$t('pubno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div>
                    <institutes name="institutes" insts-tip="thesis_institute_tooltip_mand" instonly />
                </div>
                <div class="single">
                    <FormulateInput error-behavior="submit" :tooltip="$t('thesis_library_tooltip')" name="library" :label="`${$t('library')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" hidedate />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('phd', citation.lang), 1: $t('msc', citation.lang), 2: $t('bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('pages_tooltip')" /></div>
                </div>
                <div>
                    <institutes name="institutes" instonly insts-tip="thesis_institute_tooltip_mand" />
                </div>
                <div class="columns is-desktop">
                    <div class="column is-8"><FormulateInput error-behavior="submit" :tooltip="$t('thesis_library_tooltip')" name="library" :label="`${$t('library')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column is-4"><FormulateInput error-behavior="submit" :tooltip="$t('thesis_pubno_tooltip')" name="pubno" :label="`${$t('pubno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('phd', citation.lang), 1: $t('msc', citation.lang), 2: $t('bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="finishyear" :tooltip="$t('thesis_finishyear_tooltip')" :label="`${$t('finishyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('thesis_publisher_tooltip')" :label="`${$t('publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <div>
                    <institutes name="institutes" instonly insts-tip="thesis_institute_tooltip_mand" />
                </div>
                <div class="single">
                    <FormulateInput error-behavior="submit" :tooltip="$t('thesis_library_tooltip')" name="library" :label="`${$t('library')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <contribs name="authors" type="thesis" single-name="author" />
                <div class="columns is-desktop">
                    <div class="column group-margin is-6"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column group-margin is-3"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('ieee_phd', citation.lang), 1: $t('ieee_msc', citation.lang), 2: $t('ieee_bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column group-margin is-3"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                </div>
                <div id="ieee-institutes">
                    <FormulateInput type="group" name="institutes" :repeatable="true" :label="`${$t('deptartments_institutes')} (*)`" :tooltip="`${$t('ieee_deptartments_institutes_tooltip')}`" :add-label="$t('add')" validation="ieeeInstitutesMinLength" :validation-rules="{ ieeeInstitutesMinLength: ieeeInstitutesMinLengthRule }" :validation-messages="{ ieeeInstitutesMinLength: $t('validation_institute_min_length') }">
                        <div class="columns is-desktop">
                            <div class="column"><FormulateInput error-behavior="submit" name="department" :label="`${$t('department')} (*)`" :tooltip="$t('ieee_department_tooltip_mand')" validation="invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><FormulateInput error-behavior="submit" name="institute" :label="`${$t('institute')} (*)`" :tooltip="$t('ieee_institute_tooltip_mand')" validation="invalidText" :validation-messages="{ required: $t('validation_required_text'),invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('ieee_city_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ required: $t('validation_required_text'),invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><states :lang="citation.lang" dc /></div>
                            <div class="column group-margin"><countries :lang="citation.lang" extreq /></div>
                        </div>
                    </FormulateInput>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="thesis" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="thesis" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <thesis-search :thesisLang="citation.lang" @openThesisSearchResults="openThesisSearchResults" />
                <FormulateInput error-behavior="submit" type="checkbox" :tooltip="$t('thesis_hasauthors_tooltip')" :label="`${$t('authors')}${citation.hasauthors ? ' (*)' : ''}`" name="hasauthors" style="padding-top: .45em" :style="citation.hasauthors === true ? 'margin-bottom: 0' : ''" />
                <FormulateInput error-behavior="submit" v-if="citation.hasauthors" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1">
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                </FormulateInput>
                <FormulateInput error-behavior="submit" name="title" :tooltip="$t('thesistitle_tooltip')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('thesistype_tooltip')" name="thesistype" :options="{ 0: $t('cse_phd', citation.lang), 1: $t('cse_msc', citation.lang), 2: $t('cse_bsc', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('thesis_type')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" :tooltip="$t('thesis_year_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput :tooltip="$t('yeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('yeartypepublication', citation.lang), copyrightyear: $t('yeartypecopyright', citation.lang), inferredyear: $t('yeartypeinferred', citation.lang) }" type="select" :label="`${$t('yeartype')}`" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="doi" :label="`${$t('doi')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('thesis_doi_tooltip')" /></div>
                </div>
                <div id="ieee-institutes">
                    <FormulateInput type="group" name="institutes" :repeatable="true" :label="`${$t('deptartments_institutes')} (*)`" :tooltip="`${$t('ieee_deptartments_institutes_tooltip')}`" :add-label="$t('add')" validation="cseInstitutesMinLength" :validation-rules="{ cseInstitutesMinLength: cseInstitutesMinLengthRule }" :validation-messages="{ cseInstitutesMinLength: $t('validation_institute_min_length') }">
                        <div class="columns is-desktop">
                            <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('ieee_city_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            <div class="column"><states :lang="citation.lang" /></div>
                            <div class="column"><countries :lang="citation.lang" /></div>
                            <div class="column group-margin"><FormulateInput error-behavior="submit" name="institute" :label="`${$t('institute')} (*)`" :tooltip="$t('ieee_institute_tooltip_mand')" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        </div>
                    </FormulateInput>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="thesis" condreq />
                <Controls :loading="loading" @formreset="formreset($event)" formname="thesis" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <thesis-search-results v-if="showSearchResults" @thesisselected="fillFormWithThesis" @close="showSearchResults = false;" :data="searchResults" />
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    // import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Controls from '@/components/forms/Controls';
    import Institutes from '@/components/forms/Institutes';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import PublisherLocation from '@/components/forms/PublisherLocation';
    import ThesisSearch from '@/components/forms/ThesisSearch';
    import ThesisSearchResults from '@/components/forms/ThesisSearchResults';

    export default {
        name: 'Thesis',

        components: {
            SysLang,
            // Contributors,
            Institutes,
            Contribs,
            Controls,
            States,
            Countries,
            Url,
            Modal,
            PublisherLocation,
            ThesisSearch,
            ThesisSearchResults,
        },

        data: () => ({
            citation: {},
            showResult: false,
            searchResults: {},
            showSearchResults: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'thesis';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('thesis');
                this.citation.system = data;
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.location = [
                        {
                            state: 'empty',
                            country: 'empty'
                        }
                    ],
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.uri = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.hasauthors = false,
                    this.citation.institutes = [
                        {
                            state: 'empty',
                            country: 'empty'
                        }
                    ],
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.yeartype = 'publicationyear',
                    this.citation.institutes = [
                        {
                            state: 'empty',
                            country: 'empty'
                        }
                    ],
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },

            ieeeInstitutesMinLengthRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.department && institute.institute && institute.city && institute.country;
                }
                return false;
            },
            
            cseInstitutesMinLengthRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.city && institute.institute;
                }
                return false;
            },

            openThesisSearchResults: function(data) {
                this.searchResults = data;
                this.showSearchResults = true;
            },

            fillFormWithThesis: function(data) {
                let d = data.payload.result;

                // console.log(d);

                if (this.citation.system === 'apa5') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], false);
                    }
                }
                else if (this.citation.system === 'apa7') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], false);
                    }
                }
                else if (this.citation.system === 'chicago_notes') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], false);
                    }
                }
                else if (this.citation.system === 'chicago_date') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], false);
                    }
                }
                else if (this.citation.system === 'mla') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], false);
                    }
                }
                else if (this.citation.system === 'ieee') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], true);
                    }
                }
                else if (this.citation.system === 'cse_date' || this.citation.system === 'cse_cit') {
                    if (d['authors'] && d['authors'].length > 0) {
                        this.citation.hasauthors = true;
                        this.citation.authors = d['authors'];
                    }
                    this.citation.title = (d['title']) ? d['title'] : '';
                    this.citation.uri = (d['url']) ? d['url'] : '';
                    this.citation.dateaccessed = (d['dateaccessed']) ? d['dateaccessed'] : '';
                    this.citation.year = (d['year']) ? d['year'] : '';
                    this.citation.thesistype = d['thesistype'];
                    if (d['institute'] && d['institute'].length > 0) {
                        this.citation.institutes = this.extractInsts(d['institute'], true);
                    }
                }
                this.showSearchResults = false;
            },

            extractInsts: function(data, keepDeptName) {
                let res = [];

                if (data.length > 0) {
                    data.forEach((v) => {
                        if (!keepDeptName) {
                            res.push({ institute: v.split(',')[0].trim() });
                        }
                        else {
                            let r = v.split(',');
                            if (r.length === 3) {
                                res.push({ institute: r[0].trim(), department: r[2].trim() });
                            }
                            else if (r.length === 2) {
                                res.push({ institute: r[0].trim(), department: r[1].trim() });
                            }
                            else {
                                res.push({ institute: r[0].trim() });
                            }
                        }
                    });
                }

                return res;
            },
        },
    }
</script>

<style>
    #ieee-institutes a[role="button"] {
        margin-top: 1.35em
    }

</style>