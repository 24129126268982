<template>
    <div style="margin-bottom: 0">
        <div class="columns is-desktop">
            <div class="column is-narrow b-pad" v-if="!!optionsLabel">
                <label class="my-label" style="text-align: justify">{{ `${$t(optionsLabel)}: ` }}</label>
            </div>
            <div class="column is-narrow" v-if="!hideCorp"><FormulateInput v-model="hascorpauthor" :tooltip="!!corpTooltip ? $t(corpTooltip) : $t('hascorpcreator_tooltip')" error-behavior="submit" type="checkbox" :label="$t('corporate_creator')" name="hascorpauthor" @change="creatorchange($event)" /></div>
            <div class="column is-narrow"><FormulateInput v-model="hasauthor" error-behavior="submit" type="checkbox" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t(`hascreators_tooltip`)" :label="$t('creators')" name="hasauthor" @change="creatorchange($event)" /></div>
            <div class="column is-narrow"><FormulateInput v-model="hasartist" error-behavior="submit" type="checkbox" :tooltip="!!artistTooltip ? $t(artistTooltip) : $t(`hasartists_tooltip`)" :label="$t('artists')" name="hasartist" @change="creatorchange($event)" /></div>
        </div>
        <FormulateInput key="hascorpauthor" v-model="corpauthor" error-behavior="submit" v-if="hascorpauthor" name="corpauthor" validation="required|invalidText" style="margin-bottom: 1.5em" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :style="hascorpauthor ? 'marginBottom: 1em' : 'marginBottom: 0'" :placeholder="`${$t('corpauthorname')} (*)`" />
        <FormulateInput v-model="authors" error-behavior="submit" v-if="hasauthor" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1"  style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="artists" error-behavior="submit" v-if="hasartist" type="group" name="authors" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em" >
            <div :style="hasartist ? 'marginBottom: 1.6em' : 'marginBottom: 0'">
                <FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('artistname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Creators',

        props: {
            corpTooltip: String,
            creatorTooltip: String,
            artistTooltip: String,
            optionsLabel: String,
            hideCorp: Boolean,
        },

        data: () => ({
            hascorpauthor: false,
            hasauthor: false,
            hasartist: false,
            corpauthor: '',
            authors: {},
            artists: {}
        }),

        methods: {
            creatorchange: function(e) {
                this.corpauthor = '';
                this.authors = {};
                this.artists = {};
                switch (e.target.name) {
                    case 'hascorpauthor':
                        this.hasauthor = false;
                        this.hasartist = false;
                        break;
                    case 'hasauthor':
                        this.hascorpauthor = false;
                        this.hasartist = false;
                        break;
                    case 'hasartist':
                        this.hascorpauthor = false;
                        this.hasauthor = false;
                        break;
                    default:
                        this.hascorpauthor = false;
                        this.hasauthor = false;
                        this.hasartist = false;
                        break;
                }
            }
        },

        watch: {
            value: function() {
            },
        }
    }
</script>

<style>
    div[data-type=radio] > div > div {
        display: flex
    }
</style>