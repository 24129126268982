<template>
    <div v-if="enabled" class="columns is-desktop searchbar">
        <div class="column is-12">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <FormulateInput error-behavior="submit" key="urlsearchtext" name="urlsearchtext" v-model="url" :tooltip="$t('urlsearchtext_tooltip')" :label="`${$t('urlsearchtext')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                </div>
                <div class="control">
                    <button class="input" @click.prevent="urlsearch" :style="{ fontSize: `${fontSize}em` }" :title="$t(`urlsearch`)" style="margin-top: 1.42em; padding-top: 1.2em; padding-bottom: 1.2em" :disabled="!!working">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import { bus } from '@/main';

    export default {
        name: 'UrlSearch',

        data: () => ({
            url: '',
            working: false,
            fontSize: '1',
            enabled: (process.env.VUE_APP_URL_SEARCH_ENABLED === 'true') ? true : false,
        }),

        methods: {
            urlsearch: function() {
                this.working = true;
                axios.post(`${process.env.VUE_APP_API_BASE_URL}fetch-url/`, {
                    url: this.url
                })
                .then((response) => {
                    // console.log(response.data);
                    this.$emit('fillFormWithUrl', response.data);
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        text: i18n.t('urlretrieveerror', this.lang)
                    });
                })
                .then(() => {
                    this.working = false;
                });
            }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data).toString();
          });
        },
    }
</script>
