<template>
    <div style="margin-bottom: 1.5em">
        <FormulateInput
            v-if="instonly === true"
            error-behavior="submit"
            type="group"
            :name=name
            :repeatable="true"
            :add-label="$t('add')"
            :label="`${$t('institutes')} (*)`"
            :tooltip="`${!!instsTip && instsTip !== '' ? $t(instsTip) : $t('insts_tooltip')}`"
            validation="minLengthInstOnly"
            :validation-rules="{ minLengthInstOnly: minLengthInstOnlyRule }"
            :validation-messages="{ minLengthInstOnly: $t('validation_institute_min_length') }"
        >
            <div class="single group-margin">
                <FormulateInput
                    error-behavior="submit"
                    name="institute"
                    :placeholder="`${$t('institute')} (*)`"
                    validation="invalidText"
                    :validation-messages="{ invalidText: $t('validation_invalid_text') }"
                />
            </div>
        </FormulateInput>
        <FormulateInput
            v-if="instonly === false"
            error-behavior="submit"
            type="group"
            :name=name
            :repeatable="true"
            :add-label="$t('add')"
            :label="`${$t('deptartments_institutes')} (*)`"
            :tooltip="`${!!instsDeptsTip && instsDeptsTip !== '' ? $t(instsDeptsTip) : $t('insts_depts_tooltip')}`"
            validation="minLength"
            :validation-rules="{ minLength: minLengthRule }"
            :validation-messages="{ minLength: $t('validation_institute_min_length') }"
        >
            <div class="columns is-desktop">
                <div class="column">
                    <FormulateInput
                        error-behavior="submit"
                        name="department"
                        :placeholder="`${$t('department')} (*)`"
                        validation="invalidText"
                        :validation-messages="{ invalidText: $t('validation_invalid_text') }"
                    />
                </div>
                <div class="column group-margin">
                    <FormulateInput
                        error-behavior="submit"
                        name="institute"
                        :placeholder="`${$t('institute')} (*)`"
                        validation="invalidText"
                        :validation-messages="{ invalidText: $t('validation_invalid_text') }"
                    />
                </div>
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'Institutes',

        props: {
            name: {
                type: String,
                required: true,
            },
            instonly: {
                type: Boolean
            },
            instsTip: {
                type: String
            },
            instsDeptsTip: {
                type: String
            }
        },

        methods: {
            minLengthInstOnlyRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.institute;
                }
                return false;
            },
            minLengthRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [institute] = value;
                    return institute.department && institute.institute;
                }
                return false;
            },
        },
    }
</script>