<template>
    <div>
        <div class="source-page-title">{{ $t('digitalmedia') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="apa7_digitalmediatype" name="digitalmediatype" type="select" validation="required" :tooltip="$t('apa_digitalmediatype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('apa_digitalmediatype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ film: $t('apa5_film'), broadcast: $t('apa5_broadcast'), tvseries: $t('apa5_tvseries'), episode: $t('apa5_episode'), album: $t('apa5_album'), song: $t('apa5_song'), online_video: $t('apa5_online_video') }" @change="apa7_mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'film'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" type="number" name="year" key="apa7_film_recyear" :tooltip="$t('film_releaseyear_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><countries :lang="citation.lang" tip="film_country_tooltip" /></div>
                                <div class="column"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('digitalmediaproducers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1.5em" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="film" condreq />
                </div>
                <div v-if="citation.digitalmediatype === 'broadcast'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="broadcast_creator_corp_tooltip" creator-tooltip="broadcast_creator_creator_tooltip" artist-tooltip="broadcast_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('broadcast_filmtitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><calendar error-behavior="submit" name="year" key="apa7_episode_year" :tooltip="$t('broadcast_year_tooltip')" :label="`${$t('apa7_digitalmediaepisode_year')}`" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('broadcast_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('broadcast_producers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="broadcast" condreq />
                </div>
                <div v-else-if="citation.digitalmediatype === 'tvseries'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="tvseries_creator_corp_tooltip" creator-tooltip="tvseries_creator_creator_tooltip" artist-tooltip="tvseries_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('tvseries_title_tooltip_mand')" :label="`${$t('apa7_tvseriestitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" name="year" key="apa7_tvseries_year" :tooltip="$t('tvseries_year_tooltip')" :label="`${$t('year')}`" validation="optional" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('tvseries_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('tvseries_producers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="tvseries" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'episode'">
                    <creators-2 options-label="apa5_extracreatorswd" name="creators" :lang="citation.lang" corp-tooltip="tvseries_creator_corp_tooltip" creator-tooltip="tvseries_creator_creator_tooltip" artist-tooltip="tvseries_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_episodetitle_tooltip_mand')" :label="`${$t('apa7_episodetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" name="year" key="apa7_tvseries_year" :tooltip="$t('episode_year_tooltip')" :label="`${$t('year')}`" validation="optional" /></div>
                    </div>
                    <creators-2 options-label="extracreatorsprod" name="extracreators" :lang="citation.lang" corp-tooltip="episode_producer_corp_tooltip" creator-tooltip="episode_producer_creator_tooltip" artist-tooltip="episode_producer_artist_tooltip" media-type="producers" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="albumtitle" :tooltip="$t('tvseries_title_tooltip')" :label="`${$t('apa7_tvseriestitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('tvseries_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('tvseries_producers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1.5em" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="episode" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'song'">
                    <creators-2 options-label="apa5_song_writer" name="creators" :lang="citation.lang" creator-label="apa5_song_creator_creator_label" artist-label="apa5_song_creator_artist_label" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-creator show-artist :system="citation.system" />
                    <creators-2 options-label="apa5_song_artist" name="artists" :lang="citation.lang" creator-label="apa5_song_artist_creator_label" artist-label="apa5_song_artist_artist_label" creator-tooltip="song_performer_creator_tooltip" artist-tooltip="song_performer_artist_tooltip" :media-type="citation.digitalmediatype" show-creator show-artist :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_audiotitle_tooltip_mand')" :label="`${$t('apa7_audiotitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-8"><FormulateInput error-behavior="submit" name="albumtitle" :tooltip="$t('apa7_audioalbumtitle_tooltip')" :label="`${$t('apa7_audioalbumtitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" key="apa5_song_description" name="description" :tooltip="$t('apa5_digitalmediadesc_tooltip')" :label="`${$t('digitalmediadesc')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="apa7_audio_recyear" :tooltip="$t('apa5_song_year_tooltip')" :label="`${$t('apa5_song_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="comp_year" key="apa7_audio_compyear" :tooltip="$t('apa5_song_recordingyear_tooltip')" :label="`${$t('apa5_song_recordingyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('album_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('album_producers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="audio" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'album'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" creator-label="extracreators" artist-label="extraartists" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_audioalbumtitle_tooltip_mand')" :label="`${$t('apa7_audioalbumtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="year" key="apa7_tvseries_year" :tooltip="$t('album_year_tooltip')" :label="`${$t('year')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="description" key="apa5_album_description" :tooltip="$t('apa5_digitalmediadesc_tooltip')" :label="`${$t('digitalmediadesc')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="publishers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('album_city_tooltip_mand')" :label="`${$t('city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="publisher" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('album_producers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'online_video'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" key="apa7_onlinevideo_title" name="title" :tooltip="$t('apa7_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><calendar error-behavior="submit" name="year" key="apa7_onlinevideo_year" :tooltip="$t('apa7_digitalmediaonlinevideo_year_tooltip')" :label="`${$t('apa7_digitalmediaonelinevideo_year')}`" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" req mediatype="onlinevideo" />
                </div>
                <div v-else>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="apa7_digitalmediatype" name="digitalmediatype" type="select" validation="required" :tooltip="$t('apa_digitalmediatype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('apa_digitalmediatype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ song: $t('apa7_song'), album: $t('apa7_album'), film: $t('apa7_film'), tvseries: $t('apa7_tvseries'), episode: $t('apa7_episode'), online_video: $t('apa7_online_video'), artwork: $t('apa7_artwork'), photo: $t('apa7_photo') }" @change="apa7_mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'song'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_audiotitle_tooltip_mand')" :label="`${$t('apa7_audiotitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <!-- <div class="column is-3"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediadesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <FormulateInput error-behavior="submit" name="albumtitle" :tooltip="$t('apa7_audioalbumtitle_tooltip')" :label="`${$t('apa7_audioalbumtitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="apa7_audio_recyear" :tooltip="$t('apa7_song_year_tooltip')" :label="`${$t('apa7_song_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="comp_year" key="apa7_audio_compyear" :tooltip="$t('apa7_song_originalyear_tooltip')" :label="`${$t('apa7_song_originalyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="labelno" key="apa7_audio_labelno" :tooltip="$t('apa7_audio_labelno_tooltip')" :label="`${$t('apa7_audio_labelno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('digitalmediaproducers')}`" :tooltip="$t('album_producers_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1em" />
                    </FormulateInput>
                    <Url @shortenurl="shortenurl($event)" mediatype="audio" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'album'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" name="albumtitle" :tooltip="$t('apa7_audioalbumtitle_tooltip_mand')" :label="`${$t('apa7_audioalbumtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaalbumdesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="apa7_audio_recyear" :tooltip="$t('apa7_album_year_tooltip')" :label="`${$t('apa7_album_year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="comp_year" key="apa7_audio_compyear" :tooltip="$t('apa7_album_originalyear_tooltip')" :label="`${$t('apa7_album_originalyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="labelno" key="apa7_audio_labelno" :tooltip="$t('apa7_audio_labelno_tooltip')" :label="`${$t('apa7_audio_labelno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('digitalmediaproducers')}`" :tooltip="$t('album_producers_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1em" />
                    </FormulateInput>
                    <Url @shortenurl="shortenurl($event)" mediatype="album" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'film'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="year" key="apa7_film_recyear" :tooltip="$t('film_releaseyear_tooltip')" :label="`${$t('year')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <!-- <div class="column is-9"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediafilmdesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('digitalmediaproducers')}`" :tooltip="$t('digitalmediaproducers_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1em" />
                    </FormulateInput>
                    <Url @shortenurl="shortenurl($event)" mediatype="film" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'tvseries'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="tvseries_creator_corp_tooltip" creator-tooltip="tvseries_creator_creator_tooltip" artist-tooltip="tvseries_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('tvseries_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" key="apa7_tvseries_year" :tooltip="$t('tvseries_year_tooltip')" :label="`${$t('year')}`" name="year" validation="optional" /></div>

                    </div>
                    <div class="columns is-desktop">
                        <!-- <div class="column is-9"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediatvseriesdesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('digitalmediaproducers')}`" :tooltip="$t('tvseries_producers_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1.5em" />
                    </FormulateInput>
                    <Url @shortenurl="shortenurl($event)" mediatype="tvseries" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'episode'">
                    <creators-2 options-label="extracreatorswd" name="creators" :lang="citation.lang" corp-tooltip="tvseries_creator_corp_tooltip" creator-tooltip="tvseries_creator_creator_tooltip" artist-tooltip="tvseries_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_episodetitle_tooltip_mand')" :label="`${$t('apa7_episodetitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="year" key="apa7_episode_year" :tooltip="$t('apa7_digitalmediaepisode_year_tooltip')" :label="`${$t('apa7_digitalmediaepisode_year')}`" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" min="0" name="season" key="apa7_episode_season" :tooltip="$t('digitalmediaseason_tooltip')" :label="`${$t('digitalmediaseason')}`" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" min="0" name="episode" key="apa7_episode_episode" :tooltip="$t('digitalmediaepisode_tooltip')" :label="`${$t('digitalmediaepisode')}`" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaepisodedesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><creators-2 options-label="extracreatorsprod" name="extracreators" :lang="citation.lang" corp-tooltip="episode_producer_corp_tooltip" creator-tooltip="episode_producer_creator_tooltip" artist-tooltip="episode_producer_artist_tooltip" media-type="producers" show-corp show-creator show-artist show-contribution :system="citation.system" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="albumtitle" :tooltip="$t('tvseries_title_tooltip')" :label="`${$t('apa7_tvseriestitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <FormulateInput error-behavior="submit" type="group" name="publishers" :repeatable="true" :add-label="$t('add')" minimum="1" :label="`${$t('digitalmediaproducers')}`" :tooltip="$t('tvseries_producers_tooltip')">
                        <FormulateInput error-behavior="submit" name="publisher" :placeholder="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" style="margin-bottom: 1.5em" />
                    </FormulateInput>
                    <Url @shortenurl="shortenurl($event)" mediatype="episode" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'online_video'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="year" key="apa7_onlinevideo_year" :tooltip="$t('apa7_digitalmediaonlinevideo_year_tooltip')" :label="`${$t('apa7_digitalmediaonelinevideo_year')}`" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaonlinevideodesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                        <div class="column"><FormulateInput error-behavior="submit" name="platform" :tooltip="$t('digitalmediaonlinevideoplatform_tooltip')" :label="`${$t('chicago_onlinevideoplatform')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" req mediatype="onlinevideo" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'artwork'">
                    <creators-2 options-label="creators" name="creators" :lang="citation.lang" corp-label="corporate_creator" creator-label="creators" artist-label="artists" corp-tooltip="exhibit_creator_corp_tooltip" creator-tooltip="exhibit_creator_creator_tooltip" artist-tooltip="exhibit_creator_artist_tooltip" show-corp show-creator show-artist :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_artworktitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="year" key="apa7_artwork_year" :tooltip="$t('apa7_artworkyear_tooltip')" :label="`${$t('year')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaartworkdesc_tooltip_mand')" :label="`${$t('digitalmediadesc')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('apa7_digitalmediapublisher_tooltip')" :label="`${$t('apa7_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" key="apa7_artwork_city" name="city" :tooltip="$t('exhibit_city_tooltip')" :label="`${$t('chicago_paintingcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states key="cn_film_states" :lang="citation.lang" tip="exhibit_state_tooltip" /></div>
                        <div class="column"><countries :lang="citation.lang" tip="exhibit_country_tooltip" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="painting" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'photo'">
                    <creators-2 options-label="creators" name="creators" :lang="citation.lang" corp-label="corporate_creator" corp-tooltip="hascorpcreator_tooltip" creator-label="creators" creator-tooltip="photo_creator_creator_tooltip" artist-label="artists" artist-tooltip="photo_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_phototitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="year" key="apa7_photo_year" :tooltip="$t('apa7_photoyear_tooltip')" :label="`${$t('year')}`" validation="optional" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaphotodesc_tooltip_mand')" :label="`${$t('description')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div> -->
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" :tooltip="$t('apa7_digitalmediaphoto_tooltip')" :label="`${$t('publisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="photo" />
                </div>
                <div v-else>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput :tooltip="$t('chicago_digitalmediatype_tooltip')" error-behavior="submit" key="cn_digitalmediatype" name="digitalmediatype" :options="{ painting: $t('chicago_painting'), sculpture: $t('chicago_sculpture'), photo: $t('chicago_photograph'), film: $t('film'), episode: $t('chicago_episode'), online_video: $t('chicago_online_video'), music: $t('chicago_music') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_digitalmediatype')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="cd_mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'painting'">
                    <creators key="cn_painting" options-label="extracreators" corp-tooltip="exhibit_creator_corp_tooltip" creator-tooltip="exhibit_creator_creator_tooltip" artist-tooltip="exhibit_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_painting_title" error-behavior="submit" name="title" :tooltip="$t('apa7_artworktitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" key="cn_painting_year" :tooltip="$t('painting_year_tooltip_mand')" :label="`${$t('speech_year')} (*)`" min="-2038" max="2038" validation="bail|required|number|between:-2039,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column is-3"><FormulateInput key="cn_painting_publisher" error-behavior="submit" name="publisher" :tooltip="$t('apa7_digitalmediapublisher_tooltip')" :label="`${$t('chicago_paintingpublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" key="cn_painting_city" name="city" :tooltip="$t('exhibit_city_tooltip')" :label="`${$t('chicago_paintingcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="height" key="cn_painting_height" :tooltip="$t('painting_height_tooltip')" :label="`${$t('painting_height')}`" min="0" max="9998" validation="optional|^number|between:0,9999,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="width" key="cn_painting_width" :tooltip="$t('painting_width_tooltip')" :label="`${$t('painting_width')}`" min="0" max="9998" validation="optional|^number|between:0,9999,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" key="cn_painting_meterial" name="material" :tooltip="$t('chicago_paintingmaterial_tooltip')" :label="`${$t('chicago_paintingmaterial')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-9"><Url @shortenurl="shortenurl($event)" hidedate mediatype="painting" /></div>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'sculpture'">
                    <creators key="cn_sculpture" options-label="extracreators" :hide-corp=true creator-tooltip="sculpture_creator_creator_tooltip" artist-tooltip="sculpture_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_sculpture_title" error-behavior="submit" name="title" :tooltip="$t('chicago_sculpturetitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cn_sculpture_year" :tooltip="$t('sculpture_year_tooltip_mand')" :label="`${$t('speech_year')} (*)`" min="-2038" max="2038" validation="bail|required|number|between:-2039,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput key="cn_sculpture_publisher" error-behavior="submit" name="publisher" :tooltip="$t('chicago_sculpturepublisher_tooltip')" :label="`${$t('chicago_sculpturepublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" key="cn_sculpture_city" name="city" :tooltip="$t('chicago_sculpturecity_tooltip')" :label="`${$t('chicago_sculpturecity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="height" key="cn_sculpture_height" :tooltip="$t('sculpture_height_tooltip')" :label="`${$t('sculpture_height')}`" min="0" max="9998" validation="optional|^number|between:0,9999,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="width" key="cn_sculpture_width" :tooltip="$t('sculpture_width_tooltip')" :label="`${$t('sculpture_width')}`" min="0" max="9998" validation="optional|^number|between:0,9999,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="depth" key="cn_sculpture_depth" :tooltip="$t('sculpture_depth_tooltip')" :label="`${$t('sculpture_depth')}`" min="0" max="9998" validation="optional|^number|between:0,9999,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                    </div>
                    
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" key="cn_sculpture_meterial" name="material" :tooltip="$t('sculpture_material_tooltip')" :label="`${$t('chicago_paintingmaterial')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-9"><Url @shortenurl="shortenurl($event)" hidedate mediatype="sculpture" /></div>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'photo'">
                    <creators key="cn_photo" options-label="extracreators" creator-tooltip="photo_creator_creator_tooltip" artist-tooltip="photo_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_photo_title" error-behavior="submit" name="title" :tooltip="$t('chicago_phototitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-2 head-follower">
                            <!-- <br /> -->
                            <FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cn_photo_year" :tooltip="$t('photo_year_tooltip_mand')" :label="`${$t('speech_year')} (*)`" min="-2038" max="2038" validation="bail|required|number|between:-2039,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" />
                        </div>
                        <div class="column is-3 head-follower">
                            <!-- <br /> -->
                            <FormulateInput key="cn_photo_publisher" error-behavior="submit" name="publisher" :tooltip="$t('chicago_photopublisher_tooltip')" :label="`${$t('chicago_photopublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                        </div>
                        <div class="column is-3 head-follower">
                            <!-- <br /> -->
                            <FormulateInput error-behavior="submit" name="pages" :label="`${$t('chicago_photopages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('chicago_photopages_tooltip')" />
                        </div>
                        <div class="column is-4">
                            <span style="display: block; line-height: 1.5; font-size: .9em; font-weight: 600; margin-bottom: .2em">{{ $t('photo_pubdate') }}</span>
                            <comp-calendar key="chicago_onlinevideo_date" :lang="citation.lang" date-tip="photo_date_tooltip" month-tip="photo_month_tooltip" year-tip="photo2_year_tooltip" />
                        </div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="photo" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'film'">
                    <creators key="cn_film" options-label="extracreators" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_film_title" error-behavior="submit" name="title" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('cn_creatortype_tooltip')" error-behavior="submit" key="cn_film_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), director: $t('director', citation.lang), writer: $t('writer', citation.lang), actor: $t('actor', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="filmyear" key="cn_film_filmyear" :tooltip="$t('film_filmyear_tooltip')" :label="`${$t('film_filmyear')}`" min="0" max="2038" validation="optional|number|between:-0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" key="cn_film_city" name="city" :tooltip="$t('chicago_filmcity_tooltip')" :label="`${$t('chicago_filmcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states key="cn_film_states" :lang="citation.lang "/></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_film_publisher" error-behavior="submit" name="publisher" :tooltip="$t('chicago_filmpublisher_tooltip')" :label="`${$t('chicago_filmpublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cn_film_year" :tooltip="$t('film_releaseyear_tooltip_mand')" :label="`${$t('film_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('chicago_filmmaterial_tooltip')" error-behavior="submit" key="cn_film_meterial" name="material" :options="{ empty: $t('empty_selection', citation.lang), bluray: $t('bluray', citation.lang), dvd: $t('dvd', citation.lang), vhs: $t('vhs', citation.lang), slides: $t('slides', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_filmmaterial')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="duration" key="chicago_film_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'episode'">
                    <creators options-label="extracreators" key="cn_episode" corp-tooltip="tvseries_creator_corp_tooltip" creator-tooltip="tvseries_creator_creator_tooltip" artist-tooltip="tvseries_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_episode_albumtitle" error-behavior="submit" name="albumtitle" :tooltip="$t('tvseries_title_tooltip_mand')" :label="`${$t('chicago_episodealbumtitle')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput key="cn_episode_title" error-behavior="submit" name="title" :tooltip="$t('tvseries_episodetitle_tooltip')" :label="`${$t('chicago_episodetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('cn_creatortype_tooltip')" error-behavior="submit" key="cn_episode_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), director: $t('director', citation.lang), writer: $t('writer', citation.lang), actor: $t('actor', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="season" key="cn_episode_season" :tooltip="$t('digitalmediaseason_tooltip')" :label="`${$t('chicago_episodeseason')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="episode" key="cn_episode_episode" :tooltip="$t('chicago_episodeepisode_tooltip')" :label="`${$t('chicago_episodeepisode')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                        <div class="column"><FormulateInput key="cn_episode_channel" error-behavior="submit" name="channel" :tooltip="$t('tvseries_channel_tooltip')" :label="`${$t('chicago_episodechannel')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-4">
                            <span style="display: block; line-height: 1.5; font-size: .9em; font-weight: 600; margin-bottom: .2em">{{ $t('airdate') }}</span>
                            <comp-calendar key="chicago_episode_date" :lang="citation.lang" date-tip="tvseries_episode_date_tooltip" month-tip="tvseries_episode_month_tooltip" year-tip="tvseries_episode_year_tooltip" />
                        </div>
                        <div class="column is-8 head-follower">
                            <Url @shortenurl="shortenurl($event)" hidedate mediatype="tvseries" />
                        </div>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'online_video'">
                    <creators key="cn_onlinevideo" options-label="extracreators" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cn_onlinevideo_title" error-behavior="submit" name="title" :tooltip="$t('chicago_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-4"><comp-calendar key="chicago_onlinevideo_date" :lang="citation.lang" date-tip="onlinevideo_compcalendar_day_tooltip" month-tip="onlinevideo_compcalendar_month_tooltip" year-tip="onlinevideo_compcalendar_year_tooltip" /></div>
                        <div class="column is-3"><FormulateInput key="cn_onlinevideo_platform" error-behavior="submit" name="platform" :tooltip="$t('chicago_onlinevideoplatform_tooltip')" :label="`${$t('chicago_onlinevideoplatform')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput :tooltip="$t('chicago_onlinevideomaterial_tooltip')" error-behavior="submit" key="cn_onlinevideo_meterial" name="material" :options="{ empty: $t('empty_selection', citation.lang), mpeg: $t('mpeg', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_onlinevideomaterial')}`" validation="optional" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" name="duration" key="chicago_onlinevideo_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="onlinevideo" req />
                </div>
                <div v-else-if="citation.digitalmediatype === 'music'">
                    <creators key="cn_music" options-label="extracreators" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <!-- <div v-if="!citation.hascorpauthor && !citation.hasauthor && !citation.hasartist" class="column"> -->
                        <div class="column">
                            <FormulateInput key="cn_music_title" error-behavior="submit" name="title" :tooltip="$t('chicago_mediatitle_tooltip')" :label="`${$t('title')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                        <!-- </div> -->
                        <!-- <div v-else class="column"> -->
                            <!-- <FormulateInput key="cn_music_title" error-behavior="submit" name="title" :tooltip="$t('chicago_mediatitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /> -->
                        </div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('chicago_creatortype_tooltip')" error-behavior="submit" key="cn_music_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), composer: $t('composer', citation.lang), vocalist: $t('vocalist', citation.lang), conductor: $t('conductor', citation.lang), ensemble: $t('ensemble', citation.lang), musician: $t('musician', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('chicago_audiomediatype_tooltip')" error-behavior="submit" key="cn_musicediatype" name="audiomediatype" :options="{ empty: $t('empty_selection', citation.lang), mp3: $t('mp3_audio', citation.lang), wav: $t('wav_audio', citation.lang), real: $t('real_audio', citation.lang), cd: $t('cd') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_audiomediatype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="trackno" key="cn_track_no" :tooltip="$t('trackno_tooltip')" :label="`${$t('trackno')}`" min="0" max="100" validation="optional|number|between:0,101,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                    </div>
                    <FormulateInput key="cn_album_title" error-behavior="submit" name="albumtitle" :tooltip="$t('apa7_audioalbumtitle_tooltip_mand')" :label="`${$t('chicago_audioalbumtitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="pubyear" key="cn_music_year" :tooltip="$t('song_year_tooltip_mand')" :label="`${$t('song_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cn_music_releaseyear" :tooltip="$t('song_originalreleaseyear_tooltip')" :label="`${$t('song_originalreleaseyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <!-- <div class="column"><comp-calendar key="chicago_music_date" :lang="citation.lang" /></div> -->
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput error-behavior="submit" name="publisher" key="chicago_music_publisher" :tooltip="$t('album_producers_tooltip')" :label="`${$t('cd_audio_mediapublisher')} (*)`" validation="bail|required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="labelno" key="chicago_music_labelno" :tooltip="$t('cn_music_labelno_tooltip')" :label="`${$t('cn_music_labelno')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <performers key="cn_performer" creator-tooltip="album_performer_creator_tooltip" artist-tooltip="album_performer_artist_tooltip" />
                    <conductors key="cn_conductor"/>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput :tooltip="$t('chicago_digitalmediatype_tooltip')" error-behavior="submit" key="cd_digitalmediatype" name="digitalmediatype" :options="{ mp3: $t('mp3'), soundtrack: $t('soundtrack'), album: $t('album'), audiobook: $t('audiobook'), film: $t('film'), speech: $t('speech'), /* painting: $t('chicago_painting'), sculpture: $t('chicago_sculpture'), */ online_video: $t('chicago_online_video') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_digitalmediatype')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="cd_mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'mp3'">
                    <creators key="cd_audio" options-label="extracreators" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div v-if="!citation.hascorpauthor && !citation.hasauthor && !citation.hasartist" class="column"><FormulateInput key="cd_audio_title" error-behavior="submit" name="title" :tooltip="$t('chicago_mediatitle_tooltip')" :label="`${$t('title')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div v-else class="column"><FormulateInput key="cd_audio_title" error-behavior="submit" name="title" :tooltip="$t('chicago_mediatitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('chicago_creatortype_tooltip')" error-behavior="submit" key="cd_audio_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), composer: $t('composer', citation.lang), vocalist: $t('vocalist', citation.lang), conductor: $t('conductor', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_audio_year" :tooltip="$t('song_year_tooltip_mand')" :label="`${$t('song_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('chicago_audiomediatype_tooltip')" error-behavior="submit" key="cd_audiomediatype" name="audiomediatype" :options="{ empty: $t('empty_selection', citation.lang), mp3: $t('mp3_audio', citation.lang), wav: $t('wav_audio', citation.lang), real: $t('real_audio', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_audiomediatype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="trackno" key="cd_track_no" :tooltip="$t('trackno_tooltip')" :label="`${$t('trackno')}`" min="0" max="100" validation="optional|number|between:0,101,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                    </div>
                    <FormulateInput key="cd_album_title" error-behavior="submit" name="albumtitle" :tooltip="$t('chicago_audioalbumtitle_tooltip_mand')" :label="`${$t('chicago_audioalbumtitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <!-- <div class="double"> -->
                    <FormulateInput error-behavior="submit" name="publisher" key="chicago_audio_publisher" :tooltip="$t('cd_audio_mediapublisher_tooltip_mand')" :label="`${$t('cd_audio_mediapublisher')} (*)`" validation="bail|required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <!-- <FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cd_audio_releaseyear" :tooltip="$t('song_releaseyear_tooltip')" :label="`${$t('song_releaseyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /> -->
                    <!-- </div> -->
                    <extra-creators key="cd_contributors" creator-tooltip="song_performer_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'soundtrack'">
                    <creators key="cd_soundtrack" options-label="extracreators" creator-tooltip="soundtrack_creator_creator_tooltip" artist-tooltip="soundtrack_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div v-if="!citation.hascorpauthor && !citation.hasauthor && !citation.hasartist" class="column"><FormulateInput key="cd_soundtrack_title" error-behavior="submit" name="title" :tooltip="$t('soundtrack_title_tooltip')" :label="`${$t('title')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div v-else class="column"><FormulateInput key="cd_soundtrack_title_mand" error-behavior="submit" name="title" :tooltip="$t('soundtrack_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_soundtrack_year" :tooltip="$t('soundtrack_year_tooltip_mand')" :label="`${$t('song_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="trackno" key="cd_soundtrack_track_no" :tooltip="$t('soundtrack_trackno_tooltip')" :label="`${$t('trackno')}`" min="0" max="100" validation="optional|number|between:0,101,value" :validation-messages="{ number: $t('validation_invalid_number'), between: $t('validation_invalid_number') }" /></div>
                    </div>
                    <FormulateInput key="cd_soundtrack_album_title" error-behavior="submit" name="albumtitle" :tooltip="$t('soundtrack_album_tooltip_mand')" :label="`${$t('chicago_audioalbumtitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <!-- <div class="double"> -->
                        <FormulateInput error-behavior="submit" name="publisher" key="chicago_soundtrack_publisher" :tooltip="$t('soundtrack_publisher_tooltip_mand')" :label="`${$t('cd_audio_mediapublisher')} (*)`" validation="bail|required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                        <!-- <FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cd_audio_releaseyear" :tooltip="$t('song_releaseyear_tooltip')" :label="`${$t('song_releaseyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /> -->
                    <!-- </div> -->
                    <extra-creators key="cd_soundtrack_contributors" creator-tooltip="soundtrack_extracreator_creator_tooltip" artist-tooltip="soundtrack_extracreator_artist_tooltip" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'album'">
                    <creators key="cd_album" options-label="extracreators" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cd_album_album_title" error-behavior="submit" name="albumtitle" :tooltip="$t('apa7_audioalbumtitle_tooltip_mand')" :label="`${$t('chicago_audioalbumtitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('chicago_creatortype_tooltip')" error-behavior="submit" key="cd_audio_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), composer: $t('composer', citation.lang), vocalist: $t('vocalist', citation.lang), conductor: $t('conductor', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_album_year" :tooltip="$t('song_year_tooltip_mand')" :label="`${$t('song_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('chicago_albummediatype_tooltip')" error-behavior="submit" key="cd_albummediatype" name="albummediatype" :options="{ empty: $t('empty_selection', citation.lang), cd: $t('cd', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_audiomediatype')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cd_audio_releaseyear" :tooltip="$t('song_releaseyear_tooltip')" :label="`${$t('song_releaseyear')}`" min="0" max="2038" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <FormulateInput error-behavior="submit" name="publisher" key="chicago_album_publisher" :tooltip="$t('album_producers_tooltip_mand')" :label="`${$t('cd_audio_mediapublisher')} (*)`" validation="bail|required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                    <!-- <extra-creators key="cd_album_contributors" /> -->
                </div>
                <div v-else-if="citation.digitalmediatype === 'audiobook'">
                    <contribs name="authors" options-label="authors" single-name="author" type="audiobook" show-corp key="cd_audiobook_authors" creator-tooltip="audiobook_creator_creator_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cd_audiobook_title" error-behavior="submit" name="albumtitle" :tooltip="$t('chicago_audiobooktitle_tooltip_mand')" :label="`${$t('chicago_audiobooktitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <contribs name="readers" single-name="reader" type="audiobook" key="cd_audiobook_readers" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_audiobook_year" :tooltip="$t('audiobook_year_tooltip_mand')" :label="`${$t('audiobook_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" key="cd_audiobook_city" name="city" :tooltip="$t('chicago_audiobookcity_tooltip')" :label="`${$t('chicago_audiobookcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="chicago_audiobook_publisher" :tooltip="$t('audiobook_publisher_tooltip_mand')" :label="`${$t('cd_audio_mediapublisher')} (*)`" validation="bail|required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="duration" key="chicago_audiobook_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                </div>
                <div v-else-if="citation.digitalmediatype === 'film'">
                    <creators key="cd_film" options-label="extracreators" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cd_film_title" error-behavior="submit" name="title" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('cn_creatortype_tooltip')" error-behavior="submit" key="cd_film_creatortype" name="creatortype" :options="{ empty: $t('empty_selection', citation.lang), director: $t('director', citation.lang), writer: $t('writer', citation.lang), actor: $t('actor', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_creatortype')}`" validation="optional" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" type="number" name="filmyear" key="cd_film_filmyear" :tooltip="$t('film_filmyear_tooltip')" :label="`${$t('film_filmyear')}`" min="0" max="2038" validation="optional|number|between:-0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div> -->
                        <div class="column"><FormulateInput error-behavior="submit" key="cd_film_city" name="city" :tooltip="$t('chicago_filmcity_tooltip')" :label="`${$t('chicago_filmcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><states key="cd_film_states" :lang="citation.lang "/></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cd_film_publisher" error-behavior="submit" name="publisher" :tooltip="$t('chicago_filmpublisher_tooltip')" :label="`${$t('chicago_filmpublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_film_year" :tooltip="$t('film_releaseyear_tooltip_mand')" :label="`${$t('film_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('chicago_filmmaterial_tooltip')" error-behavior="submit" key="cd_film_meterial" name="material" :options="{ empty: $t('empty_selection', citation.lang), bluray: $t('bluray', citation.lang), dvd: $t('dvd', citation.lang), vhs: $t('vhs', citation.lang), slides: $t('slides', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_filmmaterial')}`" validation="optional" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="duration" key="cd_film_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                    <!-- <creators key="cd_film" options-label="extracreators" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput key="cd_film_title" error-behavior="submit" name="albumtitle" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('chicago_filmtitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cd_film_year" :tooltip="$t('film_releaseyear_tooltip_mand')" :label="`${$t('film_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" key="cd_film_city" name="city" :tooltip="$t('chicago_filmcity_tooltip')" :label="`${$t('chicago_filmcity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><states :lang="citation.lang" /></div>
                        <div class="column is-4"><comp-calendar key="chicago_film_date" :lang="citation.lang"  date-tip="film_compcalendar_releasedate_tooltip" month-tip="film_compcalendar_releasemonth_tooltip" year-tip="film_compcalendar_releaseyear_tooltip" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" name="duration" key="chicago_film_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="film" /> -->
                </div>
                <div v-else-if="citation.digitalmediatype === 'speech'">
                    <creators key="cd_speech" :hide-corp=true options-label="extracreators" creator-tooltip="speech_creator_creator_tooltip" artist-tooltip="speech_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput key="cd_speech_title" error-behavior="submit" name="title" :tooltip="$t('chicago_speechtitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-4"><FormulateInput key="cd_speech_title" error-behavior="submit" name="albumtitle" :tooltip="$t('chicago_speechalbumtitle_tooltip')" :label="`${$t('chicago_speechalbumtitle')}`" validation="optional|invalidText"  :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-2"><FormulateInput error-behavior="submit" type="number" name="year" key="cd_speech_year" :tooltip="$t('speech_year_tooltip_mand')" :label="`${$t('speech_year')} (*)`" min="-2038" max="2038" validation="bail|required|number|between:-2039,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column is-3"><FormulateInput :tooltip="$t('chicago_audiomediatype_tooltip')" error-behavior="submit" key="cd_audiomediatype" name="audiomediatype" :options="{ empty: $t('empty_selection', citation.lang), speech_mp3: $t('mp3_audio', citation.lang), speech_wav: $t('wav_audio', citation.lang), speech_real: $t('real_audio', citation.lang) }" type="select" :placeholder="$t('choose_option')" :label="`${$t('chicago_audiomediatype')}`" validation="optional" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="duration" key="chicago_speech_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="speech" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'online_video'">
                    <creators key="cd_onlinevideo" options-label="extracreators" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column is-9"><FormulateInput key="cd_onlinevideo_title" error-behavior="submit" name="albumtitle" :tooltip="$t('chicago_onlinevideotitle_tooltip_mand')" :label="`${$t('chicago_onlinevideotitle')} (*)`" validation="required|invalidText"  :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" type="number" name="releaseyear" key="cd_onlinevideo_year" :tooltip="$t('onlinevideo_year_tooltip_mand')" :label="`${$t('onlinevideo_year')} (*)`" min="0" max="2038" validation="bail|required|number|between:0,2039,value" :validation-messages="{ required: $t('validation_required_text'), number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" key="cd_onlinevideo_platform" name="platform" :tooltip="$t('chicago_onlinevideoplatform_tooltip')" :label="`${$t('chicago_onlinevideoplatform')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <!-- <div class="column head-follower is-3"><FormulateInput error-behavior="submit" key="cd_onlinevideo_performance" name="performance" :tooltip="$t('chicago_onlinevideoperformance_tooltip')" :label="`${$t('chicago_onlinevideoperformance')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                        <!-- <div class="column is-4">
                            <span style="display: block; line-height: 1.5; font-size: .9em; font-weight: 600; margin-bottom: .2em">{{ $t('chicago_onlinevideo_originaldate') }}</span>
                            <comp-calendar key="chicago_onlinevideo_date" :lang="citation.lang" date-tip="onlinevideo_compcalendar_original_day_tooltip" month-tip="onlinevideo_compcalendar_original_month_tooltip" year-tip="onlinevideo_compcalendar_original_year_tooltip" />
                        </div> -->
                        <div class="column"><calendar name="dateposted" :tooltip="$t('onlinevideo_dateposted_tooltip')" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="duration" key="chicago_onlinevideo_duration" :tooltip="$t('chicago_audiobookduration_tooltip')" :label="`${$t('chicago_audiobookduration')}`" validation="optional|invalidDuration" :validation-messages="{ invalidDuration: $t('validation_invalid_duration') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><Url @shortenurl="shortenurl($event)" hidedate mediatype="onlinevideo" req /></div>
                    </div>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput :tooltip="$t('mla_digitalmediatype_tooltip')" error-behavior="submit" key="mla_digitalmediatype" name="digitalmediatype" :options="{ digital: $t('digital'), painting: $t('painting'), song: $t('song'), film: $t('film'), episode: $t('mla_episode') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('mla_digitalmediatype')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'digital'">
                    <creators key="mla_digital" options-label="extracreators" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" key="mla_digital_title" error-behavior="submit" name="title" :tooltip="$t('chicago_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="mla_digital_publisher" :tooltip="$t('digital_digitalmediapublisher_tooltip')" :label="`${$t('digital_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="uploader" key="mla_digital_uploader" :tooltip="$t('digitalmediauploader_tooltip')" :label="`${$t('digitalmediauploader')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><calendar name="year" :tooltip="$t('onlinevideo_dateposted_tooltip')" :label="`${$t('mla_digitalmediatype_year')}`" key="mla_digital_year" /></div>
                        <!-- <FormulateInput error-behavior="submit" type="date" key="mla_digital_year" name="year" :tooltip="$t('mla_digitalmediatype_year_tooltip')" :label="`${$t('mla_digitalmediatype_year')}`" min="0001-01-01" max="2038-12-31" validation="optional|^date|after:0001-01-01|before:2038-12-31" :validation-messages="{ date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                        <div class="column"><FormulateInput error-behavior="submit" name="timestamp" key="mla_digital_timestamp" :tooltip="$t('digitalmediatimestamp_tooltip')" :label="`${$t('digitalmediatimestamp')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="onlinevideo" key="mla_digital_uri" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'painting'">
                    <creators key="mla_painting" options-label="extracreators" corp-tooltip="exhibit_creator_corp_tooltip" creator-tooltip="exhibit_creator_creator_tooltip" artist-tooltip="exhibit_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" error-behavior="submit" key="mla_painting_title" name="title" :tooltip="$t('apa7_artworktitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="mla_painting_year" :tooltip="$t('mla_painting_digitalmediatype_year_tooltip')" :label="`${$t('mla_painting_digitalmediatype_year')}`" min="-2038" max="2038" validation="optional|number|between:-2039,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="mla_painting_publisher" :tooltip="$t('painting_digitalmediapublisher_tooltip')" :label="`${$t('painting_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="city" key="mla_painting_city" :tooltip="$t('painting_digitalmediacity_tooltip')" :label="`${$t('painting_digitalmediacity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="painting" key="mla_painting_uri" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'song'">
                    <creators key="mla_song" options-label="extracreators" creator-tooltip="song_creator_creator_tooltip" artist-tooltip="song_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" error-behavior="submit" key="mla_song_title" name="title" :tooltip="$t('song_digitalmediatitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput :tooltip="$t('song_digitalmediaalbum_tooltip')" error-behavior="submit" key="mla_song_digitalmediaalbum" name="digitalmediaalbum" :label="`${$t('song_digitalmediaalbum')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="producer" key="mla_song_producer" :tooltip="$t('cd_audio_mediapublisher_tooltip')" :label="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" name="year" key="mla_song_year" min="0" max="2038" :tooltip="$t('song_year_tooltip')" :label="`${$t('song_year')}`" validation="optional|number|between:0,2039,value" :validation-messages="{ number: $t('validation_invalid_year'), between: $t('validation_invalid_year') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" hidedate mediatype="audio" key="mla_song_uri" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'film'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="mla_film_creator_corp_tooltip" creator-tooltip="mla_film_creator_creator_tooltip" artist-tooltip="mla_film_creator_artist_tooltip" key="mla_film_episode" :media-type="citation.digitalmediatype" :system="citation.system" show-corp show-creator show-artist show-contribution focusoncontrib />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" :tooltip="$t('film_title_tooltip_mand')" error-behavior="submit" key="mla_film_digitalmediaalbum" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="producer" :tooltip="$t('digitalmediaproducers_tooltip')" key="mla_film_producer" :label="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="text" name="series_year" key="mla_film_year" :tooltip="$t('film_releaseyear_tooltip')" :label="`${$t('year')}`" validation="optional|matches:/(\d+-?\d+$)/" :validation-messages="{ matches: $t('validation_invalid_year') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="mla_film_publisher" :tooltip="$t('digital_digitalmediapublisher_tooltip')" :label="`${$t('digital_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="timestamp" key="mla_film_timestamp" :tooltip="$t('digitalmediatimestamp_tooltip')" :label="`${$t('digitalmediatimestamp')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="film" key="mla_film_uri" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'episode'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="mla_tvseries_creator_corp_tooltip" creator-tooltip="mla_tvseries_creator_creator_tooltip" artist-tooltip="mla_tvseries_creator_artist_tooltip" key="mla_episode" :media-type="citation.digitalmediatype" :system="citation.system" show-corp show-creator show-artist show-contribution focusoncontrib />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" error-behavior="submit" key="mla_episode_title" name="title" :tooltip="$t('film_title_tooltip')" :label="`${$t('title')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('mla_episode_digitalmediaalbum_tooltip_mand')" error-behavior="submit" key="mla_episode_digitalmediaalbum" name="digitalmediaalbum" :label="`${$t('mla_episode_digitalmediaalbum')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="number" min="0" name="season" key="mla_episode_season" :tooltip="$t('digitalmediaseason_tooltip')" :label="`${$t('digitalmediaseason')}`" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" type="number" min="0" name="episode" key="mla_episode_episode" :tooltip="$t('digitalmediaepisode_tooltip')" :label="`${$t('digitalmediaepisode')}`" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="producer" :tooltip="$t('digitalmediaproducers_tooltip')" key="mla_episode_producer" :label="`${$t('digitalmediaproducer')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" type="text" name="series_year" key="mla_series_year" :tooltip="$t('mla_series_year_tooltip')" :label="`${$t('mla_series_year')}`" validation="optional|matches:/(\d+-?\d+$)/" :validation-messages="{ matches: $t('validation_invalid_year') }" /></div>
                        <div class="column"><calendar name="year" key="mla_episode_year" :tooltip="$t('film_releasedate_tooltip')" :label="`${$t('mla_episode_year')}`" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="mla_episode_publisher" :tooltip="$t('digital_digitalmediapublisher_tooltip')" :label="`${$t('digital_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="timestamp" key="mla_episode_timestamp" :tooltip="$t('digitalmediatimestamp_tooltip')" :label="`${$t('digitalmediatimestamp')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" mediatype="film" key="mla_episode_uri" />
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput :tooltip="$t('ieee_youtube_tooltip')" error-behavior="submit" key="mla_digitalmediatype" name="digitalmediatype" :options="{ digital: $t('ieee_youtube') }" type="select" :placeholder="$t('choose_option')" :label="`${$t('mla_digitalmediatype')} (*)`" validation="required" :validation-messages="{ required: $t('validation_required_select') }" @change="mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'digital'">
                    <creators options-label="creators" key="ieee_digital" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput v-if="citation.digitalmediatype" key="mla_digital_title" error-behavior="submit" name="title" :tooltip="$t('chicago_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('onlinevideo_city_tooltip')" :label="`${$t('ieee_city')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column is-3"><states :lang="citation.lang" dc tip="onlinevideo_state_tooltip" /></div>
                        <div class="column is-3"><countries :lang="citation.lang" tip="onlinevideo_country_tooltip" /></div>
                        <div class="column is-3">
                            <calendar name="year" key="ieee_digital_year" :tooltip="$t('onlinevideo_dateposted_tooltip')" :label="`${$t('mla_digitalmediatype_year')}`" />
                            <!-- <FormulateInput v-model="year" error-behavior="submit" type="date" key="ieee_digital_year" name="year" :tooltip="$t('mla_digitalmediatype_year_tooltip')" :label="`${$t('mla_digitalmediatype_year')}`" min="0001-01-01" max="2038-12-31" validation="optional|^date|after:0001-01-01|before:2038-12-31" :validation-messages="{ date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                        </div>
                        <!-- <div class="column is-1"><a class="delete dateaccessed" @click.prevent="clearYear" :title="$t('clear_dateaccessed_button')"></a></div> -->
                    </div>
                    <Url req @shortenurl="shortenurl($event)" mediatype="onlinevideo" key="mla_digital_uri" />
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="digitalmedia" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="digitalmedia" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <FormulateInput error-behavior="submit" key="cse_digitalmediatype" name="digitalmediatype" type="select" validation="required" :tooltip="$t('apa_digitalmediatype_tooltip')" :placeholder="$t('choose_option')" :label="`${$t('apa_digitalmediatype')} (*)`" :validation-messages="{ required: $t('validation_required_select') }" :options="{ film: $t('cse_film'), online_video: $t('cse_online_video'), image: $t('cse_image') }" @change="cse_mediatypechanged" />
                <div v-if="citation.digitalmediatype === 'film'">
                    <creators-2 options-label="csefilmextracreatorswd" name="creators" :lang="citation.lang" corp-tooltip="film_creator_corp_tooltip" creator-tooltip="film_creator_creator_tooltip" artist-tooltip="film_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist :system="citation.system" />
                    <creators-2 options-label="cse_extracreatorsprod" name="extracreators" :lang="citation.lang" creator-tooltip="film_contributor_creator_tooltip" artist-tooltip="film_contributor_artist_tooltip" :media-type="citation.digitalmediatype" show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('film_title_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-5"><comp-calendar :lang="citation.lang" date-tip="film_compcalendar_releasedate_tooltip" month-tip="film_compcalendar_releasemonth_tooltip" year-tip="film_compcalendar_releaseyear_tooltip" /></div>
                        <div class="column is-3"><FormulateInput :tooltip="$t('dateyeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('dateyeartypepublication', citation.lang), copyrightyear: $t('dateyeartypecopyright', citation.lang), inferredyear: $t('dateyeartypeinferred', citation.lang) }" type="select" :label="`${$t('dateyeartype')}`" /></div>
                        <div class="column is-4"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('cse_digitalmediafilmdesc_tooltip')" :label="`${$t('digitalmediadesc')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="producers" :repeatable="true" :label="`${$t('digitalmediaproducers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('film_producercity_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc /></div>
                                <div class="column"><countries :lang="citation.lang" tip="film_country_tooltip" /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="producer" :label="`${$t('digitalmediaproducer')} (*)`" :tooltip="`${$t('digitalmediaproducers_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <FormulateInput error-behavior="submit" name="notes" :tooltip="$t('cse_digitalmedia_notes_tooltip')" :label="`${$t('cse_digitalmedia_notes')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                    <!-- <Url @shortenurl="shortenurl" condreq mediatype="film" /> -->
                </div>
                <div v-else-if="citation.digitalmediatype === 'online_video'">
                    <creators-2 options-label="extracreators" name="creators" :lang="citation.lang" corp-tooltip="onlinevideo_creator_corp_tooltip" creator-tooltip="onlinevideo_creator_creator_tooltip" artist-tooltip="onlinevideo_creator_artist_tooltip" :media-type="citation.digitalmediatype" show-corp show-creator show-artist show-contribution :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_onlinevideotitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><calendar name="year" :label="$t('airdate')" :tooltip="$t('onlinevideo_airdate_tooltip')" /></div>
                        <div class="column"><FormulateInput :tooltip="$t('datetype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('datetypepublication', citation.lang), copyrightyear: $t('datetypecopyright', citation.lang), inferredyear: $t('datetypeinferred', citation.lang) }" type="select" :label="`${$t('datetype')}`" /></div>
                        <!-- <div class="column"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('digitalmediaonlinevideodesc_tooltip')" :label="`${$t('digitalmediadesc')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                        <div class="column"><FormulateInput error-behavior="submit" name="length" :tooltip="$t('digitalmediaonlinevideolength_tooltip')" :label="`${$t('digitalmediaonlinevideolength')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="programmetitle" key="cseprogrammetitle" :tooltip="$t('cseprogrammetitle_tooltip')" :label="`${$t('cseprogrammetitle')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                        <div class="column"><FormulateInput error-behavior="submit" name="publisher" key="cse_episode_publisher" :tooltip="$t('onlinevideo_producer_tooltip')" :label="`${$t('cse_digitalmediapublisher')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <Url @shortenurl="shortenurl($event)" req condreq mediatype="onlinevideo" />
                </div>
                <div v-else-if="citation.digitalmediatype === 'image'">
                    <creators-2 options-label="creators" name="creators" :lang="citation.lang" corp-label="corporate_creator" creator-label="creators" artist-label="artists" corp-tooltip="exhibit_creator_corp_tooltip" creator-tooltip="exhibit_creator_creator_tooltip" artist-tooltip="exhibit_creator_artist_tooltip" show-corp show-creator show-artist :system="citation.system" />
                    <div class="columns is-desktop">
                        <div class="column"><FormulateInput error-behavior="submit" name="title" :tooltip="$t('apa7_artworktitle_tooltip_mand')" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div class="columns is-desktop">
                        <div class="column is-6"><comp-calendar :lang="citation.lang" date-tip="exhibit_date_tooltip" month-tip="exhibit_month_tooltip" year-tip="exhibit_year_tooltip" /></div>
                        <div class="column is-3"><FormulateInput :tooltip="$t('dateyeartype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('dateyeartypepublication', citation.lang), copyrightyear: $t('dateyeartypecopyright', citation.lang), inferredyear: $t('dateyeartypeinferred', citation.lang) }" type="select" :label="`${$t('dateyeartype')}`" /></div>
                        <div class="column is-3"><FormulateInput error-behavior="submit" name="description" :tooltip="$t('ssf_digitalmediaartworkdesc_tooltip')" :label="`${$t('digitalmediadesc')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    </div>
                    <div id="ieee-institutes">
                        <FormulateInput type="group" name="producers" :repeatable="true" :label="`${$t('cse_digitalmedia_publishers')} (*)`" :add-label="$t('add')" validation="minLengthDigitalMediaProducers" :validation-rules="{ minLengthDigitalMediaProducers: minLengthCityProducerRule }" :validation-messages="{ minLengthDigitalMediaProducers: $t('validation_producer_min_length') }">
                            <div class="columns is-desktop">
                                <div class="column"><FormulateInput error-behavior="submit" name="city" :tooltip="$t('exhibit_city_tooltip_mand')" :label="`${$t('ieee_city')} (*)`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                                <div class="column"><states :lang="citation.lang" dc tip="exhibit_state_tooltip" /></div>
                                <div class="column"><countries :lang="citation.lang" tip="exhibit_country_tooltip" /></div>
                                <div class="column group-margin"><FormulateInput error-behavior="submit" name="producer" :label="`${$t('cse_digitalmedia_museum')} (*)`" :tooltip="`${$t('painting_digitalmediapublisher_tooltip_mand')}`" validation="invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                            </div>
                        </FormulateInput>
                    </div>
                    <Url @shortenurl="shortenurl($event)" condreq mediatype="painting" />
                </div>
                <div v-else>
                </div>
                <Controls :loading="loading" @formreset="formreset($event)" formname="digitalmedia" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Controls from '@/components/forms/Controls';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';
    import States from '@/components/forms/States';
    import Countries from '@/components/forms/Countries';
    import Creators from '@/components/forms/Creators';
    import Calendar from '@/components/forms/Calendar';
    import ExtraCreators from '@/components/forms/ExtraCreators';
    import Contribs from '@/components/forms/Contribs';
    import CompCalendar from '@/components/forms/CompCalendar';
    import Performers from '@/components/forms/Performers';
    import Conductors from '@/components/forms/Conductors';
    import Creators2 from '@/components/forms/Creators2';

    export default {
        name: 'DigitalMedia',

        components: {
            SysLang,
            Controls,
            Url,
            States,
            Countries,
            Modal,
            Creators,
            Calendar,
            ExtraCreators,
            Contribs,
            CompCalendar,
            Performers,
            Conductors,
            Creators2,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            year: '',
            loading: false,
        }),

        methods: {
            clearYear: function() {
                this.year = '';
            },

            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'digitalmedia';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('digitalmedia');
                this.citation.system = data;
            },

            mediatypechanged: function() {
                this.citation.hascorpauthor = false;
                this.citation.hasauthor = false;
                this.citation.hasartist = false;
                this.citation.title = '';
                this.citation.producer = '';
                this.citation.publisher = '';
                this.citation.uploader = '';
                this.citation.year = null;
                this.citation.timestamp = '';
                this.citation.city = '';
                this.citation.digitalmediaalbum = '';
                this.citation.season = '';
                this.citation.episode = '';
                this.citation.uri = '';
                this.citation.dateaccessed = null;
                this.$formulate.resetValidation('digitalmedia');
            },

            cd_mediatypechanged: function() {
                this.citation.hascorpauthor = false;
                this.citation.hasauthor = false;
                this.citation.hasartist = false;
                this.citation.title = '';
                this.citation.producer = '';
                this.citation.publisher = '';
                this.citation.uploader = '';
                this.citation.year = null;
                this.citation.timestamp = '';
                this.citation.city = '';
                this.citation.digitalmediaalbum = '';
                this.citation.season = '';
                this.citation.episode = '';
                this.citation.uri = '';
                this.citation.dateaccessed = null;
                this.citation.duration = '';
                this.$formulate.resetValidation('digitalmedia');
            },

            apa7_mediatypechanged: function() {
                this.$formulate.resetValidation('digitalmedia');
            },

            cse_mediatypechanged: function() {
                this.$formulate.resetValidation('digitalmedia');
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                let type = this.citation.digitalmediatype;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                this.citation.digitalmediatype = type;
                if (sys === 'apa5') {
                    if (this.citation.digitalmediatype === 'film') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.publishers = [
                            {
                                country: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'broadcast') {
                        this.citation.publishers = [
                            {
                                state: 'empty'
                            }
                        ];
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.year = '';
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'tvseries') {
                        this.citation.publishers = [
                            {
                                state: 'empty'
                            }
                        ];
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'episode') {
                        this.citation.publishers = [
                            {
                                state: 'empty'
                            }
                        ];
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.extracreators_hascorp = false;
                        this.citation.extracreators_hascreator = false;
                        this.citation.extracreators_hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'album') {
                        this.citation.publishers = [
                            {
                                state: 'empty'
                            }
                        ];
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'song') {
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.artists_hascreator = false;
                        this.citation.artists_hasartist = false;
                        this.citation.publishers = [
                            {
                                state: 'empty'
                            }
                        ];
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'online_video') {
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.creators_hascorp = false;
                        this.citation.year = '';
                    }
                    else {
                        // TODO: General handler
                    }                    
                }
                if (sys === 'apa7') {
                    if (this.citation.digitalmediatype === 'song') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'album') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'film') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'tvseries') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'episode') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.year = '';
                        this.citation.extracreators_hascorp = false;
                        this.citation.extracreators_hascreator = false;
                        this.citation.extracreators_hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'online_video') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.year = '';
                    }
                    else if (this.citation.digitalmediatype === 'artwork') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                    }
                    else if (this.citation.digitalmediatype === 'photo') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else {
                        // TODO: General handler
                    }
                }
                if (sys === 'chicago_notes') {
                    if (this.citation.digitalmediatype === 'painting') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.title = '';
                        // this.citation.producer = '';
                        this.citation.publisher = '';
                        // this.citation.uploader = '';
                        this.citation.year = null;
                        // this.citation.timestamp = '';
                        this.citation.city = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                    }
                    else if (this.citation.digitalmediatype === 'sculpture') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '',
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'photo') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                        this.citation.hascorpauthor = false;
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'film') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.dateaccessed = null;
                        this.citation.duration = '';
                        this.citation.city = '';
                        this.citation.state = 'empty';
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'episode') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        this.citation.season = '';
                        this.citation.episode = '';
                        // this.citation.dateaccessed = null;
                        this.citation.uri = '';
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'online_video') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.publisher = '';
                        // this.citation.city = '';
                        this.citation.duration = '';
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'music') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        this.citation.dateaccessed = null;
                        this.citation.publisher = '';
                        // this.citation.city = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        this.citation.hasperformer = false;
                        this.citation.hasperformerartist = false;
                        this.citation.hasconductor = false;
                        this.citation.hasconductorartist = false;
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else {
                        // TODO: General handler
                    }                    
                }
                if (sys === 'chicago_date') {
                    if (this.citation.digitalmediatype === 'mp3') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.title = '';
                        // this.citation.producer = '';
                        this.citation.publisher = '';
                        // this.citation.uploader = '';
                        this.citation.year = null;
                        // this.citation.timestamp = '';
                        // this.citation.city = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                        this.citation.hasextracorpauthor = false;
                        this.citation.hasextraauthor = false;
                        this.citation.hasextraartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'soundtrack') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.city = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.hasextracorpauthor = false;
                        this.citation.hasextraauthor = false;
                        this.citation.hasextraartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'album') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.city = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        // this.citation.duration = '';
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'audiobook') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        this.citation.city = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        this.citation.duration = '';
                        // this.citation.title = '';
                        this.citation.hasauthors = false;
                        this.citation.hasreaders = false;
                        this.citation.hascorpauthor = false;
                    }
                    else if (this.citation.digitalmediatype === 'film') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        this.citation.title = '';
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.state = 'empty';
                        this.citation.hascorpauthor = false;
                    }
                    else if (this.citation.digitalmediatype === 'speech') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        this.citation.uri = '';
                        // this.citation.dateaccessed = null;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                    }
                    else if (this.citation.digitalmediatype === 'online_video') {
                        // this.citation.producer = '';
                        // this.citation.uploader = '';
                        // this.citation.timestamp = '';
                        // this.citation.digitalmediaalbum = '';
                        // this.citation.season = '';
                        // this.citation.episode = '';
                        // this.citation.dateaccessed = null;
                        this.citation.hascorpauthor = false;
                        // this.citation.publisher = '';
                        // this.citation.city = '';
                        this.citation.dateposted = '';
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.uri = '';
                    }
                    else {
                        // TODO: General handler
                    }                    
                }
                if (sys === 'mla') {
                    if (this.citation.digitalmediatype === 'digital') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else if (this.citation.digitalmediatype === 'painting') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.uri = '';
                    }
                    else if (this.citation.digitalmediatype === 'song') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.uri = '';
                    }
                    else if (this.citation.digitalmediatype === 'film') {
                        this.citation.dateaccessed = '';
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                    }
                    else if (this.citation.digitalmediatype === 'episode') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                    }
                    else {
                        // TODO: General handler
                    }
                }
                if (sys === 'ieee') {
                    if (this.citation.digitalmediatype === 'digital') {
                        this.citation.hascorpauthor = false;
                        this.citation.hasauthor = false;
                        this.citation.hasartist = false;
                        this.citation.title = '';
                        this.citation.year = '';
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.state = 'empty';
                        this.citation.country = 'empty';
                    }
                    else {
                        // TODO: General handler
                    }                    
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    if (this.citation.digitalmediatype === 'film') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.extracreators_hascreator = false;
                        this.citation.extracreators_hasartist = false;
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.year = '';
                        this.citation.yeartype = 'publicationyear';
                        this.citation.producers =  [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                    }
                    else if (this.citation.digitalmediatype === 'online_video') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.yeartype = 'publicationyear';
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.year = '';
                    }
                    else if (this.citation.digitalmediatype === 'image') {
                        this.citation.creators_hascorp = false;
                        this.citation.creators_hascreator = false;
                        this.citation.creators_hasartist = false;
                        this.citation.yeartype = 'publicationyear',
                        this.citation.uri = '';
                        this.citation.dateaccessed = '';
                        this.citation.date = '';
                        this.citation.month = '0';
                        this.citation.producers = [
                            {
                                state: 'empty',
                                country: 'empty'
                            }
                        ];
                        this.citation.year = '';
                    }
                    else {
                        // TODO: General handler
                    }                    
                }
            },

            minLengthProducerRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [producer] = value;
                    return producer.publisher;
                }
                return false;
            },

            minLengthCityProducerRule ({ value }) {
                if (Array.isArray(value) && value.length >= 1) {
                    const [producer] = value;
                    return producer.city && (producer.publisher || producer.producer);
                }
                return false;
            },

        },
    }
</script>

<style>
    #ieee-institutes a[role=button] {
        margin-top: 1.35em
    }
</style>