<template>
    <div>
        <section class="hero">
            <div class="hero-body" style="padding-bottom: 0">
                <div class="container">
                    <h1 class="title">
                        {{ $t('terms_text_title') }}
                    </h1>
                </div>
            </div>
        </section>
        <div class="section" style="padding: 0 1.5rem">
            <div class="container" style="margin-bottom: 2rem; padding-top: 2em">
                <p v-html="$t('terms_general_text')" class="has-text-justified terms-section"></p>
                <br />
                <p v-html="$t('terms_privacy_text')" class="has-text-justified terms-section"></p>
                <br />
                <p v-html="$t('terms_cookies_text')" class="has-text-justified terms-section"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Terms',
    }
</script>
