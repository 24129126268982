<template>
    <div>
        <div class="source-page-title">{{ $t('newspaper') }}</div>
        <hr />

        <FormulateForm v-if="!citation.system || citation.system === 'apa5'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspaperissue_tooltip')" name="newspaperissue" :label="`${$t('newspaperissue')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('newspaper_spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" condreq />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'apa7'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspaperissue_tooltip')" name="newspaperissue" :label="`${$t('newspaperissue')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('newspaper_spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_notes'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspaperissue_tooltip')" name="newspaperissue" :label="`${$t('newspaperissue')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" key="cn_newspaper_pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" key="cn_newspaper_spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('newspaper_spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'chicago_date'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <!-- <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspaperissue_tooltip')" name="newspaperissue" :label="`${$t('newspaperissue')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div> -->
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" key="cd_pages" name="pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" key="cd_spages" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('newspaper_spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'mla'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('mla_newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspapercity_tooltip')" name="newspapercity" :label="`${$t('newspapercity')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                </div>
                <div class="columns is-desktop">
                    <!-- <FormulateInput error-behavior="submit" :tooltip="$t('newspaperissue_tooltip')" name="newspaperissue" :label="`${$t('newspaperissue')}`" /> -->
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="spages" :label="`${$t('spages')}`" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" :tooltip="$t('newspaper_spages_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'ieee'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contribs name="authors" type="newspaper" single-name="author" show-corp corp-tooltip="newspaper_hascorpauthor_tooltip" creator-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('mla_newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><comp-calendar :lang="citation.lang" date-tip="newspaperdate_date_tooltip" month-tip="newspaperdate_month_tooltip" year-tip="newspaperdate_year_tooltip" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" :label="`${$t('pages')}`" :tooltip="$t('newspaper_pages_tooltip')" validation="optional|matches:/(\d+$)/" :validation-messages="{ matches: $t('validation_invalid_pages') }" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" key="ieee_newspaper_uri" mediatype="newspaper" />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <FormulateForm v-else-if="citation.system === 'cse_date' || citation.system === 'cse_cit'" v-model="citation" name="newspaper" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
            <sys-lang form="newspaper" @systemchanged="systemchanged" />
            <div v-if="citation.lang">
                <contributors name="authors" type="newspaper" creator-tooltip="newspaper_hascorpauthor_tooltip" artist-tooltip="newspaper_hasauthors_tooltip" />
                <FormulateInput error-behavior="submit" :tooltip="$t('newspapersourcetitle_tooltip')" name="title" :label="`${$t('title')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspapername_tooltip')" name="newspapername" :label="`${$t('newspapername')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><calendar name="newspaperdate" req /></div>
                    <div class="column"><FormulateInput :tooltip="$t('datetype_tooltip')" error-behavior="submit" name="yeartype" :options="{ publicationyear: $t('datetypepublication', citation.lang), copyrightyear: $t('datetypecopyright', citation.lang), inferredyear: $t('datetypeinferred', citation.lang) }" type="select" :label="`${$t('datetype')}`" /></div>
                </div>
                <div class="columns is-desktop">
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('newspaperedition_tooltip')" name="newspaperedition" :label="`${$t('newspaperedition')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" :tooltip="$t('csenewspapersection_tooltip')" name="newspapersection" :label="`${$t('csenewspapersection')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
                    <!-- <FormulateInput error-behavior="submit" type="date" :tooltip="$t('newspaperdate_tooltip')" name="newspaperdate" :label="`${$t('date')} (*)`" min="0000-01-01" max="2038-12-31" validation="bail|required|date|after:0000-01-01|before:2038-12-31" :validation-messages="{ required: $t('validation_required_text'), date: $t('validation_invalid_date'), before: $t('validation_date_late'), after: $t('validation_date_early') }" /> -->
                    <div class="column"><FormulateInput error-behavior="submit" name="pages" type="number" :label="`${$t('csenewspaperpage')}`" :tooltip="$t('csenewspaperpage_tooltip')" validation="optional|number|between:0,2039,value" min="1" max="1000" :validation-messages="{ number: $t('validation_invalid_pages'), between: $t('validation_invalid_year') }" /></div>
                    <div class="column"><FormulateInput error-behavior="submit" name="newspapercolumn" :label="`${$t('csenewspapercolumn')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" :tooltip="$t('csenewspapercolumn_tooltip')" /></div>
                </div>
                <Url @shortenurl="shortenurl($event)" mediatype="newspaper" condreq />
                <Controls :loading="loading" @formreset="formreset($event)" formname="newspaper" />
            </div>
        </FormulateForm>

        <div v-else></div>

        <!-- <pre>{{ citation }}</pre> -->
        <modal v-show="showResult" @close="showResult = false" :inref="inref" :backref="bkref" :footref="fref" :source="source" :system="citation.system" :errorMessage="errorMessage" />
    </div>
</template>

<script>
    import axios from 'axios';
    import SysLang from '@/components/forms/SysLang';
    import Contributors from '@/components/forms/Contributors';
    import Contribs from '@/components/forms/Contribs';
    import Controls from '@/components/forms/Controls';
    import CompCalendar from '@/components/forms/CompCalendar';
    import Calendar from '@/components/forms/Calendar';
    import Url from '@/components/forms/Url';
    import Modal from '@/components/Modal';

    export default {
        name: 'Newspaper',

        components: {
            SysLang,
            Contributors,
            Contribs,
            Controls,
            CompCalendar,
            Calendar,
            Url,
            Modal,
        },

        data: () => ({
            citation: {},
            showResult: false,
            inref: '',
            bkref: '',
            fref: '',
            source: '',
            errorMessage: '',
            loading: false,
        }),

        methods: {
            submit() {
                let system = this.citation.system.replace('_', '-');
                this.citation.type = 'newspaper';

                this.loading = true;

                this.$gtag.event(`cite`, {
                    'event_category': `${this.citation.type}${this.citation.digitalmediatype ? `/${this.citation.digitalmediatype}` : ``}${this.citation.system ? `-${this.citation.system}` : ``}`,
                    'event_label': `${this.citation.system}`,
                    'value': 1
                });
                
                axios.post(`${process.env.VUE_APP_API_BASE_URL}${system}/${this.citation.type}`, this.citation)
                .then((response) => {
                    // handle success
                    this.inref = response.data.payload.inref;
                    this.bkref = response.data.payload.backref;
                    this.fref = response.data.payload.footref;
                    this.source = response.data.payload.source;
                })
                .catch((error) => {
                    // handle error
                    this.errorMessage += '<ul>';
                    error.response.data.errors.forEach((err) => {
                        this.errorMessage += `<li>${err}</li>`;
                    });
                    this.errorMessage += '</ul>';
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.showResult = true;
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            systemchanged: function(data) {
                this.citation = {};
                this.$formulate.reset('newspaper');
                this.citation.system = data;
            },

            shortenurl: function(data) {
                this.citation.uri = data;
            },

            formreset: function(data) {
                let sys = this.citation.system;
                let lang = this.citation.lang;
                this.citation = {};
                this.$formulate.reset(data);
                this.$formulate.resetValidation(data);
                this.citation.system = sys;
                this.citation.lang = lang;
                if (sys === 'apa5') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'apa7') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_notes') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'chicago_date') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'mla') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'ieee') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.date = '',
                    this.citation.month = '0',
                    this.citation.year = '',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
                if (sys === 'cse_date' || sys === 'cse_cit') {
                    this.citation.hascorpauthor = false,
                    this.citation.hasauthors = false,
                    this.citation.newspaperdate = '',
                    this.citation.yeartype = 'publicationyear',
                    this.citation.uri = '',
                    this.citation.dateaccessed = ''
                }
            },
        },
    }
</script>
