<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        {{ $t('contact_title') }}
                    </h1>
                    <h2 class="subtitle">
                        <br />
                        <p>
                            {{ $t('contact_text') }}
                            <a href="mailto:info@atcite.com" class="contact-email">info [at] atcite.com</a>
                        </p>
                    </h2>
                    <div>{{ $t('contact_form_intro') }}</div>
                    <section class="section">
                        <div class="container">
                            <div class="columns is-centered">
                                <div class="column is-three-quarters" style="padding: 2em; border: 1px solid #dbdbdb; border-radius: .3em">
                                    <FormulateForm v-model="contactform" name="contactform" autocomplete="off" class="form-wrapper formulate-form" novalidate @submit="submit" @submit-raw="validate">
                                        <FormulateInput error-behavior="submit" name="contactform_name" type="text" :tooltip="$t('contactform_name_tooltip')" :label="`${$t('contactform_name')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
                                        <FormulateInput error-behavior="submit" name="contactform_email" type="email" :tooltip="$t('contactform_email_tooltip')" :label="`${$t('contactform_email')} (*)`" validation="bail|required|email" :validation-messages="{ required: $t('validation_required_text'), email: $t('validation_invalid_email') }" />
                                        <FormulateInput error-behavior="submit" name="contactform_subject" type="text" :tooltip="$t('contactform_subject_tooltip')" :label="`${$t('contactform_subject')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" />
                                        <FormulateInput error-behavior="submit" name="contactform_text" type="textarea" :tooltip="$t('contactform_text_tooltip')" :label="`${$t('contactform_text')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" class="tarea" />
                                        <hr />
                                        <div class="columns is-mobile">
                                            <div class="column">
                                                <FormulateInput type="submit" style="text-align: right" :title="$t('contactform_send')">
                                                    {{ $t('contactform_send') }} <span v-if="loading" class="loader" style="margin-left: 1em" />
                                                </FormulateInput>
                                            </div>
                                            <div class="column">
                                                <FormulateInput type="button" style="text-align: left" :label="$t('contactform_reset')" data-ghost @click="reset(true)" />
                                            </div>
                                        </div>
                                    </FormulateForm>
                                    <!-- <pre>{{ contactform }}</pre> -->
                                    <article v-if="showResult" class="message is-dark" style="margin-top: 1.5em">
                                        <div class="message-body" v-html="$t('contactform_success')"></div>
                                        <!-- <div class="message-body" v-if="contactSuccess === true" v-html="$t('contactform_success')"></div>
                                        <div class="message-body" v-else v-html="$t('contactform_error')"></div> -->
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Contact',

        data: () => ({
            contactform: {},
            showResult: false,
            loading: false,
            // contactSuccess: false,
        }),

        methods: {
            submit() {
                this.showResult = false;
                this.loading = true;
                axios.post(`${process.env.VUE_APP_API_BASE_URL}contact`, this.contactform)
                // .then((response) => {
                .then(() => {
                    // handle success
                    // console.log(response.data.success);
                    // this.contactSuccess = (response.data.success === 'success') ? true : false;
                    this.showResult = true;
                    this.reset();
                })
                .catch(() => {
                    // handle error
                    // console.log(error.response.data.errors);
                }).then(() => {
                    // always executed
                    this.loading = false;
                });
            },

            async validate(submission) {
                const hasErrors = await submission.hasValidationErrors();
                if (hasErrors) {
                    this.$el.querySelector('[data-is-showing-errors]').scrollIntoView(false);
                }
            },

            reset(hideResult) {
                this.$formulate.reset('contactform');
                if (hideResult && hideResult === true) {
                    this.showResult = false;
                }
            },
        },
    }
</script>

<style>
    .tarea textarea {
        height: 10em
    }
</style>