<template>
  <label :for="context.id" class="formulate-input-label formulate-input-label--before custom-label">
    {{ context.label }}
    <svg v-if="tooltip" v-tooltip="{ content: tooltip, template: tooltipTemplate, trigger: 'hover click' }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" tabindex="-1">
      <path d="M50,11A39,39,0,1,0,89,50,39.05,39.05,0,0,0,50,11Zm4,55a4,4,0,0,1-8,0V47a4,4,0,0,1,8,0ZM50,38a4,4,0,1,1,4-4A4,4,0,0,1,50,38Z" />
    </svg>
  </label>
</template>

<script>
    import { bus } from '../main';

    export default {
        props: {
            context: {
                type: Object,
                required: true
            },
            tooltip: {
                type: [String, Boolean],
                default: false
            }
        },

        data: () => ({
          fontSize: '.75',
        }),

        methods: {
        },

        computed: {
          tooltipTemplate: function() {
            return `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" style="font-size: ${this.fontSize}rem"></div></div>`;
          }
        },

        created() {
          bus.$on('fontsizechanged', (data) => {
            this.fontSize = (data / 1.25).toString();
          });
        },
    }
</script>
