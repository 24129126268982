<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card search-results-modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" style="flex-shrink: 1; text-align: left; ">{{ $t('thesissearchresultstitle') }}</p>
                <button class="delete" aria-label="close" @click.prevent="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                <div>
                    <vuetable ref="vuetable" :api-mode="false" :per-page="data['pagination']['per_page']" :data="data" :fields="fields" data-path="data" :css="css.table" :data-manager="dataManager" :sort-order="sortOrder" pagination-path="pagination" @vuetable:pagination-data="onPaginationData" :no-data-template="$t('thesissearchresultsnoresults')">
                    <!-- <vuetable ref="vuetable" :api-mode="false" :data="data" :fields="fields" data-path="data" :css="css.table"> -->
                        <div slot="actions" slot-scope="props">
                            <button class="button" @click="onAction('select-item', props.rowData, props.rowIndex)" :title="$t('thesisselectforfill')">
                                <span class="icon is-small"><i class="far fa-folder-open"></i></span>
                            </button>
                        </div>
                    </vuetable>
                </div>
            </section>
            <footer class="modal-card-foot">
                <p v-html="$t('thesissearchektsource')"></p>
                <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage" />
            </footer>
        </div>
    </div>
</template>

<script>
    // import Vue from 'vue';
    import axios from 'axios';
    import i18n from '@/i18n.js';
    import { isMobile } from 'mobile-device-detect';
    import Vuetable from 'vuetable-2';
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
    // import _ from 'lodash';

    export default {
        name: 'ThesisSearchResults',

        components: {
            Vuetable,
            VuetablePagination,
        },

        props: ['data'],

        data: () => ({
            mobile: isMobile,
            // data: [],
            fields: [
                {
                    name: 'id',
                    visible: false,
                },
                {
                    name: 'author',
                    sortField: 'author',
                    title: i18n.t('thesissearchresultsauthorfield'),
                    dataClass: 'is-vcentered',
                    width: '20%',
                },
                {
                    name: 'title',
                    title: i18n.t('thesissearchresultstitlefield'),
                    sortField: 'title',
                    dataClass: 'is-vcentered',
                    width: '70%',
                },
                {
                    name: 'actions',
                    title: i18n.t('thesissearchresultsselectfield'),
                    dataClass: 'has-text-centered is-vcentered',
                    width: '5%',
                }
            ],
            sortOrder: [
                {
                    field: 'author',
                    sortField: 'author',
                    direction: 'asc'
                }
            ],
            css: {
                table: {
                    tableClass: 'table is-hoverable is-fullwidth',
                    loadingClass: 'loading',
                    ascendingIcon: 'fa fa-chevron-up',
                    descendingIcon: 'fa fa-chevron-down',
                    /* eslint no-unused-vars: ["error", { "args": "none" }] */
                    renderIcon: function(classes, options) {
                        let sortIcon = classes.shift()
                        let fontIcon = classes.join(' ')
                        return `<span class="icon is-small ${sortIcon}"><i class="${fontIcon}"></i></span>`
                    }
                },
                pagination: {
                    infoClass: 'pull-left',
                    wrapperClass: 'vuetable-pagination pull-right',
                    activeClass: 'is-current',
                    disabledClass: 'disabled',
                    pageClass: 'pagination-link',
                    linkClass: 'pagination-link',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            }
        }),

        methods: {
            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    // console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = this.sortArray(local, sortOrder[0].direction);
                    // local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
                }

                pagination = this.$refs.vuetable.makePagination(local.length);
                // console.log('pagination:', pagination);

                return {
                    pagination: pagination,
                    // data: _.slice(local, pagination.from - 1, pagination.to),
                    data: local.slice(pagination.from - 1, pagination.to),
                };
            },

            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
            },

            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },

            onAction(action, data, index) {
                axios.post(`${process.env.VUE_APP_API_BASE_URL}get-thesis/`, {
                    uri: data['id'],
                    // thesislang: this.thesisLang,
                })
                .then((response) => {
                    this.$emit('thesisselected', response.data);
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        text: i18n.t('errorgettingthesis', this.lang)
                    });
                })
                .then(() => {
                    this.working = false;
                });
                
                // console.log('Slot action: ' + action, data['id'], index);
            },

            sortArray(array, order) {
                return (!order || order === 'asc') ? array.sort((a, b) => a.author.localeCompare(b.author)) : array.sort((a, b) => b.author.localeCompare(a.author));
            }
        },

        created() {
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.$emit('close');
                }
            });
        },
    }
</script>

<style scoped>
    .search-results-modal-card {
        width: 75%;
    }
    .search-results-modal-card > .modal-card-body {
        padding: 0;
    }
    span.icon.sort-icon { float: right; }

    footer p {
        font-size: .8em;
    }
</style>