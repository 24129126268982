<template>
    <div>
        <section class="hero">
            <div class="hero-body" style="padding-bottom: 0">
                <div class="container">
                    <h1 class="title">
                        {{ $t('about_title') }}
                    </h1>
                    <h2 class="subtitle">
                        <br />
                        <p>{{ $t('about_text') }}</p>
                    </h2>
                </div>
            </div>
        </section>
        <div class="section" style="padding: 0 1.5rem">
            <div class="container" style="margin-bottom: 2rem">
                <p class="has-text-justified">
                    <ul style="list-style: inherit; padding-left: 1rem">
                        <li>{{ $t('karolina_name') }}</li>
                        <li>{{ $t('giannis_name') }}</li>
                        <li>{{ $t('maria_name') }}</li>
                    </ul>
                    <br />
                    {{ $t('team_intro') }}
                </p>
                <hr />
            </div>
            <div class="container">
                <div class="card" style="margin-bottom: 5rem">
                    <div class="columns" style="padding: 1rem 1.5rem">
                        <div class="column is-one-fifth">
                            <div class="card-image">
                                <figure class="image">
                                    <img class="member" src="@/assets/images/team/karolina.jpg" :alt="$t('karolina_name')">
                                </figure>
                            </div>
                        </div>
                        <div class="column is-four-fifths">
                            <div class="media-content">
                                <p class="is-size-4">{{ $t('karolina_name') }}</p>
                                <!-- <p class="subtitle is-6">
                                    <span class="icon is-small"><i class="fas fa-at"></i></span>
                                    <a href="mailto:G.K.Konstantinides@gmail.com"> G.K.Konstantinides@gmail.com</a>
                                </p> -->
                            </div>
                            <div class="content has-text-justified">
                                <br />
                                <p>{{ $t('karolina_cv') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style="margin-bottom: 5rem">
                    <div class="columns" style="padding: 1rem 1.25rem">
                        <div class="column is-four-fifths">
                            <div class="media-content">
                                <p class="is-size-4">{{ $t('giannis_name') }}</p>
                                <!-- <p class="subtitle is-6">
                                    <span class="icon is-small"><i class="fas fa-at"></i></span>
                                    <a href="mailto:ggouts@gmail.com"> ggouts@gmail.com</a>
                                </p> -->
                            </div>
                            <div class="content has-text-justified">
                                <br />
                                <p>{{ $t('giannis_cv') }}</p>
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="card-image">
                                <figure class="image">
                                    <img class="member" src="@/assets/images/team/gkoutsidis.jpg" :alt="$t('giannis_name')">
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style="margin-bottom: 5rem">
                    <div class="columns" style="padding: 1rem 1.5rem">
                        <div class="column is-one-fifth">
                            <div class="card-image">
                                <figure class="image">
                                    <img class="member" src="@/assets/images/team/dimasi.jpg" :alt="$t('maria_name')">
                                </figure>
                            </div>
                        </div>
                        <div class="column is-four-fifths">
                            <div class="media-content">
                                <p class="is-size-4">{{ $t('maria_name') }}</p>
                                <!-- <p class="subtitle is-6">
                                    <span class="icon is-small"><i class="fas fa-at"></i></span>
                                    <a href="mailto:mdimasi@bscc.duth.gr"> mdimasi@bscc.duth.gr</a>
                                </p> -->
                            </div>
                            <div class="content has-text-justified">
                                <br />
                                <p v-html="$t('maria_cv')"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
    }
</script>

<style scoped>
    img.member {
        border: 2px solid #8a0000;
    }
</style>