<template>
    <div style="margin-bottom: 0">
        <div class="columns is-desktop">
            <div class="column is-narrow"><label class="my-label" style="text-align: justify">{{ `${$t('extracreators_label')}: ` }}</label></div>
            <div class="column is-narrow"><FormulateInput v-model="hasextracorpauthor" :tooltip="!!corpTooltip ? $t(corpTooltip) : $t('hasextracorpcreator_tooltip')" error-behavior="submit" type="checkbox" :label="$t('extracorporate_creator')" name="hasextracorpauthor" @change="extracreatorchange($event)" /></div>
            <div class="column is-narrow"><FormulateInput v-model="hasextraauthor" error-behavior="submit" type="checkbox" :tooltip="!!creatorTooltip ? $t(creatorTooltip) : $t(`hasextracreators_tooltip`)" :label="$t('extracreators')" name="hasextraauthor" @change="extracreatorchange($event)" /></div>
            <div class="column is-narrow"><FormulateInput v-model="hasextraartist" error-behavior="submit" type="checkbox" :tooltip="!!artistTooltip ? $t(artistTooltip) : $t(`hasextraartists_tooltip`)" :label="$t('extraartists')" name="hasextraartist" @change="extracreatorchange($event)" /></div>
        </div>
        <FormulateInput key="hasextracorpauthor" v-model="extracorpauthor" error-behavior="submit" v-if="hasextracorpauthor" name="extracorpauthor" validation="required|invalidText" style="margin-bottom: 1.5em" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" :style="hasextracorpauthor ? 'marginBottom: 1em' : 'marginBottom: 0'" :placeholder="`${$t('corpauthorname')} (*)`" />
        <FormulateInput v-model="extraauthors" error-behavior="submit" v-if="hasextraauthor" type="group" name="extraauthors" :repeatable="true" :add-label="$t('add')" minimum="1"  style="margin-bottom: 1.5em">
            <div class="columns is-desktop">
                <div class="column"><FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('lastname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column"><FormulateInput error-behavior="submit" name="firstname" :placeholder="`${$t('firstname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" /></div>
                <div class="column group-margin"><FormulateInput error-behavior="submit" name="middlename" :placeholder="`${$t('middlename')}`" validation="optional|invalidText" :validation-messages="{ invalidText: $t('validation_invalid_text') }" /></div>
            </div>
        </FormulateInput>
        <FormulateInput v-model="extraartists" error-behavior="submit" v-if="hasextraartist" type="group" name="extraauthors" :repeatable="true" :add-label="$t('add')" minimum="1" style="margin-bottom: 1.5em" >
            <div :style="hasextraartist ? 'marginBottom: 1.6em' : 'marginBottom: 0'">
                <FormulateInput error-behavior="submit" name="lastname" :placeholder="`${$t('artistname')} (*)`" validation="required|invalidText" :validation-messages="{ required: $t('validation_required_text'), invalidText: $t('validation_invalid_text') }" />
            </div>
        </FormulateInput>
    </div>
</template>

<script>
    export default {
        name: 'ExtraCreators',

        props: {
            corpTooltip: String,
            creatorTooltip: String,
            artistTooltip: String,
        },

        data: () => ({
            hasextracorpauthor: false,
            hasextraauthor: false,
            hasextraartist: false,
            extracorpauthor: '',
            extraauthors: {},
            extraartists: {}
        }),

        methods: {
            extracreatorchange: function(e) {
                this.extracorpauthor = '';
                this.extraauthors = {};
                this.extraartists = {};
                switch (e.target.name) {
                    case 'hasextracorpauthor':
                        this.hasextraauthor = false;
                        this.hasextraartist = false;
                        break;
                    case 'hasextraauthor':
                        this.hasextracorpauthor = false;
                        this.hasextraartist = false;
                        break;
                    case 'hasextraartist':
                        this.hasextracorpauthor = false;
                        this.hasextraauthor = false;
                        break;
                    default:
                        this.hasextracorpauthor = false;
                        this.hasextraauthor = false;
                        this.hasextraartist = false;
                        break;
                }
            }
        },

        watch: {
            value: function() {
            },
        }
    }
</script>

<style>
    div[data-type=radio] > div > div {
        display: flex
    }
    .my-label {
        display: block;
        line-height: 1.5;
        font-size: .9em;
        font-weight: 600;
        margin-bottom: .1em;
        margin-right: 1em
    }
</style>